import React from 'react'
import LogoImage from '../../assets/logo-app.png'
import background from '../../assets/icons/background.png'
import PropTypes from 'prop-types'

import {
  Logo,
  Page,
  Container,
  ButtonLogin,
  Background
} from '../signIn/style'
import { useNavigate } from 'react-router-dom'
import { useGAPageView } from '../../utils/useGAPageView'
import FooterComponent from '../../components/Footer'

const EmailVerified = () => {
  useGAPageView()

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/login')
  }

  return (
    <div style={{ backgroundColor: '#D1DBDE' }}>
      <Page>
        <Background src={background} />
        <Container style={{ zIndex: 2 }}>
          <Logo src={LogoImage} />
          <p>Tu cuenta ha sido verificada!</p>
          <ButtonLogin
            onClick={signIn}
            variant="contained"
          >
            Ingresar
          </ButtonLogin>
        </Container>
        <FooterComponent/>
      </Page>
    </div>
  )
}

EmailVerified.propTypes = {
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default EmailVerified
