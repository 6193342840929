import { SIGN_IN_SUCCESS } from '../types/actionTypes'
import { URLS } from '../utils/urls'
import { setUserSessionStorage } from '../utils/userSessionStorage'
import axios from 'axios'

const refreshUser =
  () =>
    async (dispatch) => {
      const user = await axios.get(`${URLS.base}me`, {}, { useCredentials: true })
      const data = { username: user.data.username, avatar: user.data.avatar, credits: user.data.credits.credits, costs: user.data.costs }
      setUserSessionStorage(data)

      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: data
      })
    }

export default refreshUser
