// import { getUserSessionStorage } from './userSessionStorage';
// import { getCSRFToken } from './cookies';
// import axios from 'axios';

export const setTokenAxios = async () => {
  // const user = getUserSessionStorage();
  // console.log('crf: ', getCSRFToken());
  // axios.defaults.headers.common['X-CSRFTOKEN'] = getCSRFToken();
  // if (user && user.access_token)
  //   axios.defaults.headers.common[
  //     'Authorization'
  //   ] = `Bearer ${user.access_token}`;
  // axios.defaults.withCredentials = true;
}
