import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { setTokenAxios } from '../utils/setTokenAxios'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const endpointEdit =
  (name, endpoint, id, { params }, enableToast = true, postfix = '') =>
    async (dispatch) => {
      try {
        await setTokenAxios()

        const { data } = await axios.put(
        `${URLS.base}${endpoint}/${id}/${postfix}`,
        params
        )
        if (data?.id) {
          if (enableToast) {
            dispatch(
              showToast({
                show: true,
                message: `El ${name} se modificó correctamente!`,
                type: ERROR_TYPES.SUCCESS
              })
            )
          }
          return {
            success: true,
            id: data.id
          }
        } else {
          if (enableToast) {
            dispatch(
              showToast({
                show: true,
                message: `Ups! Ocurrió un error al modificar el ${name}.`,
                type: ERROR_TYPES.ERROR
              })
            )
          }

          return {
            success: false,
            id: null,
            error: data
          }
        }
      } catch (error) {
        if (enableToast) {
          dispatch(
            showToast({
              show: true,
              message: getServerErrors(error),
              type: ERROR_TYPES.ERROR
            })
          )
        }

        return {
          success: false,
          id: null,
          error
        }
      }
    }

export default endpointEdit
