import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.span`
  // position:relative;
  position:fixed;
  bottom:0;
  background: #49454f;
  width: 100%;
  height: 40px;
  color: white;
  display: flex;
  padding: 0;
  // align-items: right;
  // justify-content: right;
  span {
    margin: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding:0;
    align:right;
  }
`

const FooterComponent = ({ language }) => {
  return (
    <Container>
      <span>{ language ? language.localization['derechos-reservados'] : '© 2022 DLe. Todos los derechos reservados.'}</span>
    </Container>
  )
}

FooterComponent.propTypes = {
  language: PropTypes.any
}

export default FooterComponent
