import React from 'react'
import { PageHeader, Questions, Title, Answer, Question, FAQFooter, FAQFooterText, Page } from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PropTypes from 'prop-types'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import Header from '../../components/Header'
import { useGAPageView } from '../../utils/useGAPageView'
import connect from './connect'
import FooterComponent from '../../components/Footer'

const FAQScreen = ({ setLanguage, language }) => {
  useGAPageView()

  return (
    <Page>
      <Header setLanguage={setLanguage} language={language}/>

      <PageHeader sx={{
        fontSize: { xs: 30, md: 40 },
        paddingTop: { xs: 0, sm: 6 },
        paddingBottom: { xs: 0, sm: 6 }
      }}>Preguntas Frequentes</PageHeader>
      <Questions>
        <Title>GENERAL</Title>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-14'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-14'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-3'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-3'] }
            </Answer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-4'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-4'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-5'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-5'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-6'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-6'] }
           </Answer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-7'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-7'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-8'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-8'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-9'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-9'] }
            </Answer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-10'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-10'] }
              </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-11'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-11'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-12'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-12'] }
            </Answer>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-13'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-13'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-16'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-16'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-17'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-17'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-17'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-17'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-18'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-18'] }
            </Answer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Question>
            { language.localization['faq-question-19'] }
            </Question>
          </AccordionSummary>
          <AccordionDetails>
            <Answer>
            { language.localization['faq-answer-19'] }
            </Answer>
          </AccordionDetails>
        </Accordion>

      </Questions>
      <FAQFooter>
        <FAQFooterText>
          ¿No encuentras respuesta a tus dudas? o necesitas ayuda, ponte en contacto con soporte: <a href='mailto:soporte@dle.com.uy'>soporte@dle.com.uy</a>
        </FAQFooterText>
      </FAQFooter>
      <FooterComponent language={language} />
    </Page>
  )
}

FAQScreen.propTypes = {
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default connect(FAQScreen)
