import { Container } from '@mui/system'
import styled from 'styled-components'
import { Colors } from '../../constants/Theme'

export const RowContainer = styled.div`
width:100%;
padding:0;
background-color: ${Colors.white};
display: flex;
flex-direction: row;
align-items: center;
position: relative;
`

export const ColumnContainer = styled.div`
width:100%;
padding:0;
background-color: ${Colors.white};
display: flex;
flex-direction: column;
align-items: center;
position: relative;
`

export const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding:10px;
margin-bottom:10px;
background: ${Colors.background} ;
`

export const HeaderItem = styled(Container)`
font-size:11pt;
padding:0 12px!important;
/* color:${Colors.black}; */
color: ${Colors.header};
`
