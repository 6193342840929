import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import checkIcon from '../assets/icons/check.png'
import { Colors } from '../constants/Theme'

const Container = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 12px 4px 12px;
  /* width: auto; */
  height: 32px;
  background: #49454f;
  border-radius: 8px;
  margin: 15px 5px 0 5px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: white;
  }
  img {
    position:relative;
    float:left;
    display:block;
    margin-right: 6px;
    margin-left: 4px;
  }
`

const Icon = styled.img`
  display:flex;
  flex:1;
  color: ${Colors.white};
`
const Label = styled.span`
  display:flex;
  flex:10;
  color: ${Colors.white};
`

const Tag = ({ text, selected }) => {
  return (
    <Container style={{ background: selected ? 'rgb(75 151 180)' : '#49454f' }}>
      {selected &&
      <Icon src={checkIcon} />
      }
      <Label sx={{ fontSize: 10 }}>{text}</Label>
    </Container>
  )
}

Tag.propTypes = {
  text: PropTypes.any,
  selected: PropTypes.any
}

export default Tag
