export const Colors = {
  primaryColor: '#4B97B4',
  secondaryColor: '',
  primaryText: '#707070',
  secondaryText: '#343434',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#ebebeb',
  googleIcon: '#de5246',
  blueText: '#102342',
  green: '#00b14f',
  header: '#4B97B4',
  red: 'red',
  background: 'rgba(75, 151, 180, 0.13)',
  orange: '#F46524',
  orangePale: '#F4652433',
  blackText: '#333333'
}
