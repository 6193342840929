import React, { useState } from 'react'
import { Colors } from '../../../constants/Theme'
import { useFocus } from '../../useFocus'
import { SmartTextfield } from '../../SmartTextfield'
import { AddRounded } from '@mui/icons-material'
import { RowContainer } from '../style'
import PropTypes from 'prop-types'

const CreateActivityItem = ({ save, setLanguage, language }) => {
  const [title, setTitle] = useState()
  const [percentage, setPercentage] = useState()

  const [ref, setFocus] = useFocus()

  const saveItem = async () => {
    if (title !== undefined && percentage !== undefined) {
      const success = await save({ title, percentage })
      if (success) {
        setPercentage('')
        setTitle('')
        setFocus()
      }
    }
  }

  return (
    <RowContainer style={{ padding: '0 10px' }}>
        <SmartTextfield
          inputRef={ref}
          onSave={saveItem}
          style={{
            textAlign: 'left',
            margin: 5,
            padding: '0 12px'
          }}
          sx={{
            width: { xs: '64%', md: '85%' }
          }}
          placeholder={ language.localization.name }
          value={title}
          type="text"
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <SmartTextfield
          style={{
            textAlign: 'right',
            margin: 5
          }}
          sx={{
            width: { xs: '14%', md: '14%' }
          }}
          placeholder={ language.localization['task-weight-factor'] }
          onSave={saveItem}
          value={percentage}
          type="number"
          onChange={(e) => {
            setPercentage(e.target.value)
          }}
          inputProps={{ min: 0, max: 100 }}
        />
        %

        <AddRounded style={{
          width: 18,
          height: 18,
          marginDown: 10,
          marginRight: 10,
          marginLeft: 10,
          color: Colors.primaryColor,
          cursor: 'pointer'
        }}
          onClick={() => {
            saveItem()
          }}>
        </AddRounded>
      </RowContainer>
  )
}

CreateActivityItem.propTypes = {
  save: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default CreateActivityItem
