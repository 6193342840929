import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import {
  CREATE_COURSE,
  CREATE_COURSE_ERROR
} from '../types/actionTypes'
import { getServerErrors } from '../utils/serverErrors'
import { setTokenAxios } from '../utils/setTokenAxios'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const getQuestion =
  ({ questionId }) =>
    async (dispatch) => {
      try {
        dispatch({ type: CREATE_COURSE })

        await setTokenAxios()

        let response = {}
        if (questionId) {
          response = await axios.get(`${URLS.base}node/${questionId}`)
        } else {
          response = await axios.get(`${URLS.base}node/`)
        }

        return { success: true, data: response.data }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
        dispatch({
          type: CREATE_COURSE_ERROR
        })
        return {
          success: false
        }
      }
    }

export default getQuestion
