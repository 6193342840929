import { React, useState } from 'react'
import { Tooltip } from '@mui/material'
import { SmartTextfield } from '../../SmartTextfield'
import trashIcon from '../../../assets/icons/trash.png'
import { ColumnContainer, RowContainer } from '../style'
import PropTypes from 'prop-types'
import { SmartTextarea } from '../../SmartTextarea'
import { CompetenceHeader } from './style'

const CompetenceItem = ({ item, deleteItem, editItem, language }) => {
  const [title, setTitle] = useState(item.title)
  const [grade1, setGrade1] = useState(item.grade_1)
  const [grade2, setGrade2] = useState(item.grade_2)
  const [grade3, setGrade3] = useState(item.grade_3)
  const [grade4, setGrade4] = useState(item.grade_4)

  return (
    <ColumnContainer style={{ padding: '0 10px' }}>

      <RowContainer>
        <SmartTextfield
            saveOnBlur
            onSave={() => editItem({ ...item, title, grade1 })}
            style={{
              textAlign: 'left',
              // margin: 5,
              paddingTop: '25px'
            }}
            value={title}
            type="text"
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            // onKeyPress={(e) => sendOnEnter(e, saveItem)}
          />
          <Tooltip title="Eliminar" arrow placement="top">
            <img
              style={{
                width: 18,
                height: 18,
                marginDown: 10,
                marginRight: 10,
                marginLeft: 10,
                cursor: 'pointer'
              }}
              src={trashIcon}
              alt="Eliminar"
              onClick={() => {
                deleteItem(item)
              }}
            />
          </Tooltip>
        </RowContainer>

        <ColumnContainer>
          <RowContainer>
            <CompetenceHeader>
              { language.localization['competence-grade-1'] }
            </CompetenceHeader>
            <SmartTextarea
              saveOnBlur
              onSave={() => editItem({ ...item, title, grade1, grade2, grade3, grade4 })}
              style={{
                textAlign: 'left',
                margin: 5,
                width: '80%'
              }}
              value={grade1}
              onChange={(e) => setGrade1(e.target.value)}
              type="string"
            />
          </RowContainer>
          <RowContainer>
            <CompetenceHeader>
              { language.localization['competence-grade-2'] }
            </CompetenceHeader>
            <SmartTextarea
              saveOnBlur
              onSave={() => editItem({ ...item, title, grade1, grade2, grade3, grade4 })}
              style={{
                textAlign: 'left',
                margin: 5,
                width: '80%'
              }}
              value={grade2}
              onChange={(e) => setGrade2(e.target.value)}
              type="string"
            />
          </RowContainer>
          <RowContainer>
            <CompetenceHeader>
              { language.localization['competence-grade-3'] }
            </CompetenceHeader>
            <SmartTextarea
              saveOnBlur
              onSave={() => editItem({ ...item, title, grade1, grade2, grade3, grade4 })}
              style={{
                textAlign: 'left',
                margin: 5,
                width: '80%'
              }}
              value={grade3}
              onChange={(e) => setGrade3(e.target.value)}
              type="string"
            />
          </RowContainer>
          <RowContainer>
            <CompetenceHeader>
              { language.localization['competence-grade-4'] }
            </CompetenceHeader>
            <SmartTextarea
              saveOnBlur
              onSave={() => editItem({ ...item, title, grade1, grade2, grade3, grade4 })}
              style={{
                textAlign: 'left',
                margin: 5,
                width: '80%'
              }}
              value={grade4}
              onChange={(e) => setGrade4(e.target.value)}
              type="string"
            />
          </RowContainer>
        </ColumnContainer>
    </ColumnContainer>
  )
}

CompetenceItem.propTypes = {
  item: PropTypes.any,
  deleteItem: PropTypes.any,
  editItem: PropTypes.any,
  language: PropTypes.any
}

export default CompetenceItem
