import { React, useState } from 'react'
import { FormControl } from '@mui/material'
import { SmartTextfield } from '../../SmartTextfield'
import { PickerContainer } from '../../../screens/stepOne/style'
import PropTypes from 'prop-types'

import { es, enCA } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import trashIcon from '../../../assets/icons/trash.png'
import CommunicationSelect from './CommunicationSelect'

const CommunicationItem = ({ item, communicationMedias, updateCommunication, deleteCommunication, language }) => {
  const [value, setValue] = useState(item.title)

  return (
    <div>
        <SmartTextfield
          saveOnBlur
          sx={{
            width: { xs: '40%', md: '60%' }
          }}
          style={{
            textAlign: 'left',
            // flex:5,
            padding: '16.5px 14px',
            margin: 0
          }}
          onSave={(value) => {
            const updated = {
              ...item,
              title: value
            }
            updateCommunication(updated)
          }}
          onChange={(event) => {
            setValue(event.target.value)
          }}
          value={value}>
        </SmartTextfield>

        <CommunicationSelect
          item={item}
          medias={communicationMedias}
          updateCommunication={updateCommunication}/>

        <FormControl
          sx={{
            width: { xs: '20%', md: '20%' }
          }}
          style={{
            textAlign: 'right',
            // flex:2,
            margin: 0
          }}>
          <PickerContainer
          style={{
            textAlign: 'right',
            // flex:2,
            margin: 4
          }}>
          <LocalizationProvider
           dateAdapter={AdapterDayjs} locale={language.lang === 'es' ? es : enCA}>
              <DatePicker
                style={{
                  // flex:2,
                  margin: 4
                }}
                // label={ language.localization['from'] }
                // eslint-disable-next-line new-cap
                value={dayjs(item?.date) || new dayjs()}
                onChange={(newValue) => {
                  const updated = {
                    ...item,
                    date: newValue
                  }
                  updateCommunication(updated)
                }}
              />
            </LocalizationProvider>
          </PickerContainer>
        </FormControl>
        <img
          className="iconForm"
          src={trashIcon}
          style={{
            width: 15,
            height: 18,
            // margin:5,
            marginTop: 22,
            marginLeft: 12,
            cursor: 'pointer'
          }}
          alt="Eliminar"
          onClick={() => {
            deleteCommunication(item)
          }}
        />

    </div>
  )
}

CommunicationItem.propTypes = {
  item: PropTypes.any,
  communicationMedias: PropTypes.any,
  updateCommunication: PropTypes.any,
  deleteCommunication: PropTypes.any,
  language: PropTypes.any
}

export default CommunicationItem
