import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { setTokenAxios } from '../utils/setTokenAxios'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const endpointCreate =
  (name, endpoint, { params }, enableToast = true) =>
    async (dispatch) => {
      try {
        await setTokenAxios()

        const { data } = await axios.post(
        `${URLS.base}${endpoint}/`,
        params
        )
        if (data?.id) {
          if (enableToast) {
            dispatch(
              showToast({
                show: true,
                message: `El ${name} se creó correctamente!`,
                type: ERROR_TYPES.SUCCESS
              })
            )
          }
          return {
            success: true,
            newId: data.id
          }
        } else {
          if (enableToast) {
            dispatch(
              showToast({
                show: true,
                message: `Ups! Ocurrió un error al crear el ${name}.`,
                type: ERROR_TYPES.ERROR
              })
            )
          }
          return {
            success: false,
            id: null
          }
        }
      } catch (error) {
        if (enableToast) {
          dispatch(
            showToast({
              show: true,
              message: getServerErrors(error),
              type: ERROR_TYPES.ERROR
            })
          )
        }
        return {
          success: false,
          id: null
        }
      }
    }

export default endpointCreate
