import { Box } from '@mui/system'
import styled from 'styled-components'
import { Colors } from '../../constants/Theme'

export const Container = styled.div`
  min-height:10vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${Colors.background};
`

export const Inner = styled.div`
  margin: 10px;
  position: 'relative';
  width: 100%;
  min-height: calc( 100vh - 104px );
`

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  background: ${Colors.white};
  left: 0;
  border-radius: 6px;
  padding: 15px;
  justify-content: start;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: ${Colors.red};
  border-radius: 4px;
  margin-bottom: 100px;
  margin-top: 20px;
  @media (max-width: 440px) {
    width: 90%;
  }
`

export const TextTitle = styled.span`
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
`

export const TextSubtitle = styled.span`
  font-size: 13px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  color: ${Colors.blueText};
`

export const Column = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin: 10px;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 4;
  justify-content: start;
`

export const NoCourses = styled(Box)`
  width:100%;
  height:100vh;
  text-align: center;
  color:#00000099;
`

export const NoCoursesBubble = styled(Box)`
  display:block;
  margin-left:20px;
  padding:10px;
  width:200px;
  background-color:#4B97B4;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color:white;
`

export const NoCoursesBubbleTip = styled(Box)`
  position: absolute;
  top:100px;
  left:170px;
  width: 20px;
  height: 20px;
  background: #4B97B4;
  transform: rotate(-45deg);
`
