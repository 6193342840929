import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Column, ColumnContainer, DateContainer, TextTitle } from '../../../screens/stepOne/style'

import calendarIcon from '../../../assets/icons/calendar.png'

import connect from '../connect'
import { FormControl, TextField } from '@mui/material'
import { PickerContainer } from '../../../screens/course/style'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enCA, es } from 'date-fns/locale'
import { formatDate } from '../../../utils/dates'

const Duration = ({ course, language, endpointEdit }) => {
  // const [duration, setDuration] = useState(course?.duration)
  const [studentHours, setStudentHours] = useState('1')
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    if (course) {
      // if (course.duration) { setDuration(course.duration) }
      if (course.fecha_fin) { setEndDate(new Date(course.fecha_fin)) }
      setStudentHours(course.estudiantes_dedicacion_horaria)
    }
  }, [course])

  const saveHours = async (h) => {
    const params = course
    course.estudiantes_dedicacion_horaria = h
    await endpointEdit('Curso', 'cursos', course.id, { params })
  }

  const saveEndDate = async (date) => {
    const params = course
    course.fecha_fin = formatDate(date)
    await endpointEdit('Curso', 'cursos', course.id, { params })
  }

  // const saveDuration = async (val) => {
  //   const params = course
  //   params.duration = parseInt(val)
  //   await endpointEdit('Curso', 'cursos', course.id, { params })
  // }

  return (
    <Column sx={{ minWidth: { xs: 'auto', md: 420 } }}>
      <ColumnContainer>
      <TextTitle>
        <div className="row">
        <img
          className="iconForm"
          src={calendarIcon}
          alt={ language.localization.duration }
        />
        <span className="titleForm">{ language.localization.duration } </span>
        </div>
        <span
        className="objetivesDescription"
        style={{ marginTop: 16, display: 'flex' }}
        >
        { language.localization['duration-description'] }
        </span>
      </TextTitle>
      <DateContainer>
        {/* <FormControl style={{ margin: '15px  5px', width: '90%' }}>
          <TextField
            label={ language.localization['course-duration'] }
            variant="outlined"
            value={duration}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 200 } }}
            onChange={(e) => {
              setDuration(e.target.value)
              saveDuration(e.target.value)
            }}
          />
          </FormControl> */}
        <FormControl>
          <TextField
            label={ language.localization['weekly-time-allocation'] }
            variant="outlined"
            value={studentHours}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 200 } }}
            onChange={(e) => {
              setStudentHours(e.target.value)
              saveHours(e.target.value)
            }}
          />
          </FormControl>

          <PickerContainer>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={language.lang === 'es' ? es : enCA}>
              <DatePicker
                value={endDate}
                label={ language.localization.to }
                onChange={(newValue) => {
                  setEndDate(newValue)
                  saveEndDate(newValue)
                }}
              />
            </LocalizationProvider>
          </PickerContainer>
        {/* <PickerContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={language.lang === 'es' ? es : enCA}>
            <DatePicker
              value={dateFrom}
              label={ language.localization.from }
              onChange={ async (newValue) => {
                setDateFrom(newValue)
                saveDateFrom(newValue)
              }}
            />
          </LocalizationProvider>
        </PickerContainer> */}
      </DateContainer>
      </ColumnContainer>
    </Column>

  )
}
Duration.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointEdit: PropTypes.any
}
export default connect(Duration)
