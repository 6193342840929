import { React, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Stack } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import trashIcon from '../../../assets/icons/trash.png'
import pencilIcon from '../../../assets/icons/pencil.png'
import { SmartTextarea } from '../../SmartTextarea'
import PropTypes from 'prop-types'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enCA, es } from 'date-fns/locale'
import connect from '../connect'
import { formatDate } from '../../../utils/dates'

const TagObjetive = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* margin-top: 10px; */
  justify-content: space-between;
`

const Container = styled.div`
  display:flex;
  flex-direction: row;
  margin-top:10px;
`

const ModuleItem = ({ data, deleteItem, id, edit, allowEdit, allowDelete, language }) => {
  const [value, setValue] = useState(data.title)
  const [date, setDate] = useState(new Date(data.date))

  useEffect(() => {
    setValue(data.title)
  }, [data])

  return (
    <TagObjetive>
      <Stack direction="row" gap={1} justifyContent="start">
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={language.lang === 'es' ? es : enCA}
          >
            <DatePicker
              style={{ flex: 1 }}
              label={ language.localization['start-date'] }
              okLabel="Guardar"
              cancelLabel="Cerrar"
              value={date}
              onChange={(newValue) => {
                // data.date = newValue
                setDate(newValue)
                edit({ ...data, date: formatDate(newValue) })
              }}
            />
          </LocalizationProvider>
          <SmartTextarea
              saveOnBlur
              onSave={(e) => {
                // data.title = value
                edit({ ...data, title: value })
              }}
              onChange={(e) => { setValue(e.target.value) }}
              value={value}>
          </SmartTextarea>
          {/* {id + 1} - {data.title} */}
        <Container>
          {allowDelete &&

            <Tooltip title="Eliminar" arrow placement="top">
              <img
                style={{
                  width: '18px',
                  height: 20,
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: 'pointer'
                }}
                src={trashIcon}
                alt="Eliminar"
                onClick={() => {
                  deleteItem(data)
                }}
              />
            </Tooltip>
          }

          {allowEdit &&
            <Tooltip title="Editar módulo" arrow placement="top">
              <img
                onClick={() => {
                  edit(id)
                }}
                src={pencilIcon}
                style={{ height: 20, cursor: 'pointer' }}
                alt="Editar"
              />
            </Tooltip>
          }
        </Container>
      </Stack>
    </TagObjetive>
  )
}

ModuleItem.propTypes = {
  language: PropTypes.any,
  data: PropTypes.any,
  deleteItem: PropTypes.any,
  id: PropTypes.any,
  edit: PropTypes.any,
  allowEdit: PropTypes.any,
  allowDelete: PropTypes.any,
  showOrder: PropTypes.any
}

export default connect(ModuleItem)
