import styled from 'styled-components'
// import { styled } from '@mui/system';
import { Colors } from '../../constants/Theme'
import { Container, Typography, Box, ListItem } from '@mui/material'

export const ListItemTerm = styled(ListItem)`
${
    `
      &:before {
        content: '•';
        position: absolute;
        font-size: 1.8rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    `
  }
`
export const Page = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  // height: 100vh;
  background: ${Colors.white};
`

export const PageHeader = styled(Box)`
  background: #82b5ca;
  top:0;
  color:white;
  width:100%;
  text-align:center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: center;
  z-index:3;
`

export const Terms = styled(Container)`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  margin: 30px 0 30px 0;
`

export const Title = styled(Typography)`
  //styleName: Subtítulo_1;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: ${Colors.orange};
  margin: 40px 40px 40px 0px!important;
  /* margin: 20px!important; */
`

export const Term = styled(Typography)`
  //styleName: M3/body/large;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
`

export const Answer = styled(Typography)`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
`

export const FAQFooter = styled(Box)`
  width: 100%;
  padding: 50px 0 50px 0;
  background: #E5E5E5;
`
export const FAQFooterText = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #F46524;
  max-width:1200px;
  margin:auto!important;
  padding:0 10px 0 10px!important;

  & a{
    color:#69a8c0;
  }
`
