import axios from 'axios'
import LocalizedStrings from 'react-localization'

import { ERROR_TYPES } from '../constants/ErrorTypes'
import { SET_LANGUAGE, SET_LOCALIZATION } from '../types/actionTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const setLanguage =
  (lang) =>
    async (dispatch) => {
      try {
        const result = await axios.get(`${URLS.base}texts/${lang}`)
        const settings = {}
        settings[lang] = {}
        for (const k in result.data) {
          const r = result.data[k]
          settings[lang][r.get_label] = r.text
        }
        const strings = new LocalizedStrings(settings)

        if (strings) {
          dispatch({
            type: SET_LOCALIZATION,
            payload: strings
          })
          dispatch({
            type: SET_LANGUAGE,
            payload: lang
          })
        } else {
          dispatch(
            showToast({
              show: true,
              message: 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
      }
    }

export default setLanguage
