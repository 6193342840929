import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ColumnContainer, TextTitle } from '../../../screens/stepOne/style'
import { Tooltip } from '@mui/material'

import checksIcon from '../../../assets/icons/checks.png'
import connect from '../connect'
import InfoIcon from '@mui/icons-material/Info'
import { Colors } from '../../../constants/Theme'
// import showToast from '../../../actions/Toast'
// import { ERROR_TYPES } from '../../../constants/ErrorTypes'
import { sendGAEvent } from '../../../utils/useGAPageView'
import { Column } from '../../../screens/course/style'
import CompetenceItem from '../Competences/CompetenceItem'
import CreateCompetenceItem from '../Competences/CreateCompetenceItem'

const ModuleCompetences = ({ setLanguage, module, language, endpointCreate, endpointEdit, endpointDelete, generating }) => {
  const [competences, setCompetences] = useState([])

  useEffect(() => {
    setCompetences(module?.module_competences)
  }, [module])

  const saveCompetence = async ({ title, percentage }) => {
    // create
    const params = { module: module.id, title, percentage }
    const { success, newId } = await endpointCreate(
      'Competencia',
      'module_competences',
      { params }
    )
    if (success) {
      // update local
      setCompetences([...competences, { title, percentage, id: newId }])
      sendGAEvent({
        category: 'edit',
        action: 'module-competence'
      })
    }
    return success
  }
  const editCompetence = async (item) => {
    // edit
    const params = { module: module.id, id: item.id, title: item.title, grade_1: item.grade1, grade_2: item.grade2, grade_3: item.grade3, grade_4: item.grade4 }
    // update local
    competences.forEach((a) => {
      if (a.id === item.id) {
        a.title = item.title
        a.grade1 = item.grade1
        a.grade2 = item.grade2
        a.grade3 = item.grade3
        a.grade4 = item.grade4
      }
    })
    await endpointEdit(
      'Competencia',
      'module_competences',
      item.id,
      { params }
    )
  }
  const deleteCompetence = async (value) => {
    // delete
    const { success } = await endpointDelete(
      'Competencia',
      'module_competences',
      value.id,
      {}
    )
    if (success) {
      // update local
      const newcompetences = competences.filter((val) => val.id !== value.id)
      setCompetences(newcompetences)
    }
  }

  return (
    <Column generating={generating}>
    <ColumnContainer>
    <TextTitle>
      <div className="row">
        <img
          style={{ width: '25px', marginRight: 10 }}
          src={checksIcon}
          alt="Título del curso"
        />
        <span
          className="titleForm"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span style={{ marginRight: 10 }}>
            { language.localization['module-competences'] }
            </span>
            <Tooltip
            title={ language.localization['module-competences-tooltip'] }
            arrow
            placement="right"
            >
            <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
          </Tooltip>
        {/* <AutoFixHighIcon style={{display:'block', margin:10, cursor:'pointer', color:Colors.orange}}/> */}
        </span>
      </div>
      <span
      className="objetivesDescription"
      style={{ marginTop: 16, display: 'flex' }}
      >
      { language.localization['module-competences-description'] }
      </span>
    </TextTitle>
    <div>
      {competences?.map((a) => (
        <CompetenceItem
          item={a}
          key={a.title}
          setActivitiName={() => {}}
          setActivityPorcentage={() => {}}
          deleteItem={deleteCompetence}
          editItem={editCompetence}
          language={language}
        />
      ))}
    </div>
    <CreateCompetenceItem save={saveCompetence} setLanguage={setLanguage} language={language}/>
    </ColumnContainer>
  </Column>

  )
}

ModuleCompetences.propTypes = {
  setLanguage: PropTypes.any,
  language: PropTypes.any,
  module: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointDelete: PropTypes.any,
  generating: PropTypes.any
}

export default connect(ModuleCompetences)
