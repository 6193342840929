import { React, useState } from 'react'
import { FormControl, Select } from '@mui/material'
import PropTypes from 'prop-types'

import { MediaImage, MediaRow, MediaTitle } from './style'

const CommunicationSelect = ({ item, medias, updateCommunication }) => {
  const [value, setValue] = useState(item.media ? item.media.id : '')

  return (
    <FormControl style={{
      textAlign: 'right',
      width: { xs: '20%', md: '20%' },
      margin: 5,
      minWidth: 70
    }}>
    <Select
      value={value}
      onChange={(event) => {
        setValue(event.target.value)

        if (item.media) {
          const updated = {
            ...item,
            media: event.target.value
          }

          updateCommunication(updated)
        }
      }}
      renderValue={() => (
        <MediaImage src={item.media?.image}/>
      )}
    >
      {medias.map((media, j) => {
        return <MediaRow key={`media_${j}`} name={media?.title} value={media?.id}>

        <MediaImage src={media?.image}/>
        <MediaTitle>
        {media?.title}
        </MediaTitle>
      </MediaRow>
      })}
    </Select>
    </FormControl>
  )
}

CommunicationSelect.propTypes = {
  item: PropTypes.any,
  updateCommunication: PropTypes.any,
  medias: PropTypes.any
}

export default CommunicationSelect
