import { createSelector } from 'reselect'

const toastState = (state) => state.toast
const userState = (state) => state.user

export const getShowToast = createSelector(
  toastState,
  (toast) => toast || null
)

export const getUserLoading = createSelector(
  userState,
  ({ user }) => user?.loading || false
)
