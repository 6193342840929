import styled from 'styled-components'
import { Colors } from '../../constants/Theme'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'

export const Container = styled.div`
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 4px;
  margin-bottom: 100px;
  margin-top: 20px;
  @media (max-width: 440px) {
    width: 100%;
    border-radius: 0;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .iconForm {
    width: 18px;
    margin-right: 10px;
  }
  .titleForm {
    font-size: 20px;
    font-weight: 400;
  }
  .titleInput {
    width: 100%;
  }
  .objetivesSubTitle {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 500;
    color: #1c1b1f;
  }
  .objetivesDescription {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #49454f;
  }
  .objetivesEspSubTitle {
    font-size: 20px;
    font-weight: 500;
    color: #1c1b1f;
  }
`

export const TextTitle = styled.span`
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
`

export const TextSubtitle = styled.span`
  font-size: 13px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  color: ${Colors.blueText};
`

export const ObjectivesConitaner = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 440px) {
  }
`

export const ContainerHibrid = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.white};
  border-radius: 5px;
  margin-bottom: 20px;
  /* height: 100%; */
  @media (max-width: 440px) {
    border-radius: 0;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: space-between;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  /* background: ${Colors.white}; */
  gap:20px;
  flex-wrap:wrap;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 41px;
  justify-content: space-between;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const PickerContainer = styled.div`
  margin:10px;
  width:100%;
`

export const StudentComContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  margin-bottom: 0;
`

export const StudentCheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const StudentTextField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 440px) {
    width: 100%;
    margin-top: 15px;
  }
`

export const AboutStudentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  margin-bottom: 0;
`

export const AboutStudentsSelects = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px -5px;
  justify-content: space-between;
  @media (max-width: 440px) {
    width: 100%;
    flex-direction: column;
    margin: 20px 0;
  }
`

export const StudentActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  margin-bottom: 0;
`

export const StudentActivitiesCheck = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const StudentContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  margin-bottom: 0;
`

export const StudentContentCheck = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const ButtonFooter = styled.div`
  display: flex;
  justify-content: end;
  gap:20px;
`

export const NextButton = styled.div`
  padding: 10px 24px 10px 16px;
  width: 126px;
  height: 40px;
  background: ${Colors.header};
  border-radius: 100px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
`

export const BackButton = styled.div`
  padding: 8px 24px 8px 16px;
  width: 126px;
  height: 40px;
  color: ${Colors.header};
  background: ${Colors.white};
  border-radius: 100px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border: 2px solid ${Colors.header};
`

export const AIButton = styled.div`
  padding: 10px 24px 10px 16px;
  width: 156px;
  height: 40px;
  background: ${Colors.orange};
  border-radius: 100px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
`

export const BoxObjetives = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NewRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top:20px;
  gap:10px;
`

export const AIWorking = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: #ffffff99;
`

export const AILogo = styled(PrecisionManufacturingIcon)`
  width:100px;
  height:auto;
`
