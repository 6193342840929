import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import connect from './connect'
import PropTypes from 'prop-types'

import objetiveIcon from '../../assets/icons/objetive.png'
import wordIcon from '../../assets/icons/word.png'
import gptIcon from '../../assets/icons/gpt.jpg'
import moodleIcon from '../../assets/icons/moodle.png'
import schoologyIcon from '../../assets/icons/schoology.png'

import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'

import {
  Container,
  Body,
  FormContainer,
  TextTitle,
  Column,
  ButtonFooter,
  ColumnContainer,
  NewRowContainer,
  Description
} from './style.js'

import { Colors } from '../../constants/Theme'

import ToastMessage from '../../components/ToastMessage'
import Header from '../../components/Header'
import LoadingPage from '../../components/LoadingPage'
import ObjetiveItem from '../../components/ObjetiveItem'
import FormHeader from '../../components/FormHeader'
import { SmartTextfield } from '../../components/SmartTextfield'
import { SmartTextarea } from '../../components/SmartTextarea'
import { AddRounded } from '@mui/icons-material'
import { sendGAEvent, useGAPageView } from '../../utils/useGAPageView'
import CourseTitle from '../../components/boxes/courseTitle/CourseTitle'
import Duration from '../../components/boxes/duration/Duration'
import StudentProfile from '../../components/boxes/studentProfile/StudentProfile'
import LearningEnvironments from '../../components/boxes/LearningEnvirronments/LearningEnvironments'
import ModulesList from '../../components/boxes/Modules/ModulesList'
import StrategySelector from '../../components/boxes/StrategySelector/StrategySelector'
import Activities from '../../components/boxes/Activities/Activities'
import CommunicationStrategy from '../../components/boxes/CommunicationStrategy/CommunicationStrategy'
import ModuleBox from '../../components/boxes/Modules/ModuleBox'
import { URLS } from '../../utils/urls'
import axios from 'axios'
import { getServerErrors } from '../../utils/serverErrors'
import ExportButton from '../../components/export/ExportButton'
import refreshUser from '../../actions/RefreshUser'
import { useDispatch } from 'react-redux'
import { ERROR_TYPES } from '../../constants/ErrorTypes'
import FooterComponent from '../../components/Footer'
import { Grid, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import * as animationData from '../../assets/gen-course-anime.json'
import Competences from '../../components/boxes/Competences/Competences.jsx'
import { formatDate } from '../../utils/dates.js'

const CourseScreen = ({
  toastProps,
  endpointGet,
  endpointCreate,
  endpointEdit,
  endpointDelete,
  endpointExport,
  showToast,
  setLanguage,
  language,
  user
}) => {
  const [course, setCourse] = useState(null)
  const [modules, setModules] = useState([])

  const [courseId, setCourseId] = useState(null)
  const [owner, setOwner] = useState(null)
  const [title, setTitle] = useState('')

  const [tagsObjetives, setTagsObjetives] = useState([])
  const [objetiveText, setObjectiveText] = useState()

  const [tagsSpecific, setTagsSpecific] = useState([])
  const [specificText, setSpecificText] = useState()
  const [description, setDescription] = useState('')
  const [, setStrategies] = useState([])

  const [generatingCourse, setGeneratingCourse] = useState(false)

  // const [calificationdisplay, setcalificationdisplay] = useState(false)
  // const [comunicationdisplay, setcomunicationdisplay] = useState(false)

  const navigate = useNavigate()

  const setDataEditing = (response) => {
    setCourseId(response.id)
    setOwner(response.owner)
    setTitle(response.title)
    setTagsObjetives(response.objetivos_generales)
    setTagsSpecific(response.objetivos_especificos)
    setStrategies(response.strategies)
  }

  const { id } = useParams()
  const [loading, setLoading] = useState(false)

  useGAPageView()

  useEffect(() => {
    if (id) {
      setLoading(true)
      // const getGenerationState = async () => {
      //   let response = await axios.get(`${URLS.base}cursos/${id}/state/`);
      //   if(response.status === 200) return response.data.state;
      //   return -1;
      // }

      const fetchCourse = async () => {
        try {
          const { data } = await endpointGet('Curso', 'cursos', id)
          if (data) {
            setLoading(false)
            setDataEditing(data)
            setCourse(data)
            setModules(data.modulos)
            // setVisivility(data)
            setDescription(data.description)
            // const state = await getGenerationState();
            // if(state === 0 || state === 1 ||state === 2 || state === 3){
            //   startPollingGeneration();
            // }
          }
        } catch (error) {
          // navigate('/');
        }
      }
      fetchCourse()
    }
  }, [endpointGet, id, navigate])

  const saveOrCreateObjective = async (item) => {
    if (item.id) {
      // edit
      const params = { curso: id, title: item.title }
      await endpointEdit(
        'Objetivo General',
        'objetivos_generales',
        item.id,
        { params }
      )
    } else {
      // create
      const params = { curso: id, title: item.title }
      const { success, newId } = await endpointCreate(
        'objetivo general',
        'objetivos_generales',
        { params }
      )
      if (success) {
        // refresh!
        params.id = newId
        setTagsObjetives([...tagsObjetives, params])

        sendGAEvent({
          category: 'edit',
          action: 'generic-objective'
        })
      }
    }
  }

  const deleteObjetive = async (value) => {
    // delete
    const { success } = await endpointDelete(
      'objetivo general',
      'objetivos_generales',
      value.id,
      {}
    )
    if (success) {
      const newtags = tagsObjetives.filter((val) => val.id !== value.id)
      setTagsObjetives(newtags)
    }
  }

  const sendOrCreateSpecific = async (item) => {
    if (item.id) {
      // edit
      const params = { curso: id, title: item.title }
      await endpointEdit(
        'objetivo especifico',
        'objetivos_especificos',
        item.id,
        { params }
      )
    } else {
      // create
      const params = { curso: id, title: item.title }
      const { success, newId } = await endpointCreate(
        'objetivo especifico',
        'objetivos_especificos',
        { params }
      )
      if (success) {
        setTagsSpecific([...tagsSpecific, { title: item.title, id: newId }])

        sendGAEvent({
          category: 'edit',
          action: 'specific-objective'
        })
      }
    }
  }

  const deleteSpecific = async (value) => {
    // delete
    const { success } = await endpointDelete(
      'objetivo especifico',
      'objetivos_especificos',
      value.id,
      {}
    )
    if (success) {
      const newtags = tagsSpecific.filter((val) => val.title !== value.title)
      setTagsSpecific(newtags)
    }
  }

  const refreshCourse = async () => {
    const { data } = await endpointGet('Curso', 'cursos', id)
    setCourse(data)
    setModules(arr => data.modulos)
  }

  const handleDeleteModule = async (moduleID) => {
    const { success } = await endpointDelete('Modulo', 'modulos', moduleID)

    if (success) {
      refreshCourse()
    }
  }

  const handleSendData = async (navigateAfter = true) => {
    const params = {
      id: courseId,
      owner,
      title,
      fecha_fin: formatDate(course.fecha_fin)
    }

    const { success } = await endpointEdit('Curso', 'cursos', id, {
      params
    })

    if (success) {
      if (navigateAfter) {
        setTimeout(() => {
          navigate(`/course/${id}`)
        }, 1000)
      }
    }
  }

  const dispatch = useDispatch()

  const handleExport = async (path, ext, platform) => {
    await endpointExport({
      path,
      courseId: id,
      ext
    })
    sendGAEvent({
      category: 'export',
      action: platform
    })

    dispatch(refreshUser())
  }

  // const setVisivility = (data) => {
  //   if (Object.keys(data.course_activities).length > 0) {
  //     setcalificationdisplay(true)
  //   }
  //   if (Object.keys(data.course_communication).length > 0) {
  //     setcomunicationdisplay(true)
  //   }
  // }

  const handleGenerate = async () => {
    try {
      const response = await axios.post(`${URLS.base}cursos/${course.id}/generate/`)
      if (response.status === 200) {
        setGeneratingCourse(response.data.generation_state)
        setCourse(response.data)
        dispatch(refreshUser())
      }
    } catch (error) {
      console.log(error)
      // dispatch(
      //   showToast({
      //     show: true,
      //     message: getServerErrors(error),
      //     type: ERROR_TYPES.WARNING,
      //   }),
      // );
    }

    // start polling
    startPollingGeneration()
  }

  let checkInterval

  const startPollingGeneration = () => {
    setGeneratingCourse(true)
    checkInterval = setInterval(checkGenerate, 10000)
  }

  const checkGenerate = async () => {
    try {
      const response = await axios.get(`${URLS.base}cursos/${id}/state/`)
      if (response.status === 200) {
        if (response.data.generation_state === 4 || response.data.generation_state === null) {
          clearInterval(checkInterval)
          setCourse(response.data)
          setDescription(response.data.description)
          setModules(arr => response.data.modulos)
          // setVisivility(response.data)
        }

        if (response.data.generation_state !== course.generation_state) {
          setCourse(response.data)
          setDescription(response.data.description)
          setModules(arr => response.data.modulos)
          // setVisivility(response.data)
        }

        setGeneratingCourse(response.data.generation_state)
        // setVisivility(response.data)
        refreshCourse()
        // window.scrollTo(0, document.body.scrollHeight);
      }
    } catch (error) {
      console.log(error)
      dispatch(
        showToast({
          show: true,
          message: getServerErrors(error),
          type: ERROR_TYPES.WARNING
        })
      )
    }
  }

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language}/>
      { loading
        ? <LoadingPage></LoadingPage>
        : <Body>
          { course?.title === false &&

              <FormHeader
                course={course}
                // title={ language.localization['step-2'] }
                save={handleSendData}
                backLink="/"
              />
          }
          <FormContainer>
            <CourseTitle title={title} course={course}/>

            {/* Objetivos Generales */}
            <Column>
              <ColumnContainer>
                <TextTitle>
                  <div className="row">
                    <img
                      style={{ width: '25px', marginRight: 10 }}
                      src={objetiveIcon}
                      alt={ language.localization['course-title'] }
                    />
                    <span className="titleForm">{ language.localization['course-objectives'] }</span>
                  </div>
                </TextTitle>
                {/* <span className="objetivesSubTitle">{ language.localization['general-objectives'] }</span> */}
                <span
                  className="objetivesDe  ription"
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  <span style={{ marginRight: 10 }}>
                  { language.localization['general-objectives-description'] }
                  </span>
                  <Tooltip
                    title={ language.localization['general-objectives-tooltip'] }
                    arrow
                    placement="right"
                  >
                    <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                  </Tooltip>
                </span>
                <div style={{ marginTop: 20 }}>
                  {tagsObjetives?.map((data, index) => {
                    return (
                      <ObjetiveItem
                        allowEdit={true}
                        allowDelete={true}
                        id={index}
                        data={data}
                        key={data.id}
                        deleteItem={deleteObjetive}
                        edit={(item) => {
                          saveOrCreateObjective(data)
                        }}
                      />
                    )
                  })}
                </div>
                <NewRowContainer>
                  <SmartTextfield
                    className="titleInput"
                    saveOnBlur={false}
                    onSave={(value) => {
                      saveOrCreateObjective({
                        curso: id,
                        title: value
                      })
                      setObjectiveText('')
                    }}
                    value={objetiveText}
                    onChange={(e) => setObjectiveText(e.target.value)}
                    style={{ marginTop: 20, width: '100%', maxLength: 100 }}
                    placeholder={ language.localization['general-objectives-placeholder'] }
                  />
                  <AddRounded style={{
                    width: 28,
                    height: 28,
                    marginRight: 5,
                    marginLeft: 8,
                    marginTop: 18,
                    color: Colors.primaryColor,
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      saveOrCreateObjective({
                        curso: id,
                        title: objetiveText
                      })
                      setObjectiveText('')
                    }}></AddRounded>
                </NewRowContainer>

              </ColumnContainer>
            </Column>

            {/* Objetivos especificos */}
            {false &&

              <Column>
                <ColumnContainer>
                  <span className="objetivesEspSubTitle">
                  { language.localization['specific-objectives'] }
                  </span>
                  <span
                    className="objetivesDescription"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span style={{ marginRight: 10 }}>
                    { language.localization['specific-objectives-description'] }
                    </span>
                    <Tooltip
                      title={ language.localization['specific-objectives-tooltip'] }
                      arrow
                      placement="right"
                    >
                      <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                    </Tooltip>
                  </span>

                  <div style={{ marginTop: 20 }}>
                    {tagsSpecific?.map((data, index) => {
                      return (
                        <ObjetiveItem
                          allowEdit={true}
                          allowDelete={true}
                          id={index}
                          data={data}
                          key={index}
                          deleteItem={deleteSpecific}
                          edit={(item) => {
                            sendOrCreateSpecific(data)
                          }}
                        />
                      )
                    })}
                  </div>
                  <NewRowContainer>
                    <SmartTextfield
                      className="titleInput"
                      saveOnBlur={false}
                      onSave={(value) => {
                        sendOrCreateSpecific({
                          curso: id,
                          title: value
                        })
                        setSpecificText('')
                      }}
                      value={specificText}
                      onChange={(e) => setSpecificText(e.target.value)}
                      style={{ marginTop: 20, width: '100%', maxLength: 100 }}
                      placeholder={ language.localization['general-objectives-placeholder'] }
                    />
                    <AddRounded style={{
                      width: 28,
                      height: 28,
                      marginRight: 5,
                      marginLeft: 8,
                      marginTop: 18,
                      color: Colors.primaryColor,
                      cursor: 'pointer'
                    }}
                        onClick={() => {
                          sendOrCreateSpecific({
                            curso: id,
                            title: specificText
                          })
                          setObjectiveText('')
                        }}></AddRounded>
                  </NewRowContainer>
                </ColumnContainer>
              </Column>
            }
            {/* Details */}
            <Grid container spacing={2} style={{ padding: 0 }}>
              <Grid item xs={12} md={4}>
                <Duration course={course} />
              </Grid>
              <Grid item xs={12} md={4}>
                <StudentProfile course={course} />
              </Grid>
              <Grid item xs={12} md={4}>
                <LearningEnvironments course={course} />
              </Grid>
            </Grid>

            {/* Modules */}
            <ModulesList course={course} onModulesChanged={(modules) => refreshCourse()}/>

            {/* Strategy */}
            <StrategySelector course={course} />

            {/* AI Button */}
            <ButtonFooter>
              { !generatingCourse &&
              <ExportButton
                user={user} icon={gptIcon}
                caption={language.localization['gpt-generate']}
                cost={user.costs?.gpt_generation_credits}
                onAccept={() => handleGenerate() }>
              </ExportButton>
              }
            </ButtonFooter>

            {/* Overview */}
            { (!generatingCourse) &&
              <Column>
                <ColumnContainer>
                  <TextTitle>
                    <div className="row">
                      <img
                        style={{ width: '25px', marginRight: 10 }}
                        src={objetiveIcon}
                        alt={ language.localization['course-description'] }
                      />
                      <span className="titleForm">{ language.localization['course-description'] }</span>
                      </div>
                  </TextTitle>
                  <Description
                    className="objetivesDescription"
                    style={{ marginTop: 16, display: 'flex' }}
                    >
                      <SmartTextarea
                        saveOnBlur
                        onSave={async (description) => {
                          const params = course
                          params.description = description
                          await endpointEdit('Curso', 'cursos', course.id, { params })
                        }}
                        onChange={(e) => { setDescription(e.target.value) }}
                        value={description}>
                    </SmartTextarea>
                    </Description>
                </ColumnContainer>
              </Column>
            }
            {/* Estrategia de comunicacion */}
            { !generatingCourse && false &&
                <CommunicationStrategy generating={generatingCourse} course={course} />
            }

            { !generatingCourse &&
              <Competences generating={generatingCourse} course={course} />
            }

            {/* Activities */}
            { !generatingCourse &&
              <Activities generating={generatingCourse} course={course} />
            }

            {/* Module List */}
            { (!generatingCourse) &&
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20 }}>
                    {modules?.map((data, index) => {
                      if (index < modules.length - 1) {
                        const d1 = new Date(data.date)
                        const d2 = new Date(modules[index + 1].date)
                        return (
                          <ModuleBox
                            duration={Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))}
                            order={index + 1} key={index} generating={false} module={data} deleteModule={handleDeleteModule} s
                            tyle={{ width: 300, cursor: 'pointer' }} />
                        )
                      } else {
                        const d1 = new Date(data.date)
                        const d2 = new Date(course.fecha_fin)
                        return (
                            <ModuleBox
                              duration={Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))}
                              order={index + 1} key={index} generating={false} module={data} deleteModule={handleDeleteModule}
                              style={{ width: 300, cursor: 'pointer' }} />
                        )
                      }
                    })}
                </div>
            }

            {/* Export */}
            { !generatingCourse &&
                <ButtonFooter>
                  <ExportButton user={user} icon={wordIcon} caption={language.localization['export-to-word']}
                    cost={user.costs?.word_export_credits}
                    onAccept={() => handleExport('docx', 'docx', 'word')}>
                  </ExportButton>
                  <ExportButton user={user} icon={moodleIcon} caption={language.localization['export-to-moodle']}
                    cost={user.costs?.lti_export_credits}
                    onAccept={() => handleExport('imscc', 'zip', 'moodle')}>
                  </ExportButton>
                  <ExportButton user={user} icon={schoologyIcon} caption={language.localization['export-to-schoology']}
                    cost={user.costs?.lti_export_credits}
                    onAccept={() => handleExport('imscc', 'zip', 'schoology')}>
                  </ExportButton>
              </ButtonFooter>
            }

            { (generatingCourse) &&
              <Column>
                <ColumnContainer>
                  <TextTitle>
                    <div className="row">
                      <img
                        style={{ width: '25px', marginRight: 10 }}
                        src={objetiveIcon}
                        alt={ language.localization['generating-course'] }
                      />
                      <span className="titleForm">{ language.localization['generating-course'] }</span>
                    </div>
                  </TextTitle>
                </ColumnContainer>
                <Lottie options={lottieAnimation} width={400} height={400}></Lottie>
                <ColumnContainer>
                  <Typography>
                      { language.localization['generating-course-tips'] }
                  </Typography>
                </ColumnContainer>
              </Column>
            }

          </FormContainer>

          {/* <AIWorking>
            <AILogo sx={{width:1000}}/>
          </AIWorking> */}
        </Body>
      }

      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}

      <FooterComponent language={language} />
    </Container>
  )
}

CourseScreen.propTypes = {
  toastProps: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointDelete: PropTypes.any,
  endpointExport: PropTypes.any,
  showToast: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any,
  user: PropTypes.any
}

export default connect(CourseScreen)
