import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { setTokenAxios } from '../utils/setTokenAxios'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const endpointDelete =
  (name, endpoint, id) =>
    async (dispatch) => {
      try {
        await setTokenAxios()

        const { status } = await axios.delete(
        `${URLS.base}${endpoint}/${id}/`
        )
        if (status === 204) {
          dispatch(
            showToast({
              show: true,
              message: `El ${name} se eliminó correctamente!`,
              type: ERROR_TYPES.SUCCESS
            })
          )
          return {
            success: true
          }
        } else {
          dispatch(
            showToast({
              show: true,
              message: `Ups! Ocurrió un error al eliminar el ${name}.`,
              type: ERROR_TYPES.ERROR
            })
          )
          return {
            success: false,
            id: null
          }
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
        return {
          success: false,
          id: null
        }
      }
    }

export default endpointDelete
