import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ColumnContainer, TextTitle } from '../../../screens/stepOne/style'
import { Tooltip } from '@mui/material'

import checksIcon from '../../../assets/icons/checks.png'
import connect from '../connect'
import InfoIcon from '@mui/icons-material/Info'
import { Colors } from '../../../constants/Theme'
import Activityitem from './ActivityItem'
import CreateActivityItem from './CreateActivityItem'
import showToast from '../../../actions/Toast'
import { ERROR_TYPES } from '../../../constants/ErrorTypes'
import { sendGAEvent } from '../../../utils/useGAPageView'
import { useParams } from 'react-router-dom'
import { HeaderContainer, HeaderItem } from '../style'
import { Column } from '../../../screens/course/style'

const Activities = ({ setLanguage, course, language, endpointCreate, endpointEdit, endpointDelete, generating }) => {
  const [activities, setNewAtivities] = useState([])

  const { id } = useParams()

  useEffect(() => {
    setNewAtivities(course?.course_activities)
  }, [course])

  const checkActivity = () => {
    let total = 0
    if (activities.length > 0) {
      total = activities
        .map((item) => item.percentage)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
    }
    if (total > 100) {
      showToast({
        show: true,
        message: 'Te has exedido el 100%',
        type: ERROR_TYPES.WARNING
      })

      return false
    }
    return true
  }

  const saveActivity = async ({ title, percentage }) => {
    // create
    const params = { course: id, title, percentage }
    const { success, newId } = await endpointCreate(
      'Actividad',
      'course_activities',
      { params },
      checkActivity()
    )
    if (success) {
      // update local
      setNewAtivities([...activities, { title, percentage, id: newId }])
      sendGAEvent({
        category: 'edit',
        action: 'activity'
      })
    }
    return success
  }
  const editActivity = async (item) => {
    // edit
    const params = { course: id, id: item.id, title: item.title, percentage: item.percentage }
    // update local
    activities.forEach((a) => {
      if (a.id === item.id) {
        a.title = item.title
        a.percentage = item.percentage
      }
    })
    await endpointEdit(
      'Actividad',
      'course_activities',
      item.id,
      { params },
      checkActivity()
    )
  }
  const deleteActivity = async (value) => {
    // delete
    const { success } = await endpointDelete(
      'Actividad',
      'course_activities',
      value.id,
      {}
    )
    if (success) {
      // update local
      const newActivities = activities.filter((val) => val.id !== value.id)
      setNewAtivities(newActivities)
    }
  }

  return (
    <Column generating={generating}>
    <ColumnContainer>
    <TextTitle>
      <div className="row">
        <img
          style={{ width: '25px', marginRight: 10 }}
          src={checksIcon}
          alt="Título del curso"
        />
        <span
          className="titleForm"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span style={{ marginRight: 10 }}>
            { language.localization['general-assessment-of-course'] }
            </span>
            <Tooltip
            title={ language.localization['general-assessment-of-course-tooltip'] }
            arrow
            placement="right"
            >
            <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
          </Tooltip>
        {/* <AutoFixHighIcon style={{display:'block', margin:10, cursor:'pointer', color:Colors.orange}}/> */}
        </span>
      </div>
      <span
      className="objetivesDescription"
      style={{ marginTop: 16, display: 'flex' }}
      >
      { language.localization['general-assessment-of-course-description'] }
      </span>
    </TextTitle>
    <HeaderContainer>
      <HeaderItem sx={{
        width: { xs: '70%', md: '85%' }
      }}>
        { language.localization['activity-task'] }
      </HeaderItem>
      <HeaderItem sx={{
        width: { xs: '31%', md: '19%' }
      }}>
        { language.localization['weight-factor'] }
      </HeaderItem>
    </HeaderContainer>
    <div>
      {activities?.map((a) => (
        <Activityitem
          item={a}
          key={a.title}
          setActivitiName={() => {}}
          setActivityPorcentage={() => {}}
          deleteItem={deleteActivity}
          editItem={editActivity}
        />
      ))}
    </div>
    <CreateActivityItem save={saveActivity} setLanguage={setLanguage} language={language}/>
    </ColumnContainer>
  </Column>

  )
}

Activities.propTypes = {
  setLanguage: PropTypes.any,
  language: PropTypes.any,
  course: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointDelete: PropTypes.any,
  generating: PropTypes.any
}

export default connect(Activities)
