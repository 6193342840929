import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Column, ColumnContainer, TextTitle } from '../../../screens/stepOne/style'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import usersIcon from '../../../assets/icons/users.png'

import connect from '../connect'
import { getFormErrors } from '../../../utils/serverErrors'

const StudentProfile = ({ course, language, endpointEdit }) => {
  const [errorAge, setErroAge] = useState(false)
  // const [scolarshipError, setScolarshipError] = useState(false)

  const [studentAge, setStudentAge] = useState('12_18')
  // const [studentTraining, setStudentTraining] = useState('se')

  useEffect(() => {
    if (course) {
      if (course.estudiantes_edad) { setStudentAge(course.estudiantes_edad) }
      // if (course.estudiantes_formacion) { setStudentTraining(course.estudiantes_formacion) }
    }
  }, [course])

  const saveAge = async (age) => {
    const params = course
    course.estudiantes_edad = age
    const { success, error } = await endpointEdit('Curso', 'cursos', course.id, { params })
    checkErrors(success, error)
  }

  // const saveTraining = async (training) => {
  //   const params = course
  //   course.estudiantes_formacion = training
  //   const { success, error } = await endpointEdit('Curso', 'cursos', course.id, { params })
  //   checkErrors(success, error)
  // }

  const checkErrors = (success, error) => {
    if (!success) {
      const errors = getFormErrors(error)
      errors.forEach((error) => {
        switch (error.name) {
          case 'estudiantes_edad': setErroAge(true)
            break
          // case 'estudiantes_formacion': setScolarshipError(true)
          //   break
          default: break
        }
      })
      console.log(errors)
    }
  }

  return (
    <Column sx={{ minWidth: { xs: 'auto', md: 420 } }}>
      <ColumnContainer>
        <TextTitle>
        <div className="row">
          <img
          style={{ height: 15, width: 20 }}
          className="iconForm"
          src={usersIcon}
          alt={ language.localization['student-profile-and-engagement'] }
          />
          <span className="titleForm">
          { language.localization['student-profile-and-engagement'] }
          </span>
        </div>
        <span
          className="objetivesDescription"
          style={{ marginTop: 16, display: 'flex' }}
        >
          { language.localization['student-profile-and-engagement-description'] }
        </span>
        </TextTitle>
        <FormControl style={{ margin: '15px 5px', width: '90%' }}>
        <InputLabel id="demo-simple-select-helper-label">
        { language.localization.age }
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={studentAge}
          label="Edad"
          error={errorAge}
          onChange={(e) => {
            setStudentAge(e.target.value)
            setErroAge(false)
            saveAge(e.target.value)
          }}
        >
          <MenuItem value={'6_12'}>6-12</MenuItem>
          <MenuItem value={'12_18'}>12-18</MenuItem>
          <MenuItem value={'18_25'}>18-25</MenuItem>
          <MenuItem value={'M_25'}>más de 25</MenuItem>
        </Select>
        </FormControl>
        {/* <FormControl style={{ margin: '15px 5px', width: '90%' }}>
        <InputLabel fullwidth id="demo-simple-select-helper-label">
        { language.localization['academic-qualifications'] }
        </InputLabel>
        <Select fullwidth
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={studentTraining}
          label="Formación"
          error={scolarshipError}
          onChange={(e) => {
            setStudentTraining(e.target.value)
            setScolarshipError(false)
            saveTraining(e.target.value)
          }}
        >
          <MenuItem value={'pr'}>{ language.localization['K-12'] }</MenuItem>
          <MenuItem value={'se'}>{ language.localization['high-school'] }</MenuItem>
          <MenuItem value={'te'}>{ language.localization.bachelor }</MenuItem>
          <MenuItem value={'un'}>{ language.localization.universtiy }</MenuItem>
          <MenuItem value={'po'}>{ language.localization.postgraduate }</MenuItem>
        </Select>
        </FormControl> */}
      </ColumnContainer>
    </Column>

  )
}

StudentProfile.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointEdit: PropTypes.any
}

export default connect(StudentProfile)
