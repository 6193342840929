import * as React from 'react'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../constants/Theme'
import TextareaAutosize from '@mui/base/TextareaAutosize'

const TextAreaInnerInput = styled(TextareaAutosize)`
  width:100%;
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
  border: unset;
  border-bottom: 1px solid gray;
  :focus-visible {
    outline: unset;
    border-bottom: 2px solid ${Colors.orange};
  }
`

const TextAreaInnerTextArea = styled(TextField)`
  width:100%;
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
`

export const SmartTextarea = ({ onSave, saveOnBlur, autoClean, inputRef, input = 'input', ...props }) => {
  let lastValue

  const onKeyDown = (e) => {
    // do nothing, text areas must allow adding line breaks
    // if (e.keyCode === 13) {
    //   e.preventDefault()
    //   if (e.target.value !== '') {
    //     if (onSave) {
    //       onSave(e.target.value)
    //     }
    //     lastValue = e.target.value
    //   }
    // }
  }

  const onBlur = (e) => {
    if (!saveOnBlur) return
    // console.log(e.target.value, lastValue)
    if (e.target.value !== '' && e.target.value !== lastValue) {
      if (onSave) {
        onSave(e.target.value)
      }

      lastValue = e.target.value
    }
  }

  return input === 'input'
    ? (<TextAreaInnerInput
            {...props}
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
    />)
    : (
      <TextAreaInnerTextArea
                {...props}
                inputRef={inputRef}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
              />
      )
}

SmartTextarea.propTypes = {
  onSave: PropTypes.any,
  saveOnBlur: PropTypes.any,
  autoClean: PropTypes.any,
  inputRef: PropTypes.any,
  input: PropTypes.any
}
