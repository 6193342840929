import React, { useState, useEffect } from 'react'
import connect from './connect'
import { useGAPageView } from '../../utils/useGAPageView'
import Header from '../../components/Header'
import setLanguage from '../../actions/SetLanguage'
import { Container, Row } from '../dashboard/style'
import PropTypes from 'prop-types'

import { URLS } from '../../utils/urls'
import axios from 'axios'

const SearchScreen = ({ language }) => {
  useGAPageView()

  const [results, setResults] = useState([])
  const [terms, setTerms] = useState('')

  const getResults = async (terms) => {
    const url = terms ? `${URLS.base}search/${terms}/` : `${URLS.base}search/`
    const result = await axios.get(url)
    setResults(result.data)
  }

  useEffect(() => {
    getResults(null)
  }, [])

  const resultsList = results.map((item, i) =>
    <Row key={'result' + i}>
        <p>{item.title}</p>
    </Row>
  )

  const doSearch = async () => {
    console.log(terms.split(' ').join('+'))
    getResults(terms.split(' ').join('+'))
  }

  return (
    <Container>
        <Header setLanguage={setLanguage} language={language}/>
        <input value={terms} onChange={(e) => {
          setTerms(e.target.value)
        }}></input>
        <button onClick={doSearch}>search</button>
        { resultsList }
    </Container>

  )
}

SearchScreen.propTypes = {
  language: PropTypes.any
}

export default connect(SearchScreen)
