import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const resetPassword =
  (email) =>
    async (dispatch) => {
      try {
        const response = await axios.post(`${URLS.base}rest-auth/password/reset/`, { email })
        if (response) {
          dispatch(
            showToast({
              show: true,
              message: `Te hemos enviado un correo a ${email}`,
              type: ERROR_TYPES.SUCCESS
            })
          )

          return response
        } else {
          dispatch(
            showToast({
              show: true,
              message: 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
      }
    }

export default resetPassword
