import React, { useState, useEffect } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'

import ToastMessage from '../../components/ToastMessage'
import Header from '../../components/Header'

import { Container, Inner, NoCoursesBubble, NoCoursesBubbleTip, Row } from './style.js'

import { Colors } from '../../constants/Theme'
// import LoadingPage from '../../components/LoadingPage'

import addWhiteIcon from '../../assets/icons/addWhite.png'
import lisIcon from '../../assets/icons/checks.png'
import AlertDialog from '../../components/Dialog'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useGAPageView } from '../../utils/useGAPageView'
import Footer from '../../components/Footer'
import styled from 'styled-components'
import trashIcon from '../../assets/icons/trash.png'

const ITEM_HEIGHT = 48

export const ListItemClickeable = styled(ListItem)`
    cursor: pointer;
`
const DashboardScreen = ({
  endpointGet,
  endpointDelete,
  endpointCreate,
  toastProps,
  showToast,
  setLanguage,
  language
}) => {
  const [courses, setCourses] = useState(null)
  // const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [courseId, setCourseId] = useState(null)

  useGAPageView()

  useEffect(() => {
    // setLoading(true)
    const fetchCourse = async () => {
      const result = await endpointGet('Usuario', 'me', null)
      if (result) {
        const { cursos } = result.data
        setCourses(cursos)
      }
      // setLoading(false)
    }
    fetchCourse()
  }, [endpointGet])

  const navigate = useNavigate()
  const goToCourse = () => {
    navigate(`/course/${courseId}`)
  }
  const goToCourseFromDiv = (id) => {
    navigate(`/course/${id}`)
  }

  const deleteCourseHandler = async () => {
    const { success } = await endpointDelete('Curso', 'cursos', courseId)

    if (success) {
      // update courses
      const { data } = await endpointGet('Usuario', 'me', null)
      if (data) {
        const { cursos } = data
        setCourses(cursos)
      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event, id) => {
    setCourseId(id)
    // setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const newCourse = async () => {
    const result = await endpointCreate(
      'curso',
      'cursos',
      { params: {} },
      () => {

      }
    )
    console.log(result.newId)
    if (result.success) {
      navigate(`course/${result.newId}`)
    }
  }

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language}/>
        <Row>
          <Inner>
            <div style={{ display: 'flex', flexDirction: 'column' }}>
            <div
                style={{
                  background: Colors.orange,
                  color: Colors.white,
                  fontWeight: 500,
                  borderRadius: 20,
                  padding: '10px 15px',
                  width: '110px',
                  height: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  newCourse()
                }}
              >
                { language.localization['new-le'] }
                <img src={addWhiteIcon} width={15} />
              </div>

              {courses && courses.length === 0 &&
                <NoCoursesBubble>
                  <NoCoursesBubbleTip></NoCoursesBubbleTip>
                  { language.localization['first-new-le-tooltip'] }
                </NoCoursesBubble>
              }

            </div>
            <div
              style={{
                padding: '10px 15px',
                marginTop: 30,
                width: '95%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                borderBottom: `1px solid ${Colors.blackText}`
              }}
            >
              <img src={lisIcon} width={20} />
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: Colors.blackText,
                  marginLeft: 10
                }}
              >
                { language.localization['all-my-courses'] }
              </span>
            </div>
            <List>
              {courses &&
                courses.map((m) => (
                  <ListItem key={m.id} disableGutters>
                    <div
                      style={{
                        background: Colors.white,
                        padding: 15,
                        borderRadius: 6,
                        fontWeight: 500,
                        color: Colors.blackText,
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <ListItemClickeable
                      onClick={() => {
                        goToCourseFromDiv(m.id)
                      }}>
                      <span>
                      {m.title === '' &&
                         language.localization['no-title']
                      }
                      {m.title}</span>
                      </ListItemClickeable>
                      <div style={{ display: 'flex' }}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? `long-menu-${m.id}` : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(event) => {
                            handleClick(event, m.id)
                          }}
                        >
                          <img
                            style={{ width: 20, cursor: 'pointer' }}
                            src={trashIcon}
                            alt="cerrar"
                            onClick={() => {
                              setShowAlert(true)
                            }}
                          />
                        </IconButton>
                        <Menu
                          id={`long-menu-${m.id}`}
                          MenuListProps={{
                            'aria-labelledby': 'long-button'
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch'
                            }
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              goToCourse()
                              handleClose()
                            }}
                          >
                            { language.localization.edit }
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setShowAlert(true)
                              handleClose()
                            }}
                          >
                            { language.localization.delete }
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </ListItem>
                ))}
            </List>
          </Inner>
        </Row>
          {/* )} */}
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
      {showAlert && (
        <AlertDialog
          action={deleteCourseHandler}
          type="curso"
          closeModal={() => {
            setShowAlert(false)
          }}
        />
      )}
      <Footer language={language}/>
    </Container>
  )
}

DashboardScreen.propTypes = {
  toastProps: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointDelete: PropTypes.any,
  endpointExport: PropTypes.any,
  showToast: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any,
  user: PropTypes.any
}

export default connect(DashboardScreen)
