import {
  connect
} from 'react-redux'
import {
  compose
} from 'redux'

import endpointCreate from '../../actions/EndpointCreate'
import endpointEdit from '../../actions/EndpointEdit'
import endpointDelete from '../../actions/EndpointDelete'
import endpointGet from '../../actions/EndpointGet'
import showToast from '../../actions/Toast'
import setLanguage from '../../actions/SetLanguage'

import {
  getShowToast
} from '../../selectors/selectors'

const mapStateToProps = (state) => ({
  toastProps: getShowToast(state),
  language: state.language
})

const mapDispatchToProps = {
  endpointCreate,
  endpointEdit,
  endpointDelete,
  endpointGet,
  showToast,
  setLanguage
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
