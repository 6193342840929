import * as actions from '../types/actionTypes'

const initialState = {
  course: {}
}

export default function GetCourse (state = initialState, action) {
  switch (action.type) {
    case actions.GET_COURSE_ERROR:
      return {
        course: {}
      }

    case actions.GET_COURSE_SUCCESS:
      return {
        course: action.payload
      }

    default:
      return state
  }
}
