import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const initialize = () => {
  ReactGA.initialize('G-7GP9T5FW7T')
}

export const useGAPageView = () => {
  initialize()
  const location = useLocation()
  useEffect(() => {
    // console.log('/'+location.pathname.split('/')[1])
    ReactGA.send({ hitType: 'pageview', page: '/' + location.pathname.split('/')[1] })
    // scroll to the top of the browser window when changing route
    // the window object is a normal DOM object and is safe to use in React.
  }, [location])
}

export const sendGAEvent = (event) => {
  initialize()
  // console.log(event)
  ReactGA.event(event)
}
