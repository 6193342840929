import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const endpointGet =
  (name, endpoint, id) =>
    async (dispatch) => {
      try {
        let response
        if (id) {
          response = await axios.get(`${URLS.base}${endpoint}/${id}/`)
        } else {
          response = await axios.get(`${URLS.base}${endpoint}/`)
        }

        if (response) {
          return response
        } else {
          dispatch(
            showToast({
              show: true,
              message: `Ups! Ocurrió un error al traer el ${name}.`,
              type: ERROR_TYPES.ERROR
            })
          )
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
      }
    }

export default endpointGet
