import React, { useState, useEffect, useRef } from 'react'
import connect from './connect'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { enCA, es } from 'date-fns/locale'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import TopicsTable from '../../components/topicsTable/TopicsTable'

import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

import {
  Container,
  FormContainer,
  ColumnContainer,
  TextTitle,
  Row,
  BackButton,
  ButtonFooter
} from './style.js'

import { Colors } from '../../constants/Theme'
import Header from '../../components/Header'
import FormHeader from '../../components/FormHeader'

import folderIcon from '../../assets/icons/drive_folder_upload.png'
import schoolIcon from '../../assets/icons/school.png'
import noteIcon from '../../assets/icons/note.png'
import objectiveIcon from '../../assets/icons/objetive.png'

import Activityitem from '../../components/boxes/Activities/ActivityItem'
import CreateActivityItem from '../../components/boxes/Activities/CreateActivityItem'

import backColorIcon from '../../assets/icons/backColor.png'
import LoadingPage from '../../components/LoadingPage'
import ToastMessage from '../../components/ToastMessage'
import { formatDate } from '../../utils/dates'

import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'

import { ERROR_TYPES } from '../../constants/ErrorTypes'
import { useFocus } from '../../components/useFocus'
import { SmartTextfield } from '../../components/SmartTextfield'
import { Body, Column, NewRowContainer, PickerContainer } from '../stepOne/style'
import { AddRounded } from '@mui/icons-material'
import { sendGAEvent, useGAPageView } from '../../utils/useGAPageView'

import { HeaderContainer, HeaderItem } from '../../components/boxes/style'
import FooterComponent from '../../components/Footer'
import { SmartTextarea } from '../../components/SmartTextarea'
import ModuleCompetences from '../../components/boxes/ModuleCompetences/ModuleCompetences.jsx'

const ModuleScreen = ({
  endpointGet,
  endpointCreate,
  endpointEdit,
  endpointDelete,
  toastProps,
  showToast,
  setLanguage,
  language
}) => {
  const [course, setCourse] = useState({})
  // const [moduleOrder, setModuleOrder] = useState('')
  const [moduleName, setModuleName] = useState('')
  const [moduleDuration, setModuleDuration] = useState(null)
  // const [date, setDate] = useState(null);
  const [date, setDate] = useState(new Date())

  const [activities, setActivities] = useState([])

  // module objectives
  const [moduleObjectives, setModuleObjectives] = useState([])

  // const [strategies] = useState([])

  const [loading, setLoading] = useState(true)

  const [newobjetive, setNewObjetive] = useState('')

  const [showErrorActivities, setShowErrorActivities] = useState(false)

  const [module, setModule] = useState([])
  const [description, setDescription] = useState('')

  useGAPageView()

  useEffect(() => {
    setActivities(course?.course_activities)
  }, [course])

  const checkActivities = () => {
    let total = 0
    if (activities.length > 0) {
      total = activities
        .map((item) => item.percentage)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
    }
    if (total > 100) {
      showToast({
        show: true,
        message: 'Te has exedido el 100%',
        type: ERROR_TYPES.WARNING
      })
      return false
    }
    return true
  }

  const saveActivity = async ({ title, percentage }) => {
    const params = { module: mid, title, percentage }
    const { success, newId } = await endpointCreate(
      'Actividad',
      'module_activities',
      { params },
      checkActivities()
    )

    if (success) {
      setActivities([...activities, { title, percentage, id: newId }])

      sendGAEvent({
        category: 'edit',
        action: 'module-activity'
      })
    }
    return success
  }

  const editActivity = async (params) => {
    await endpointEdit(
      'Actividad',
      'module_activities',
      params.id,
      { params },
      checkActivities()
    )
  }
  const deleteActivity = async (value) => {
    const { success } = await endpointDelete(
      'Actividad',
      'module_activities',
      value.id,
      {}
    )
    console.log(success)
    if (success) {
      const newActivities = activities.filter(
        (val) => val.title !== value.title
      )
      console.log(newActivities)
      setActivities(newActivities)
      setShowErrorActivities(false)
    }
  }

  const refreshObjectives = async () => {
    const { data } = await endpointGet('Modulos', 'modulos', mid)
    setModule(data)

    // setModuleObjectives(data.module_objectives);
    // checkCourseObjectives(data.module_objectives);
  }

  const deleteObjective = async (value) => {
    const { success } = await endpointDelete(
      'Objetivo de Modulo',
      'module_objectives',
      value.id,
      {}
    )
    if (success) {
      refreshObjectives()
    }
  }

  const navigate = useNavigate()
  const { id, mid } = useParams()

  useEffect(() => {
    if (id && mid) {
      setLoading(true)

      const fetcModule = async () => {
        try {
          const courseResponse = await endpointGet('Curso', 'cursos', id)
          setCourse(courseResponse.data)
          const moduleResponse = await endpointGet('Modulo', 'modulos', mid)
          setModule(moduleResponse.data)
          setDescription(moduleResponse.data.description)
          setLoading(false)
        } catch (error) {
          navigate('/')
        }
      }
      fetcModule()
    }
  }, [endpointGet, mid, id, navigate])

  const editModuleTitleRef = useRef(null)
  const generalObjectivesTitleRef = useRef(null)
  const [generalObjectivesInputRef] = useFocus()

  const refreshModule = async (lalala, editAfter = false) => {
    // setLoading(true);
    const courseResponse = await endpointGet('Curso', 'cursos', id)
    setCourse(courseResponse.data)
    const moduleResponse = await endpointGet('Modulo', 'modulos', mid)
    setModule(moduleResponse.data)

    // setLoading(false);
  }

  useEffect(() => {
    // setModuleOrder(module.orden)
    setModuleName(module.title)
    setModuleDuration(module.duration)
    if (module.date) { setDate(module.date) }
  }, [module])

  const handleSaveModule = async () => {
    const params = {
      title: moduleName,
      // orden: parseInt(moduleOrder),
      duration: parseInt(moduleDuration),
      date: formatDate(date),
      curso: parseInt(id),
      description
    }

    // edit module
    const response = await endpointEdit('Modulo', 'modulos', mid, {
      params
    })
    // refresh
    if (response.success) {
      refreshModule(response.id)
    }
  }

  const addNewObjetive = async (title) => {
    if (title !== '') {
      const params = { module: mid, title }
      const { success, id } = await endpointCreate(
        'Objetivo de modulo',
        'module_objectives',
        { params }
      )
      if (success) {
        setModuleObjectives([...moduleObjectives, { id, title }])
        setNewObjetive('')
        refreshObjectives()

        sendGAEvent({
          category: 'edit',
          action: 'module-objective'
        })
      }
    }
  }

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language}/>
      {loading
        ? <LoadingPage></LoadingPage>
        : (
        <Body>
          <FormHeader
            // title= { language.localization['planning-each-module'] }
            course={course}
            module={module}
            save={() => {}}
            backLink={`/course/${id}`}
          />
          {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
            {strategies.length > 0 &&
              strategies.map((s, i) => <Tag key={`tag_${i}`} text={language.localization[s.title]} />)}
          </div> */}
          <FormContainer>

              <Column ref={editModuleTitleRef} style={{ marginTop: 20 }}>
                <ColumnContainer>
                  <TextTitle>
                    <div className="row">
                      <img
                        alt='listado de modulos'
                        style={{ width: '25px', marginRight: 10 }}
                        src={folderIcon}
                      />
                      <span
                        className="titleForm"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <span style={{ marginRight: 10 }}>
                          { language.localization['edit-course-module']}
                        </span>
                        <Tooltip
                          title={ language.localization['create-a-module-tooltip'] }
                          arrow
                          placement="right"
                        >
                          <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                        </Tooltip>

                      </span>

                    </div>
                  </TextTitle>

                  <span className="objetivesDescription">
                  { language.localization['create-a-module-description'] }
                  </span>

                  <span className="objetivesSubTitle">{ language.localization['general-information'] }</span>
                  <Row
                    style={{ marginTop: 30, display: 'flex', flexWrap: 'wrap' }}
                  >
                    {/* <SmartTextfield
                      input='textfield'
                      style={{ margin: 10, display: 'flex', flex: 1 }}
                      value={moduleOrder}
                      label={ language.localization['module-number'] }
                      type="number"
                      onChange={(e) => setModuleOrder(e.target.value)}
                      saveOnBlur
                      onSave={(value) => {
                        setModuleOrder(value)
                        handleSaveModule()
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      // onKeyPress={(e) => sendOnEnter(e, ()=>{handleSaveModule()})}
                    /> */}
                    <SmartTextfield
                      style={{ margin: 10, display: 'flex', flex: 4 }}
                      value={moduleName}
                      label={ language.localization['module-name'] }
                      InputLabelProps={{
                        shrink: true
                      }}
                      input='textfield'
                      saveOnBlur
                      onChange={(e) => setModuleName(e.target.value)}
                      onSave={(value) => {
                        setModuleName(value)
                        handleSaveModule()
                      }}
                      // onKeyPress={(e) => sendOnEnter(e, ()=>{handleSaveModule()})}
                    />
                    </Row>
                    <Row>
                    <PickerContainer style={{ width: 'auto' }}>
                        <LocalizationProvider
                        dateAdapter={AdapterDayjs} locale={language.lang === 'es' ? es : enCA}>
                        <DatePicker
                          // inputProps={{ style: { display: 'flex', flex: 1 } }}
                          label={ language.localization['start-date'] }
                          // eslint-disable-next-line new-cap
                          value={dayjs(date) || new dayjs()}
                          onChange={(newValue) => {
                            setDate(newValue)
                            handleSaveModule()
                          }}
                        />
                        </LocalizationProvider>
                      </PickerContainer>
                      {/* <SmartTextfield
                      style={{ margin: 10, width: 'auto' }}
                      value={moduleDuration}
                      label={ language.localization['duration-weeks'] }
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        setModuleDuration(e.target.value)
                      }}
                      input='textfield'
                      saveOnBlur
                      onSave={(value) => {
                        setModuleDuration(value)
                        handleSaveModule()
                      }}
                      // onKeyPress={(e) => sendOnEnter(e, ()=>{handleSaveModule(id)})}
                      /> */}
                  </Row>

                  {/* { false && */}
                    {/* } */}
                    {/* <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={language.lang === 'es' ? es : enCA}
                    >
                      <DatePicker
                        style={{ marginRight: 10 }}
                        label={ language.localization['start-date'] }
                        // openTo="year"
                        okLabel="Guardar"
                        cancelLabel="Cerrar"
                        // views={['year', 'month', 'day']}
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => <TextField onKeyPress={(e) => sendOnEnter(e, ()=>{handleSaveModule(id)})} {...params} />}
                      />
                    </LocalizationProvider> */}

                </ColumnContainer>
              </Column>
            {/* DESCRIPTION */}
            <Column>
              <ColumnContainer>
                <TextTitle>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      alt='module description objectives'
                      style={{ width: '25px', marginRight: 10 }}
                      src={objectiveIcon}
                      />
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 18,
                        fontWeight: 500
                      }}
                      >
                      { language.localization['module-description'] }
                    </span>
                  </span>
                  <div style={{ marginTop: 40 }}>
                      <SmartTextarea
                          saveOnBlur
                          onSave={handleSaveModule}
                          onChange={(e) => { setDescription(e.target.value) }}
                          value={description}>
                      </SmartTextarea>
                    </div>
                </TextTitle>
              </ColumnContainer>
            </Column>
            {/* OBJECTIVES */}
            {module && (
              <Column ref={generalObjectivesTitleRef}>
                <ColumnContainer>
                  <TextTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        alt='learning objectives'
                        style={{ width: '25px', marginRight: 10 }}
                        src={objectiveIcon}
                      />
                      <span
                        style={{
                          marginRight: 10,
                          fontSize: 18,
                          fontWeight: 500
                        }}
                      >
                        { language.localization['learning-objectives'] }
                      </span>
                      <Tooltip
                        title= { language.localization['learning-objectives-tooltip'] }
                        arrow
                        placement="right"
                      >
                        <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                      </Tooltip>
                    </span>
                  </TextTitle>

                  {/* <span
                    className="objetivesDescription"
                    style={{ marginBottom: 20 }}
                  >
                    Seleciona y agrega los objetivos de aprendizaje que
                    desarrollarán los estudiantes en este módulo.
                  </span>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <span>
                      { language.localization['learning-objectives-description-2'] }
                      </span>
                    </AccordionSummary>
                    {courseObjectives?.map((item, index) => (
                      <AccordionDetails key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                saveObjetive({
                                  checked: e.target.checked,
                                  title: item.title,
                                });
                              }}
                            />
                          }
                          label={item.title}
                        />
                      </AccordionDetails>
                    ))}
                  </Accordion> */}
                  <div style={{ padding: '30px 0 0 0' }}>
                    {module.module_objectives?.map((item, index) => (
                      <Row
                        key={`obj_${index}`}
                        style={{ display: 'flex', padding: '10px 0' }}
                      >
                        <span style={{ flex: 11, width: '100%' }}>{item.title}</span>
                        <ClearRoundedIcon
                          style={{
                            width: '25px',
                            marginRight: '2px',
                            cursor: 'pointer',
                            color: Colors.primaryColor
                          }}
                          alt="Eliminar"
                          onClick={() => {
                            deleteObjective(item)
                          }}
                        />
                      </Row>
                    ))}
                  </div>
                  <NewRowContainer style={{ marginTop: 10 }}>
                    <SmartTextfield
                      inputRef={generalObjectivesInputRef}
                      className="titleInput"
                      value={newobjetive}
                      inputProps={{ maxLength: 300 }}
                      onChange={(e) => setNewObjetive(e.target.value)}
                      placeholder={ language.localization['learning-objectives-placeholder'] }
                      onSave={() =>
                        addNewObjetive(newobjetive)}
                    />
                    <AddRounded style={{
                      width: 28,
                      height: 28,
                      // marginRight: 6,
                      color: Colors.primaryColor,
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        if (newobjetive) addNewObjetive(newobjetive)
                      }}>
                    </AddRounded>
                  </NewRowContainer>
                </ColumnContainer>
              </Column>
            )}
            {/* COMPETENCES */}
            <ModuleCompetences module={module} />
            {/* ACTIVITIES */}
            {module && (
              <Column>
                <ColumnContainer>
                  <TextTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        alt='activities'
                        style={{ width: '25px', marginRight: 10 }}
                        src={schoolIcon}
                      />
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 18,
                        fontWeight: 500
                      }}
                    >
                      { language.localization.assessment }
                    </span>
                    <Tooltip
                      title= { language.localization['assessment-tooltip'] }
                      arrow
                      placement="right"
                    >
                      <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                    </Tooltip>
                    </span>
                  </TextTitle>
                  <span className="objetivesDescription">
                  { language.localization['assessment-description'] }
                  </span>

                  <HeaderContainer style={{ marginTop: 20 }}>
                  <HeaderItem style={{ flex: 8 }}>
                    { language.localization['activity-task'] }
                  </HeaderItem>
                  <HeaderItem style={{ flex: 2 }}>
                    { language.localization.grade }
                  </HeaderItem>
                </HeaderContainer>
                <div>
                      {module.module_activities?.map((a, i) => (
                                  <Activityitem
                                    key={`act_${i}`}
                                    item={a}
                                    setActivitiName={() => {}}
                                    setActivityPorcentage={() => {}}
                                    deleteItem={deleteActivity}
                                    editItem={editActivity}
                                  />
                      ))}

                </div>
        <CreateActivityItem save={saveActivity} setLanguage={setLanguage} language={language}/>

                        {showErrorActivities && (
                          <div
                            style={{
                              display: 'flex',
                              padding: 20,
                              paddingTop: 0
                            }}
                          >
                            <span
                              style={{
                                color: Colors.red,
                                fintSize: 15,
                                fontWeight: 500
                              }}
                            >
                              La suma de los porcentajes debe ser 100.
                            </span>
                          </div>
                        )}

                </ColumnContainer>
              </Column>
            )}
            {/* TOPICS */}
            {!loading && (
              <Column>
                <ColumnContainer>
                  <TextTitle>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          alt='topics'
                          style={{ width: '15px', marginRight: 10 }}
                          src={noteIcon}
                        />

                        <span
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          marginRight: 10
                        }}
                      >
                        { language.localization['module-topics'] }
                      </span>
                      <Tooltip
                        title={ language.localization['module-topics-tooltip'] }
                        arrow
                        placement="right"
                      >
                        <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
                      </Tooltip>
                    </span>
                  </TextTitle>
                  <span className="objetivesDescription">
                  { language.localization['module-topics-description'] }
                  </span>

                </ColumnContainer>
                <>
                    <TopicsTable
                      setLanguage={setLanguage}
                      language={language}
                      course={course}
                      data={module}
                      refreshCallback={(mid) => {
                        refreshModule(mid)
                      }}
                    ></TopicsTable>
                  </>
              </Column>
            )}
              <ButtonFooter>
                <BackButton
                  onClick={() => {
                    navigate(`/course/${id}`)
                  }}
                >
                  <img
                    style={{ width: 10 }}
                    className="iconForm"
                    src={backColorIcon}
                    alt={ language.localization['back-button'] }
                  />
                  <span>{ language.localization['back-button'] }</span>
                </BackButton>
              </ButtonFooter>
            </FormContainer>
            </Body>
          )}

      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}

      {!loading && <FooterComponent language={language} />}
    </Container>
  )
}

ModuleScreen.propTypes = {
  toastProps: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointDelete: PropTypes.any,
  endpointExport: PropTypes.any,
  showToast: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any,
  user: PropTypes.any
}

export default connect(ModuleScreen)
