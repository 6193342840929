import { React, useState } from 'react'
import styled from 'styled-components'
import { Stack } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import trashIcon from '../assets/icons/trash.png'
import pencilIcon from '../assets/icons/pencil.png'
import { SmartTextarea } from './SmartTextarea'
import PropTypes from 'prop-types'

const TagObjetive = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* margin-top: 10px; */
  justify-content: space-between;
`

const Container = styled.div`
  display:flex;
  flex-direction: row;
  margin-top:10px;
`

const Order = styled.span`
  margin-top:5px;
`

const ObjetiveItem = ({ data, deleteItem, id, edit, allowEdit, allowDelete, showOrder }) => {
  const [value, setValue] = useState(data.title)

  return (
    <TagObjetive>
      <Stack direction="row" gap={1} justifyContent="start">
        {showOrder &&
          <Order>{data.orden}</Order>
        }
          <SmartTextarea
              saveOnBlur
              onSave={(e) => {
                data.title = value
                edit(data)
              }}
              onChange={(e) => { setValue(e.target.value) }}
              value={value}>
          </SmartTextarea>
          {/* {id + 1} - {data.title} */}
        <Container>
          {allowDelete &&

            <Tooltip title="Eliminar" arrow placement="top">
              <img
                style={{
                  width: '18px',
                  height: 20,
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: 'pointer'
                }}
                src={trashIcon}
                alt="Eliminar"
                onClick={() => {
                  deleteItem(data)
                }}
              />
            </Tooltip>
          }

          {allowEdit &&
            <Tooltip title="Editar módulo" arrow placement="top">
              <img
                onClick={() => {
                  edit(id)
                }}
                src={pencilIcon}
                style={{ height: 20, cursor: 'pointer' }}
                alt="Editar"
              />
            </Tooltip>
          }
        </Container>
      </Stack>
    </TagObjetive>
  )
}

ObjetiveItem.propTypes = {
  language: PropTypes.any,
  data: PropTypes.any,
  deleteItem: PropTypes.any,
  id: PropTypes.any,
  edit: PropTypes.any,
  allowEdit: PropTypes.any,
  allowDelete: PropTypes.any,
  showOrder: PropTypes.any
}

export default ObjetiveItem
