import styled from 'styled-components'
import { Colors } from '../../constants/Theme'

export const Container = styled.div`
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  position: relative;

  & .link-image {
    margin: 0 5px 0 5px;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
    font-weight: 400;
    padding: 20px 0;
    color: ${Colors.orange};
  }

  & .tooltip {
    width: 180px;
    background: rgb(252 183 158);
    position: absolute;
    padding: 20px;
    font-weight: bold;
    z-index: 2;
    text-align: center;
  }

  & .tooltip-arrow {
    width: 20px;
    height: 20px;
    background: rgb(252 183 158);
    position: absolute;
    transform: rotate(45deg);
    z-index: 1;
  }

  & .tooltip-casos,
  .tooltip-icon-aprendizaje-colaborativo,
  .tooltip-icon-aprendizaje-basado-proyectos,
  .tooltip-icon-aula-invertida,
  .tooltip-icon-gamificacion,
  .tooltip-aprendizaje-inmersivo,
  .tooltip-icon-aprendizaje-entre-pares {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  & .casos {
    box-shadow: none !important;
    position: absolute;
    right: 19%;
    top: -13%;
    width: 15%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;
    :hover + .tooltip-casos {
      transition-delay: 0.3s;
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-aprendizaje-colaborativo {
    box-shadow: none !important;
    position: absolute;
    left: 25%;
    top: -4%;
    width: 15%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;
    :hover + .tooltip-icon-aprendizaje-colaborativo {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-aprendizaje-basado-proyectos {
    position: absolute;
    box-shadow: none !important;
    left: -4%;
    top: 45%;
    width: 15%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;
    :hover + .tooltip-icon-aprendizaje-basado-proyectos {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-aula-invertida {
    box-shadow: none !important;
    position: absolute;
    bottom: -10%;
    width: 15%;
    left: 15%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;
    :hover + .tooltip-icon-aula-invertida {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-aprendizaje-entre-pares {
    box-shadow: none !important;
    position: absolute;
    left: 42%;
    top: 50%;
    width: 15%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;

    :hover + .tooltip-icon-aprendizaje-entre-pares {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }

    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-gamificacion {
    box-shadow: none !important;
    position: absolute;
    top: 29%;
    width: 15%;
    right: -5%;
    transition: width 0.3s;
    border-radius:100%;
    cursor: pointer;
    :hover + .tooltip-icon-gamificacion {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }

  & .icon-aprendizaje-inmersivo {
    box-shadow: none !important;
    position: absolute;
    right: 14%;
    bottom: 0%;
    width: 15%;
    cursor: pointer;
    transition: width 0.3s;
    border-radius:100%;
    :hover + .tooltip-aprendizaje-inmersivo {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    :hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      width: 16%;
    }
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3% 10% 0;
  background-color: ${Colors.white};
`

export const TextS = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const TextM = styled.span`
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin: 10px 0;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 700;
  padding: 20px 0;
  color: ${Colors.blueText};
  @media (max-width: 440px) {
    font-size: 50px;
    line-height: 1.2;
  }
  color: ${Colors.blueText};
`

export const TextL = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 60px;
  /* or 112% */

  letter-spacing: -0.25px;

  /* M3/sys/light/on-surface */

  color: #1c1b1f;
  @media (max-width: 440px) {
    font-size: 50px;
    line-height: 1.2;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5% 10%;
  background-color: ${Colors.white};
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const Step1 = styled.div`
  display: flex;
  width: 90%;
  background-color: ${Colors.white};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px 5%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 14px solid #f8c82d;
  box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
`

export const Step2 = styled.div`
  display: flex;
  width: 90%;
  background-color: ${Colors.white};
  flex-direction: column;
  text-align: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px 5%;
  margin: 0 2%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 14px solid #4dc6e6;
  box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  @media (max-width: 440px) {
    margin: 20px 0;
  }
`

export const Step3 = styled.div`
  display: flex;
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.white};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px 5%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 14px solid #ec484d;
  box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 12px 4px -7px rgba(0, 0, 0, 0.1);
`

export const StepTitle = styled.span`
  font-weight: 900;
  line-height: 20px;
  color: #5a4d4c;
  margin: 25px 0;
  font-size: 15px;
`

export const StepText = styled.span`
  color: #5a4d4c;
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const ButtonStart = styled.div`
  border: 2px solid #f55953;
  background: ${Colors.orange};
  color: #fff;
  border-radius: 9999px;
  padding: 15px;
  width: 150px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: #fff;
    border-color: ${Colors.orange};
    border-color: ${Colors.orange};
  }
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;

  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-20px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }
`

export const ImageSecondSection = styled.img`
  &.shadow{
    -webkit-box-shadow: -2px 5px 19px 6px #CFCFCF; 
    box-shadow: -2px 5px 19px 6px #CFCFCF;
  }
}
`
