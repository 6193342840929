import styled from 'styled-components'
import { Colors } from '../../constants/Theme'

export const RowContainer = styled.div`
  width:100%;
  padding:0;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  align-items: center;
  /* position: relative; */
  /* justify-content:flex-start; */
`

export const RowItem = styled.div`
  width:100%;
  /* padding:0; */
  /* background-color: ${Colors.orange}; */
  display: flex;
  flex-direction:row;
  /* flex:2; */
  align-items: center;
  margin:4px;
  /* position: relative; */
  /* flex-wrap:wrap; */
`

export const MediaImage = styled.img`
  display:flex;
  /* flex:1; */
  width:20px;
  height:20px;
`
