import styled from 'styled-components'
import { Colors } from '../../constants/Theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & .button-si:hover {
    background-color: ${Colors.orange} !important;
  }

  & .button-no:hover {
    background-color: ${Colors.orange} !important;
    color: ${Colors.white} !important;
  }

  & .button-si:hover {
    background-color: ${Colors.header} !important;
    color: ${Colors.white} !important;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 4px;
  margin-bottom: 100px;
  margin-top: 20px;
  @media (max-width: 440px) {
    width: 90%;
  }
`

export const TextTitle = styled.span`
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .iconForm {
    width: 18px;
    margin-right: 10px;
  }
  .titleForm {
    font-size: 20px;
    font-weight: 400;
  }
  .titleInput {
    width: 100%;
  }
  .objetivesSubTitle {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 500;
    color: #1c1b1f;
  }
  .objetivesDescription {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #49454f;
  }
  .objetivesEspSubTitle {
    font-size: 20px;
    font-weight: 500;
    color: #1c1b1f;
  }
`

export const ObjectivesConitaner = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 440px) {
  }
`

export const ContainerHibrid = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.white};
  border-radius: 5px;
  margin-bottom: 20px;
  height: 100%;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 41px;
  justify-content: space-between;
`
