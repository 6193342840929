import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Column, ColumnContainer, TextTitle } from '../../../screens/stepOne/style'

import folderIcon from '../../../assets/icons/drive_folder_upload.png'

import connect from '../connect'
// mport { useParams } from 'react-router-dom'
import { sendGAEvent } from '../../../utils/useGAPageView'
import Tag from '../../Tag'
import { URLS } from '../../../utils/urls'
import axios from 'axios'

const StrategySelector = ({ course, language, endpointCreate, endpointEdit, endpointGet, endpointDelete }) => {
  const [plainStrategies, setPlainStrategies] = useState([])
  const [strategies, setStrategies] = useState([])
  const [edittingCourse, setEdittingCourse] = useState(course)

  // const { id } = useParams()

  const onStrategyChanged = async (strategy) => {
    let toRemove = null
    course.strategies.forEach((s) => {
      if (s.id === strategy.id) {
        toRemove = strategy
      }
    })

    const c = course
    if (toRemove !== null) {
      const removed = course.strategies.filter((s) => {
        return s.id !== strategy.id
      })
      c.strategies = removed
    } else {
      c.strategies.push(strategy)
    }

    const action = toRemove !== null ? 'remove' : 'add'
    const result = await axios.put(`${URLS.base}cursos/${course.id}/strategies/${action}/${strategy.id}/`)

    sendGAEvent({
      category: 'edit',
      action: 'strategy',
      label: strategy.title
    })

    setEdittingCourse(result.data)
  }

  useEffect(() => {
    if (course) {
      setEdittingCourse(course)

      const fetchStrategies = async () => {
        const all = await endpointGet('strategies', 'strategies')
        setPlainStrategies(all.data)
      }

      fetchStrategies()
    }
  }, [course, endpointGet])

  useEffect(() => {
    const newStragtgyList = []
    plainStrategies.forEach((strategy) => {
      let used = false
      edittingCourse.strategies.forEach((courseStrategy) => {
        if (strategy.id === courseStrategy.id) {
          used = true
        }
      })
      newStragtgyList.push({
        strategy,
        used
      })
    })
    setStrategies(newStragtgyList)
  }, [edittingCourse, plainStrategies])

  return (
    <Column style={{ marginTop: 20 }}>
    <ColumnContainer>
      <TextTitle>
      <div className="row">
        <img alt={ language.localization['list-of-strategies'] }
        style={{ width: '25px', marginRight: 10 }}
        src={folderIcon}
        />
        <span
        className="titleForm"
        style={{ display: 'flex', justifyContent: 'center' }}
        >
        <span style={{ marginRight: 10 }}>
        { language.localization['list-of-strategies'] }
        </span>
        {/* <Tooltip
          title="Ten en cuenta que al momento de exportar el curso, se impactará en la plataforma según el orden que has designado."
          arrow
          placement="right"
        > */}
          {/* <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon> */}
        {/* </Tooltip> */}
        </span>
      </div>
      </TextTitle>
      <span className="objetivesDescription">
        { language.localization['list-of-strategies-description'] }
      </span>
      <div style={{ marginTop: 20, display: 'flex', cursor: 'pointer', flexWrap: 'wrap' }}>
      {strategies?.map((data, index) => {
        return (
          <div key={index} onClick={(e) => {
            e.stopPropagation()
            onStrategyChanged(data.strategy)
          }}>
            <Tag key={data.strategy.title} selected={data.used}
              text={language.localization[data.strategy.title]}

            />
          </div>
        )
      })}
      </div>
      {/* <p>not sure what to choose? <a href={`questionary/${id}`}>take our  survey</a></p>
      */}
    </ColumnContainer>
    </Column>

  )
}

StrategySelector.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointDelete: PropTypes.any
}

export default connect(StrategySelector)
