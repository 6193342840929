import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import connect from './connect'
import { RowContainer } from './style'

import TopicsTableItem from './TopicsTableItem'

const Container = styled.span`
  /* position: absolute; */
  /* bottom: 0; */
  /* background: #49454f; */
  width: 100%;
  /* height: 50px; */
  color: white;
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
`

const TopicsTable = ({ data, course, refreshCallback, language }) => {
  const [table, setTable] = useState([])
  // const [forceRefresh, setForceRefresh] = useState(0);

  // const _refreshCallback = (mid) => {
  //   refreshCallback(mid);
  //   setForceRefresh(Math.random());
  // }

  useEffect(() => {
    let currentTopic = null
    let currentActivity = null
    let currentResource = null
    // let t = table;
    // while(table.length > 0) table.pop();
    const t = []
    data.module_topics.forEach((topic, topicIndex) => {
      topic.module_topic_activities.forEach((activity) => {
        activity.module_topic_activity_resources.forEach((resource) => {
          t.push({
            topic: topic.id !== currentTopic ? topic.title : '',
            activity: activity.id !== currentActivity ? activity.title : '',
            resource: resource.id !== currentResource ? resource.title : '',
            topic_id: topic.id,
            topicIndex,
            activityId: activity.id,
            activity_scene: activity.scene,
            resource_id: resource.id,
            resource_type: resource.resource?.title,
            resource_resource_id: resource.resource?.id
          })
          currentTopic = topic.id
          currentResource = resource.id
          currentActivity = activity.id
        })
        if (activity.module_topic_activity_resources.length === 0) {
          t.push({
            topic: topic.id !== currentTopic ? topic.title : '',
            activity: activity.id !== currentActivity ? activity.title : '',
            resource: '',
            resource_id: -1,
            topic_id: topic.id,
            topicIndex,
            // topic_id: topic.id !== currentTopic ? topic.id : undefined,
            activityId: activity.id !== currentActivity ? activity.id : '',
            activity_scene: activity.scene,
            resource_type: ''
          })
          currentTopic = topic.id
        } else {
          // new resource
          t.push({
            topic: '',
            activity: '',
            resource: '',
            topic_id: topic.id,
            activityId: activity.id,
            topicIndex,
            resource_id: -1,
            resource_type: ''
          })
        }
      })
      if (topic.module_topic_activities.length === 0) {
        t.push({
          topic: topic.id !== currentTopic ? topic.title : '',
          activity: '',
          resource: '',
          topic_id: topic.id !== currentTopic ? topic.id : -1,
          topicIndex,
          activityId: -1,
          resource_type: ''
        })
      } else {
        // new activity
        t.push({
          topic: '',
          activity: '',
          resource: '',
          topic_id: topic.id,
          topicIndex,
          activityId: -1,
          resource_id: undefined,
          resource_type: ''
        })
      }
    })

    // new topic
    t.push({
      topic: '',
      activity: '',
      resource: '',
      topic_id: -1
      // activityId:-1,
      // resource_id:-1,
      // resource_type:''
    })

    setTable(arr => t)
  }, [data, refreshCallback, course])

  return (
    <Container>
      <div style={{ marginTop: 20, width: '100%' }}>
        {/* <Table aria-label="simple table"> */}
        {/* <HeaderContainer style={{marginTop:20}}>
          <HeaderItem style={{flex:1}}>
            { language.localization['topic'] }
          </HeaderItem>
          <HeaderItem style={{flex:1}}>
            { language.localization['tasks'] }
          </HeaderItem>
          <HeaderItem style={{flex:1}} sx={{display:{xs:'none', sm:'flex'}}}>
            { language.localization['resources'] }
          </HeaderItem>
        </HeaderContainer> */}

            { table.map((row, i) => (
              <RowContainer key={`row_${i}`}>
                <TopicsTableItem key={`topic_${i}`}
                                index={data.topicIndex}
                                course={course}
                                // style={{
                                //   flex:6,
                                // }}
                                moduleId={data.id}
                                text={row.topic}
                                id={row.topic_id}
                                refreshCallback={refreshCallback}
                                type='topic'
                                language={language}></TopicsTableItem>
                <TopicsTableItem key={`activity_${i}`}
                                course={course}
                                // style={{
                                //   flex:1,
                                // }}
                                activity_scene={row.activity_scene}
                                moduleId={data.id}
                                topicId={row.topic_id}
                                text={row.activity}
                                id={row.activityId}
                                refreshCallback={refreshCallback}
                                type='activity'
                                ></TopicsTableItem>
                <TopicsTableItem key={`resource_${i}`}
                                course={course}
                                // sx={{
                                //   display:{xs:'none'}
                                // }}
                                // style={{
                                //   flex:1,
                                // }}
                                moduleId={data.id}
                                activityId={row.activityId}
                                resource_resource_id={row.resource_resource_id}
                                text={row.resource}
                                id={row.resource_id}
                                refreshCallback={refreshCallback}
                                type='resource'
                                ></TopicsTableItem>
              </RowContainer>
            ))}
          {/* </RowContainer> */}
        {/* </Table> */}
      </div>
    </Container>
  )
}

TopicsTable.propTypes = {
  data: PropTypes.any,
  course: PropTypes.any,
  refreshCallback: PropTypes.any,
  language: PropTypes.any
}

export default connect(TopicsTable)
