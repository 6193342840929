export const setUserSessionStorage = ({
  accessToken,
  username,
  avatar,
  credits,
  costs
}) => {
  sessionStorage.setItem(
    'user',
    JSON.stringify({
      accessToken,
      username,
      avatar,
      loggedin: true,
      credits,
      costs
    })
  )

  // from now, axios will send the token on every request
  // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export const getUserSessionStorage = () => {
  const data = JSON.parse(sessionStorage.getItem('user'))
  return data
}

export const deleteUserSessionStorage = () => {
  sessionStorage.removeItem('user')
}

export const setUserLanguage = ({
  lang
}) => {
  sessionStorage.setItem(
    'lang',
    JSON.stringify({
      lang
    })
  )
}

export const getUserLanguage = () => {
  const d = sessionStorage.getItem('lang')
  if (d === null) return { lang: navigator && navigator.language && navigator.language.startsWith('en') ? 'en' : 'es' }
  const data = JSON.parse(sessionStorage.getItem('lang'))
  if (data === undefined) return { lang: navigator && navigator.language && navigator.language.startsWith('en') ? 'en' : 'es' }
  if (data.lang === undefined) data.lang = navigator && navigator.language && navigator.language.startsWith('en') ? 'en' : 'es'
  return data
}
