export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'

export const SIGN_IN_GOOGLE = 'SIGN_IN_GOOGLE'
export const SIGN_IN_GOOGLE_SUCCESS = 'SIGN_IN_GOOGLE_SUCCESS'
export const SIGN_IN_GOOGLE_ERROR = 'SIGN_IN_GOOGLE_ERROR'

export const SIGN_IN_FACEBOOK = 'SIGN_IN_FACEBOOK'
export const SIGN_IN_FACEBOOK_SUCCESS = 'SIGN_IN_FACEBOOK_SUCCESS'
export const SIGN_IN_FACEBOOK_ERROR = 'SIGN_IN_FACEBOOK_ERROR'

export const HIDE_TOAST = 'HIDE_TOAST'
export const SHOW_TOAST = 'SHOW_TOAST'

export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_ERROR = 'CREATE_COURSE_ERROR'

export const CREATE_GENERAL_OBJECTIVE = 'CREATE_GENERAL_OBJECTIVE'
export const CREATE_GENERAL_OBJECTIVE_SUCCESS = 'CREATE_GENERAL_OBJECTIVE_SUCCESS'
export const CREATE_GENERAL_OBJECTIVE_ERROR = 'CREATE_GENERAL_OBJECTIVE_ERROR'

export const CREATE_MODULE = 'CREATE_MODULE'
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_ERROR = 'CREATE_MODULE_ERROR'

export const GET_COURSE = 'GET_COURSE'
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS'
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR'

export const GET_FULL_USER_INFO = 'GET_FULL_USER_INFO'
export const GET_FULL_USER_INFO_SUCCESS = 'GET_FULL_USER_INFO_SUCCESS'
export const GET_FULL_USER_INFO_ERROR = 'GET_FULL_USER_INFO_ERROR'

export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR'

export const DELETE_MODULE = 'DELETE_MODULE'
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS'
export const DELETE_MODULE_ERROR = 'DELETE_MODULE_ERROR'

export const SET_LOCALIZATION = 'SET_LOCALIZATION'
export const SET_LANGUAGE = 'SET_LANGUAGE'
