import axios from 'axios'

import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const changePassword =
  (uid, token, newPassword1, newPassword2) =>
    async (dispatch) => {
      try {
        const params = {
          uid: uid,
          token: token,
          new_password1: newPassword1,
          new_password2: newPassword2
        }
        const response = await axios.post(`${URLS.base}rest-auth/password/reset/confirm/`, params)
        if (response) {
          dispatch(
            showToast({
              show: true,
              message: 'Tuc contraseña ha sido cambiada.',
              type: ERROR_TYPES.SUCCESS
            })
          )

          setTimeout(() => { window.location.href = '/#/login' }, 3000)

          return response
        } else {
          dispatch(
            showToast({
              show: true,
              message: 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
      }
    }

export default changePassword
