import React from 'react'
import PropTypes from 'prop-types'

import { TextTitle } from '../../../screens/stepOne/style'

import folderIcon from '../../../assets/icons/drive_folder_upload.png'
// import calendarIcon from '../../../assets/icons/calendar.png';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import connect from '../connect'
import { Colors } from '../../../constants/Theme'
import { useNavigate } from 'react-router-dom'
import { AcivityRow, BoxInfo, ColumnContainer, CompetenceDetailRow, CompetenceRow, ModuleBoxItem, ModuleInfo, ResourceBox } from './style'
import { HeaderContainer, HeaderItem, RowContainer } from '../style'

import EditIcon from '@mui/icons-material/Edit'

import trashIcon from '../../../assets/icons/trash.png'
import { Column, Description } from '../../../screens/course/style'
import LinkPreview from '../../LinkPreview'

const ModuleBox = ({ module, language, deleteModule, generating, order, duration }) => {
  const navigate = useNavigate()

  return (
    <Column generating={generating} style={{ width: '100%' }}>
    <ColumnContainer>
      <RowContainer>
        <TextTitle style={{ flex: 7 }}>
          <div className="row">
          <img alt={ module.title }
            style={{ width: '25px', marginRight: 10 }}
            src={folderIcon}
          />
          <span
            className="titleForm"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span style={{ marginRight: 10 }}>
            { order } { module.title }
            </span>
          </span>
          </div>
        </TextTitle>

        <div style={{ cursor: 'pointer', color: Colors.primaryColor, margin: 10, textAlign: 'right', display: 'block' }}
          onClick={() => {
            navigate(`module/${module.id}`)
          }}>
            <EditIcon />
        </div>

        {/* <AutoFixHighIcon style={{display:'flex', flex:1, marginTop:-26, marginLeft:5, cursor:'pointer', color:Colors.orange}}/> */}

      </RowContainer>

      <ModuleInfo>
        {duration > 0 &&
          <RowContainer style={{ color: Colors.header }}>
            <AccessTimeIcon style={{ paddingRight: 5, color: Colors.header, width: 35, height: 35 }}/>
            <p style={{ paddingTop: 2, margin: 0, fontSize: 15 }}> {duration} {language.localization.weeks}</p>
          </RowContainer>
        }
        {module.date && false &&
          <RowContainer style={{ color: Colors.header }}>
            <CalendarMonthIcon style={{ paddingRight: 5, color: Colors.header, width: 35, height: 35 }}/>
            <p style={{ paddingTop: 2, margin: 0, fontSize: 15 }}>{module.date}</p>
          </RowContainer>
        }
      </ModuleInfo>

      <Description
        className="objetivesDescription"
        style={{ marginTop: 16, display: 'flex' }}
        >
        { module?.description }
        </Description>
      {module.module_objectives.length > 0 &&
        <>
          <HeaderContainer style={{ marginTop: 20 }}>
              <HeaderItem style={{ flex: 1 }}>
                { language.localization['learning-objectives'] }
              </HeaderItem>
            </HeaderContainer>

          <BoxInfo style={{ width: '100%' }}>
            {module.module_objectives.map((activity) => (
              <AcivityRow key={activity.id}>
                <ModuleBoxItem>{ activity.title }</ModuleBoxItem>
              </AcivityRow>
            ))}
          </BoxInfo>
        </>
      }

  {module.module_competences.length > 0 &&
    <>
      <HeaderContainer style={{ marginTop: 20 }}>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization['module-competences']?.toUpperCase() }
          </HeaderItem>
          {/* <HeaderItem style={{ flex: 1 }}>
            { language.localization['competence-grade-1']?.toUpperCase() }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization['competence-grade-2']?.toUpperCase() }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization['competence-grade-3']?.toUpperCase() }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization['competence-grade-4']?.toUpperCase() }
          </HeaderItem> */}
        </HeaderContainer>

      <BoxInfo style={{ width: '100%' }}>
        {module.module_competences.map((competence) => (
          <CompetenceRow key={competence.id}>
            <ModuleBoxItem>
              { competence.title }
            </ModuleBoxItem>
            <ModuleBoxItem>
              <CompetenceDetailRow>
                <p>
                <HeaderItem style={{ flex: 1 }}>
                  { language.localization['competence-grade-1']?.toUpperCase() }
                </HeaderItem>
                  { competence.grade_1 }
                </p>
                <p>
                <HeaderItem style={{ flex: 1 }}>
                  { language.localization['competence-grade-2']?.toUpperCase() }
                </HeaderItem>
                  { competence.grade_2 }
                </p>
                <p>
                <HeaderItem style={{ flex: 1 }}>
                  { language.localization['competence-grade-3']?.toUpperCase() }
                </HeaderItem>
                  { competence.grade_3 }
                </p>
                <p>
                <HeaderItem style={{ flex: 1 }}>
                  { language.localization['competence-grade-4']?.toUpperCase() }
                </HeaderItem>
                  { competence.grade_4 }
                </p>
              </CompetenceDetailRow>
            </ModuleBoxItem>
          </CompetenceRow>

        ))}
      </BoxInfo>

    </>
  }

{module.module_activities.length > 0 &&
    <>
      <HeaderContainer style={{ marginTop: 20 }}>
          <HeaderItem style={{ flex: 10 }}>
            { language.localization['activity-task'] }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization.grade }
          </HeaderItem>
        </HeaderContainer>

      <BoxInfo style={{ width: '100%' }}>
        {module.module_activities.map((activity) => (
          <AcivityRow key={activity.id}>
            <ModuleBoxItem style={{ flex: 10 }}>
              { activity.title }
            </ModuleBoxItem>
            <ModuleBoxItem style={{ flex: 1 }}>
              { activity.percentage }%
            </ModuleBoxItem>
          </AcivityRow>

        ))}
      </BoxInfo>

    </>
  }

  {module.module_topics.length > 0 &&
    <>
      <HeaderContainer style={{ marginTop: 20 }}>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization.topic }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }}>
            { language.localization['activity-task'] }
          </HeaderItem>
          <HeaderItem style={{ flex: 1 }} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            { language.localization.resources }
          </HeaderItem>
        </HeaderContainer>

      <BoxInfo style={{ width: '100%' }}>
        {module.module_topics.map((topic) => {
          return <AcivityRow key={topic.id}>
            <ModuleBoxItem style={{ flex: 10 }}>
              { topic.title }
            </ModuleBoxItem>

            {
              topic.module_topic_activities.length > 0 &&
              <ModuleBoxItem style={{ flex: 10 }}>
                { topic.module_topic_activities[0].title }
              </ModuleBoxItem>
            }

            { topic.module_topic_activities.map(activity => {
              return <ResourceBox key={`${activity.id}_act`} style={{ flex: 10 }} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                { activity.module_topic_activity_resources.map(element => {
                  return <ResourceBox key={`${element.id}_res`} style={{ flex: 10 }} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                      <LinkPreview url={element.title}></LinkPreview>
                    </ResourceBox>
                }) }
              </ResourceBox>
            })
            }
          </AcivityRow>
        })}
      </BoxInfo>

    </>
  }

      <div style={{ cursor: 'pointer', color: Colors.primaryColor, margin: 10, textAlign: 'right', display: 'block' }}>
          <img
            style={{ width: 20, cursor: 'pointer' }}
            src={trashIcon}
            alt="cerrar"
            onClick={() => {
              deleteModule(module.id)
            }}
          />
        </div>

    </ColumnContainer>
    </Column>

  )
}
ModuleBox.propTypes = {
  module: PropTypes.any,
  language: PropTypes.any,
  order: PropTypes.any,
  duration: PropTypes.any,
  deleteModule: PropTypes.any,
  generating: PropTypes.any
}

export default connect(ModuleBox)
