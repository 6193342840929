import React, { useState } from 'react'
import { Colors } from '../../../constants/Theme'
import { useFocus } from '../../useFocus'
import { SmartTextfield } from '../../SmartTextfield'
import { AddRounded } from '@mui/icons-material'
import { RowContainer } from '../style'
import PropTypes from 'prop-types'

const CreateCompetenceItem = ({ save, setLanguage, language }) => {
  const [title, setTitle] = useState()
  const [grade1, setGrade1] = useState()
  const [grade2, setGrade2] = useState()
  const [grade3, setGrade3] = useState()
  const [grade4, setGrade4] = useState()
  const [grade5, setGrade5] = useState()
  const [ref, setFocus] = useFocus()

  const saveItem = async () => {
    if (title !== undefined) {
      const success = await save({ title, grade1, grade2, grade3, grade4, grade5 })
      if (success) {
        setTitle('')
        setGrade1('')
        setGrade2('')
        setGrade3('')
        setGrade4('')
        setGrade5('')
        setFocus()
      }
    }
  }

  return (
    <RowContainer style={{ padding: '0 10px' }}>
        <SmartTextfield
          inputRef={ref}
          onSave={saveItem}
          style={{
            textAlign: 'left',
            margin: 5,
            paddingTop: '25px'
          }}
          placeholder={ language.localization.name }
          value={title}
          type="text"
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />

        <AddRounded style={{
          width: 18,
          height: 18,
          marginDown: 10,
          marginRight: 10,
          marginLeft: 10,
          color: Colors.primaryColor,
          cursor: 'pointer'
        }}
          onClick={() => {
            saveItem()
          }}>
        </AddRounded>
      </RowContainer>
  )
}

CreateCompetenceItem.propTypes = {
  save: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default CreateCompetenceItem
