// import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import {
  SIGN_IN_FACEBOOK,
  SIGN_IN_FACEBOOK_ERROR,
  SIGN_IN_FACEBOOK_SUCCESS
} from '../types/actionTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const signInFacebook = () => async (dispatch) => {
  try {
    dispatch({ type: SIGN_IN_FACEBOOK })

    // const response = await axios.post(
    //   `${URLS.base}accounts/facebook/login/`
    // )

    window.open(`${URLS.base}accounts/facebook/login/`)
    const response = false

    if (response) {
      dispatch({
        type: SIGN_IN_FACEBOOK_SUCCESS
        // payload: {
        //   email,
        //   loggedin: true,
        // },
      })
      dispatch(
        showToast({
          show: true,
          message: 'Bienvenido',
          type: ERROR_TYPES.SUCCESS
        })
      )
      return true
    } else {
      dispatch(
        showToast({
          show: true,
          message: 'Ups! Ocurrió un error al intentar acceder con Facebook.',
          type: ERROR_TYPES.ERROR
        })
      )
      dispatch({
        type: SIGN_IN_FACEBOOK_ERROR
      })
      return false
    }
  } catch (error) {
    dispatch(
      showToast({
        show: true,
        message: getServerErrors(error),
        type: ERROR_TYPES.ERROR
      })
    )
    dispatch({
      type: SIGN_IN_FACEBOOK_ERROR
    })
  }
}

export default signInFacebook
