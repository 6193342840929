import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import connect from './connect'

import {
  Container,
  Body,
  FormContainer,
  Column,
  Row,
  ButtonFooter,
  BackButton,
  NextButton
} from './style.js'

import { Colors } from '../../constants/Theme'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FormHeader from '../../components/FormHeader'
import Tag from '../../components/Tag'

import schoolIcon from '../../assets/icons/school.png'

import nextIcon from '../../assets/icons/next.png'
import backColorIcon from '../../assets/icons/backColor.png'

import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import { useGAPageView } from '../../utils/useGAPageView'

const StepInformation = ({ endpointGet, setLanguage, language }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [strategies, setStrategies] = useState([])

  useEffect(() => {
    if (id) {
      const fetchCourse = async () => {
        const { data } = await endpointGet('Curso', 'cursos', id)
        if (data) {
          console.log('data0info: ', data)
          setDataEditing(data)
        }
      }
      fetchCourse()
    }
  }, [endpointGet, id])

  const setDataEditing = (response) => {
    setStrategies(response.strategies)
  }

  useGAPageView()

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language}/>
      <Body>
        <FormHeader
          title={ language.localization['learning-strategies-suggested'] }
          save={() => {}}
          backLink="/questionary"
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {strategies.length > 0 &&
            strategies.map((s, index) => <Tag key={index} text={language.localization[s.title]} />)}
        </div>
        <FormContainer>
          <Column style={{ marginTop: 20 }}>
            <div className="row" style={{ padding: 20, alignItems: 'center' }}>
              <img className="iconForm" src={schoolIcon} alt="" />
              <span
                style={{ color: Colors.blackText }}
                className="titleForm"
                // style={{ marginRight: 10 }}
              >{ language.localization['recommend-strategies'] }
              </span>
              <Tooltip
                title="Por defecto estas estrategias están activadas y en el próximo paso se cargará toda la configuración. Puedes optar por no utilizar alguna estrategia."
                arrow
                placement="right"
              >
                <InfoIcon sx={{ color: '#75cff7' }}></InfoIcon>
              </Tooltip>
            </div>
            <span
              style={{
                padding: '0 20px 20px 20px',
                color: Colors.blackText,
                fontWeight: 400,
                fontSize: 15,
                lineHeight: '22px'
              }}
            >{ language.localization['recommend-strategies-description'] }
            </span>
          </Column>

          {strategies.length > 0 &&
            strategies.map((item) => {
              return (
                <Row key={item.id}>
                  <img
                    style={{ width: 150, height: 150, margin: 20 }}
                    src={item.image}
                    alt=""
                  />
                  <Column>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          padding: 20,
                          display: 'flex',
                          alignItems: 'center',
                          paddingBottom: 0
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: Colors.orange,
                            fontWeight: 500
                          }}
                        >
                          {language.localization[item.title]}
                        </span>
                      </div>
                      <div
                        style={{
                          padding: 20,
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <span
                          style={{
                            color: Colors.orange,
                            paddingBottom: 20,
                            fontSize: 16
                          }}
                        >
                          { language.localization.definition }
                        </span>
                        <span
                          style={{
                            color: Colors.blackText,
                            paddingBottom: 20,
                            fontSize: 14,
                            lineHeight: '18px'
                          }}
                        >
                          { language.localization[item.definition] }
                        </span>
                        <span
                          style={{
                            color: Colors.orange,
                            paddingBottom: 10,
                            fontSize: 16
                          }}
                        >
                          { language.localization['suggestions-for-teachers'] }
                        </span>
                        <span
                          style={{
                            color: Colors.blackText,
                            paddingBottom: 20,
                            fontSize: 14
                          }}
                        >
                          { language.localization[item.recommendations] }
                        </span>
                        <ul>
                          {item?.bullets !== '' &&
                            item.bullets.split(',').map((text, index) => {
                              return (
                                <li key={index}
                                  style={{
                                    color: Colors.blackText,
                                    paddingBottom: 5,
                                    fontSize: 14
                                  }}
                                >
                                  {text}
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                  </Column>
                </Row>
              )
            })}

          <ButtonFooter>
            <BackButton
              onClick={() => {
                navigate('/questionary')
              }}
            >
              <img
                style={{ width: 10 }}
                className="iconForm"
                src={backColorIcon}
                alt={ language.localization['back-button'] }
              />
              <span>{ language.localization['back-button'] }</span>
            </BackButton>
            <NextButton
              onClick={() => {
                navigate(`/step-one/${id}`)
              }}
            >
              <span>{ language.localization['next-button'] }</span>
              <img
                style={{ width: 10 }}
                className="iconForm"
                src={nextIcon}
                alt={ language.localization['next-button'] }
              />
            </NextButton>
          </ButtonFooter>
        </FormContainer>
      </Body>
      <Footer language={language} />
    </Container>
  )
}

StepInformation.propTypes = {
  endpointGet: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default connect(StepInformation)
