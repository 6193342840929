import { connect } from 'react-redux'
import { compose } from 'redux'

import { getShowToast } from '../../selectors/selectors'
import showToast from '../../actions/Toast'

import resetPassword from '../../actions/ResetPassword'

const mapStateToProps = (state) => ({
  toastProps: getShowToast(state),
  language: state.language
})

const mapDispatchToProps = {
  showToast,
  resetPassword
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
