import React, { useState, useEffect } from 'react'
import connect from './connect'
import { useGAPageView } from '../../utils/useGAPageView'
import Header from '../../components/Header'
import setLanguage from '../../actions/SetLanguage'
import { Grid, Card, Box, Typography, Container } from '@mui/material'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Check from '../../assets/check.png'
import Coin from '../../assets/coin.png'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import {
  PayPalScriptProvider,
  PayPalButtons
} from '@paypal/react-paypal-js'
import { URLS } from '../../utils/urls'
import axios from 'axios'
import FooterComponent from '../../components/Footer'

export const pcentered = styled.p`
text-align: center!important;
`

export const gridcontainer = styled.div`
    padding: 0 60px;
    height: 100vh;
`
export const ContainerNew = styled.div`
min-height:10vh;
display: flex;
flex-direction: column;
position: relative;
background: white;
`
export const PageHeader = styled(Box)`
  background: #82b5ca;
  top:0;
  color:white;
  width:100%;
  text-align:center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: center;
  z-index:3;
`
const ContainerButton = styled(Container)`
  text-align:center;
  margin-top:3em;
  margin-bottom:2em;
`
const ContainerGratis = styled(Container)`
  padding:3em;
`
const Creditlogo = styled.img`
  height: 25px;
  vertical-align: middle;
  padding-right:10px;
  // margin-bottom: 20px;
  // @media (max-width: 440px) {
  //   margin-bottom: 20px;
  // }
  // @media (max-width: 320px) {
  //   margin-bottom: 20px;
  // }
`
const Checklogo = styled.img`
  height: 4em;
  vertical-align: middle;
`
export const CreditGrid = styled(Grid)`
margin-bottom:3em;
padding:1em;
background-color:#eefcff;
`

export const CreditContainerBox = styled(Box)`
margin-bottom:20px;
`
export const CreditAmount = styled(Typography)`
color:#559eb9;
font-weight:bold;
`
export const CreditName = styled(Typography)`
font-weight:bold;
`
export const CreditDesc = styled(Typography)`
font-size:1.3em;
`
export const Permanencia = styled(Box)`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`
export const Time = styled(Typography)`
color:#f57135;
`
export const Circle = styled(Box)`
background-color:white;
padding:1.3em;
border-radius:200em;
`
export const ImgButton = styled(Box)`
background-color:#4c97b4;
padding:.5em;
width:60%;
text-align:center;
border-radius:200em;
`
export const TextImgButton = styled(Typography)`
color:white;
padding:0px 20px;
`
export const ToggleButtonOpt = styled(ToggleButton)`
color:white;
margin:2em;
`

const BuyCreditsScreen = ({ language }) => {
  useGAPageView()

  const [filtroCompra, setfiltroCompra] = useState(true)
  const [filtroGratis, setfiltroGratis] = useState(false)
  const [selected, setSelected] = React.useState(true)
  const [selected2, setSelected2] = React.useState(false)

  const handleFiltroGratis = () => {
    // setfiltroCompra(true)
    // setfiltroGratis(false)
    // setSelected(!selected)
    // setSelected2(!selected2)
  }
  const handleFiltroCompra = () => {
    setfiltroCompra(false)
    setfiltroGratis(true)
    setSelected(!selected)
    setSelected2(!selected2)
  }

  const [offers, setOffers] = useState([])

  useEffect(() => {
    const url = `${URLS.base}offers/`
    const getOffers = async () => {
      const result = await axios.get(url)
      setOffers(result.data)
    }
    getOffers()
  }, [])

  const offersList = offers.map((item, i) =>
    <Grid item xs={12} md={4} sm={12} key={'offer' + i}>
        <Card sx={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5
        }}variant="outlined">
        <h3>{item.credits} credits for {item.usd} USD.</h3>
        <PayPalButtons style={{
          layout: 'vertical',
          color: 'blue',
          shape: 'pill',
          label: 'paypal'
        }}
          currency="USD" showSpinner={true}
          createOrder={
            async (data, actions) => {
              const url = `${URLS.base}payments/paypal/order/${item.id}/`
              return axios.post(url).then((response) => {
                // console.log(response);
                if (response.status === 200) {
                  return response.data.id
                }
                return false
              })
            }} onApprove={(data, actions) => {
              console.log(data.orderID)

              const url = `${URLS.base}payments/paypal/order/${data.orderID}/capture/`
              return axios.post(url).then((response) => {
                // console.log(response);
                if (response.status === 200) {
                  // refresh UI here
                  window.location.reload()
                }
              })
            }}></PayPalButtons>
        </Card>
    </Grid>

  )

  return (
      <ContainerNew>
          <Header setLanguage={setLanguage} language={language}/>
          <PageHeader sx={{
            fontSize: { xs: 30, md: 40 },
            paddingTop: { xs: 0, sm: 6 },
            paddingBottom: { xs: 0, sm: 6 }
          }}>{language.localization['credits-title']}</PageHeader>
          <Container maxWidth="md">
            <Typography
            sx={{
              color: '#83b8cb',
              textAlign: 'center',
              paddingTop: 6
            }}
            variant="h5"
            >
              {language.localization['credits-sub']}
            </Typography>
          </Container>
          <ContainerButton maxWidth="sm">
          <ToggleButtonGroup
          color="info"
          exclusive
          aria-label="Platform"
          >
            <ToggleButtonOpt
            selected={selected}
            onClick={(e) => {
              handleFiltroGratis()
            }}
            >{language.localization['free-button']}</ToggleButtonOpt>
            <ToggleButtonOpt
            selected={selected2}
            disabled= {true}
            onClick={(e) => {
              handleFiltroCompra()
            }}>{language.localization['buy-button']}</ToggleButtonOpt>
          </ToggleButtonGroup>
          </ContainerButton>
          { !filtroGratis &&
            <ContainerGratis maxWidth="md">
              <CreditGrid container spacing={2}>
                <Grid item xs={8}>
                <CreditContainerBox>
                  <CreditAmount
                    variant="h6"
                    ><Creditlogo src={Coin}/>
                    3 {language.localization['credits-text']}
                    </CreditAmount>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditName
                    variant="h5"
                    >
                    {language.localization['credit-register']}
                    </CreditName>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditDesc
                    variant="span1"
                    >
                    {language.localization['credit-register-desc']}
                    </CreditDesc>
                  </CreditContainerBox>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'center' }}>
                  <Permanencia>
                    <Checklogo src={Check}/>
                  </Permanencia>
                </Grid>
              </CreditGrid>
              <CreditGrid container spacing={2}>
                <Grid item xs={8}>
                <CreditContainerBox>
                  <CreditAmount
                    variant="h6"
                    ><Creditlogo src={Coin}/>
                    2 {language.localization['credits-text']}
                    </CreditAmount>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditName
                    variant="h5"
                    >
                    {language.localization['credit-permanence']}
                    </CreditName>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditDesc
                    variant="span1"
                    >
                    {language.localization['credit-permanence-desc']}
                    </CreditDesc>
                  </CreditContainerBox>
                </Grid>
                <Grid item xs={4}>
                  <Permanencia>
                    <ImgButton>
                      <TextImgButton sx={{ fontWeight: 'bold', lineHeight: 2 }}>{language.localization['credit-soon']}</TextImgButton>
                    </ImgButton>
                  </Permanencia>
                </Grid>
              </CreditGrid>
              <CreditGrid container spacing={2}>
                <Grid item xs={8}>
                <CreditContainerBox>
                  <CreditAmount
                    variant="h6"
                    ><Creditlogo src={Coin}/>
                    3 {language.localization['credits-text']}
                    </CreditAmount>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditName
                    variant="h5"
                    >
                    {language.localization['credit-share']}
                    </CreditName>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditDesc
                    variant="span1"
                    >
                    {language.localization['credit-share-desc']}
                    </CreditDesc>
                  </CreditContainerBox>
                </Grid>
                <Grid item xs={4}>
                  <Permanencia>
                    <ImgButton>
                      <TextImgButton sx={{ fontWeight: 'bold', lineHeight: 2 }}>{language.localization['credit-soon']}</TextImgButton>
                    </ImgButton>
                  </Permanencia>
                </Grid>
              </CreditGrid>
              <CreditGrid container spacing={2}>
                <Grid item xs={8}>
                <CreditContainerBox>
                  <CreditAmount
                    variant="h6"
                    ><Creditlogo src={Coin}/>
                    4 {language.localization['credits-text']}
                    </CreditAmount>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditName
                    variant="h5"
                    >
                    {language.localization['credit-invite']}
                    </CreditName>
                  </CreditContainerBox>
                  <CreditContainerBox>
                    <CreditDesc
                    variant="span1"
                    >
                    {language.localization['credit-invite-desc']}
                    </CreditDesc>
                  </CreditContainerBox>
                </Grid>
                <Grid item xs={4}>
                  <Permanencia>
                    <ImgButton>
                      <TextImgButton sx={{ fontWeight: 'bold', lineHeight: 2 }}>{language.localization['credit-soon']}</TextImgButton>
                    </ImgButton>
                  </Permanencia>
                </Grid>
              </CreditGrid>
          </ContainerGratis>
          }
          { !filtroCompra &&
          <Grid container spacing={3}
          sx={{
            // columns: { xs: 1, md: 3 },
            minHeight: '66.2vh',
            paddingTop: 6,
            paddingLeft: 5,
            paddingRight: 5
          }}>
          <PayPalScriptProvider
                  options={{
                    'client-id': 'AXbTaLpNWzmyr2Ojk9Z5L4MTFugsZ_TeNOzmAZcfBpDoctcvdzpa8K5a_lWHb1ejgwb6-H7NkvwOt8R4',
                    components: 'buttons',
                    currency: 'USD'
                  }}
                  >

                  { offersList }

              </PayPalScriptProvider>
          </Grid> }
          <FooterComponent />
      </ContainerNew>

  )
}

BuyCreditsScreen.propTypes = {
  language: PropTypes.any
}

export default connect(BuyCreditsScreen)
