import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LogoImage from '../assets/logo-app.png'
import Coin from '../assets/coin.png'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { Colors } from '../constants/Theme'
import {
  setUserLanguage
} from '../utils/userSessionStorage'
import { logout } from '../utils/backend'

import {
  IconFlagUS,
  IconFlagES
} from 'material-ui-flags'
import connect from './connect'
import { Button } from '@mui/material'
import { AlternateEmail, LiveHelp, TipsAndUpdates, MenuRounded } from '@mui/icons-material'

const Logo = styled.img`
  width: 80px;
  @media (max-width: 440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 320px) {
    margin-bottom: 20px;
  }
`
const Creditlogo = styled.img`
  height: 25px;
  vertical-align: middle;
  padding-right:10px;
  // margin-bottom: 20px;
  // @media (max-width: 440px) {
  //   margin-bottom: 20px;
  // }
  // @media (max-width: 320px) {
  //   margin-bottom: 20px;
  // }
`

const MenuLink = styled.a`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 28px;
text-align: left;
color: #f46524;
text-decoration: none;
position: relative;
display: flex;
margin-left: 10px;
margin-right: 10px;
`

const MobileMenuItem = styled(MenuItem)`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 28px;
color: red;
text-decoration: none;
position: relative;
margin-left: 10px;
margin-right: 10px;
display: flex;
`

const MobileMenuButton = styled(Button)`
  color: 'secondary';
`

const FlagIcon = styled.div`
display:block;
padding-top:3px;
padding-right:10px;
cursor:pointer
`

const Header = ({ user, setLanguage, language }) => {
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/')
    window.location.reload()
  }

  const [anchorElUser, setAnchorElUser] = useState(null)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position="static"
      sx={{
        background: { md: Colors.white, xs: Colors.white },
        boxShadow: 'none'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            style={{ alignItems: 'flex-end' }}
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              marginTop: { xs: 2, md: 0 },
              display: { xs: 'flex', md: 'flex' }
              // display: { xs: 'none', md: 'flex' } ,
            }}
          >
            <Logo onClick={goToHome} src={LogoImage} />
          </Typography>

          <Box style={{ position: 'absolute', right: 0 }} sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            <Box
              sx={{
                flexGrow: 1,
                display: { md: 'flex' }
                // justifyContent: { md: 'end' },
              }}
            >

              <Box
                sx={{
                  display: { xs: 'none', md: 'none' }
                }}
              >
                { user?.loggedin &&
                  <MenuLink href="/#/">{language.localization['menu-inicio']}</MenuLink>
                }
                {
                  <MenuLink href="/#/como-funciona">{language.localization['menu-como-funciona']}</MenuLink>
                }
                <MenuLink href="/#/faq">{language.localization['menu-faq']}</MenuLink>
                <MenuLink href="mailto:support@digitalexs.com">{language.localization['menu-contacto']}</MenuLink>
                {user?.credits !== undefined &&
                  <MenuLink href="/#/buy-credits"><Creditlogo src={Coin}/>{user?.credits}</MenuLink>
                }
                {!user?.credits &&
                  <MenuLink>{user?.username}</MenuLink>
                }
                {!user?.loggedin &&
                  <MenuLink href='/#/login'>{language.localization['menu-ingresar']}</MenuLink>
                }
                  <MenuLink>
                  { language.localization._language === 'en' &&
                    <MenuLink onClick={async () => {
                      setUserLanguage({ lang: 'es' })
                      setLanguage('es')
                    }}>
                      <FlagIcon>
                      <IconFlagES />
                      </FlagIcon>
                    </MenuLink>
                  }
                  { language.localization._language === 'es' &&
                    <MenuLink onClick={async () => {
                      setUserLanguage({ lang: 'en' })
                      setLanguage('en')
                    }}>
                      <FlagIcon>
                      <IconFlagUS />
                      </FlagIcon>
                    </MenuLink>
                  }
                  </MenuLink>
              </Box>
              <Box style={{ marginRight: 20 }}></Box>

                <IconButton
                  sx={{
                    p: 0,
                    display: { xs: 'flex', md: 'flex' }
                  }}
                  onClick={(e) => {
                    handleOpenUserMenu(e)
                  }}
                >
                  <FlagIcon>
                    <MenuRounded></MenuRounded>
                  </FlagIcon>
                </IconButton>
            </Box>

            <Menu
              // sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              {/* MOBILE MENU */}
              {user?.username &&
                <MobileMenuItem>
                  <FlagIcon>
                  <Avatar
                    sx={{ width: 45, height: 45 }}
                    alt="User Avatar"
                    src={user?.avatar}
                  />
                  </FlagIcon>
                  <MobileMenuButton>{user?.username}</MobileMenuButton>
                </MobileMenuItem>
              }
              {!user?.credits &&
                <MobileMenuButton sx={{
                  display: { xs: 'flex', md: 'none' }
                }}>{user?.username}</MobileMenuButton>
              }
                {user?.credits !== undefined &&
                <MobileMenuItem>
                  <MobileMenuButton href="/#/">{language.localization['menu-inicio']}</MobileMenuButton>
                </MobileMenuItem>
                }
                {user?.credits !== undefined &&
                <MobileMenuItem>
                  <MobileMenuButton href="/#/buy-credits"><Creditlogo src={Coin}/>{user?.credits}</MobileMenuButton>
                </MobileMenuItem>
                }
                <MobileMenuItem>
                  <MobileMenuButton href="/#/como-funciona"><FlagIcon><TipsAndUpdates color="#ff0000"></TipsAndUpdates></FlagIcon>{language.localization['menu-como-funciona']}</MobileMenuButton>
                </MobileMenuItem>
              <MobileMenuItem>
                <MobileMenuButton href="/#/faq"><FlagIcon><LiveHelp></LiveHelp></FlagIcon>{language.localization['menu-faq']}</MobileMenuButton>
              </MobileMenuItem>

              <MobileMenuItem>
                <MobileMenuButton href="/#/contact">
                  <FlagIcon><AlternateEmail></AlternateEmail></FlagIcon>
                  {language.localization['menu-contacto']}
                </MobileMenuButton>
              </MobileMenuItem>

              {!user?.loggedin &&
                <MobileMenuItem><MobileMenuButton href='/#/login'>{language.localization['menu-ingresar']}</MobileMenuButton></MobileMenuItem>
              }
              { language.localization._language === 'en' &&
              <MobileMenuItem onClick={async () => {
                setUserLanguage({ lang: 'es' })
                setLanguage('es')
              }}>
                <MobileMenuButton>
                  <FlagIcon>
                    <IconFlagES />
                  </FlagIcon>
                  Español
                </MobileMenuButton>
              </MobileMenuItem>
              }
              { language.localization._language === 'es' &&
              <MobileMenuItem onClick={async () => {
                setUserLanguage({ lang: 'en' })
                setLanguage('en')
              }}>
                <MobileMenuButton>
                  <FlagIcon>
                    <IconFlagUS />
                  </FlagIcon>
                  English
                </MobileMenuButton>
              </MobileMenuItem>
              }
              {user?.loggedin &&
              <MobileMenuItem onClick={logout}>
                <MobileMenuButton><FlagIcon><ExitToAppIcon></ExitToAppIcon></FlagIcon>{language.localization['menu-salir']}</MobileMenuButton>
              </MobileMenuItem>
              }
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

Header.propTypes = {
  user: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default connect(Header)
