import { ERROR_TYPES } from '../constants/ErrorTypes'
import { SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../types/actionTypes'
import { signIn } from '../utils/backend'
import { getServerErrors } from '../utils/serverErrors'

import showToast from './Toast'

const signInEmail =
  ({ userName, password }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SIGN_IN,
          payload: {
            loading: true
          }
        })

        const response = await signIn(userName, password)

        if (response && !response.error) {
        // window.location.href = '';
          dispatch({
            type: SIGN_IN_SUCCESS,
            payload: {
              userName,
              loggedin: true,
              loading: false
            }
          })
          dispatch(
            showToast({
              show: true,
              message: `Bienvenido ${userName}`,
              type: ERROR_TYPES.SUCCESS
            })
          )

          setTimeout(() => {
            window.location.href = ''
          }, 1000)
          return true
        } else {
          dispatch(
            showToast({
              show: true,
              message: response && response.error ? response.response : 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          dispatch({
            type: SIGN_IN_ERROR,
            payload: {
              loading: false
            }
          })
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
        dispatch({
          type: SIGN_IN_ERROR,
          payload: {
            loading: false
          }
        })
      }
    }

export default signInEmail
