import { Input, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import connect from './connect'
import { sendGAEvent } from '../../utils/useGAPageView'
import { MediaRow, MediaTitle } from '../boxes/CommunicationStrategy/style'
import { MediaImage, RowItem } from './style'
import { Container } from '@mui/system'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Colors } from '../../constants/Theme'
import PropTypes from 'prop-types'

const TopicsTableItem = ({
  moduleId,
  topicId,
  activityId,
  activityScene,
  resourceId,
  id,
  type,
  text,
  endpointCreate,
  endpointDelete,
  endpointEdit,
  refreshCallback,
  course,
  language
}) => {
  let newText
  switch (type) {
    case 'topic':
      newText = language.localization['new-subject']
      break
    case 'activity':
      newText = language.localization['new-task']
      break
    case 'resource':
      newText = language.localization['new-resource']
      break
    default:
      break
  }

  const [value, setValue] = useState(id === -1 ? '' : text)
  const [typeText] = useState(newText)
  const [, setActivityScene] = useState(activityScene || -1)

  useEffect(() => {
    setValue(id === -1 ? '' : text)
    setActivityScene(activityScene || -1)
  }, [text, activityScene, id, resourceId])

  const onDelete = async () => {
    console.log(`deleting ${type} with id ${id}`)
    switch (type) {
      case 'topic':
        // delete
        await endpointDelete('Tema', 'module_topics', id)
        refreshCallback(moduleId)
        break
      case 'activity':
        // delete
        await endpointDelete('Actividad', 'module_topic_activities', id)
        refreshCallback(moduleId)
        break
      case 'resource':
        // delete
        await endpointDelete('Recurso', 'module_topic_activity_resources', id)
        refreshCallback(moduleId)
        break
      default:
        break
    }
    // setValue('');
  }

  const onEdit = async (val, valActivityScene) => {
    // resourceIde
    console.log(`saving ${type} ${val} with id ${id} on module ${moduleId}`)
    switch (type) {
      case 'topic':
        if (id === -1) {
          // create
          const params = { module: moduleId, title: val }
          const { success } = await endpointCreate('Tema', 'module_topics', { params })
          if (success) {
            sendGAEvent({
              category: 'edit',
              action: 'topic'
            })
          }
          // setValue('');
        } else {
          // edit
          const params = { id, module: moduleId, title: val }
          await endpointEdit('Tema', 'module_topics', id, { params })
        }
        refreshCallback(moduleId)
        break
      case 'activity':
        if (id === -1) {
          // create
          const params = valActivityScene === -1
            ? { topic: topicId, title: resourceId }
            : { topic: topicId, title: val, scene: valActivityScene, resourceId }

          const { success } = await endpointCreate('Actividad', 'module_topic_activities', {
            params
          })
          if (success) {
            sendGAEvent({
              category: 'edit',
              action: 'topic-activity'
            })
          }
          // setValue('');
        } else {
          // edit
          const params = valActivityScene === -1
            ? { topic: topicId, title: resourceId }
            : { topic: topicId, title: val, scene: valActivityScene, resourceId }

          await endpointEdit('Actividad', 'module_topic_activities', id, {
            params
          })
        }
        refreshCallback(moduleId)
        break
      case 'resource':
        if (id === -1) {
          // create
          const params = {
            activity: activityId,
            resource: resourceId,
            title: val
          }
          const { success } = await endpointCreate('Recurso', 'module_topic_activity_resources', {
            params
          })

          if (success) {
            sendGAEvent({
              category: 'edit',
              action: 'topic-resource'
            })
          }
          // setValue('');
        } else {
          // edit
          const params = {
            id,
            activity: activityId,
            resource: resourceId,
            title: val
          }
          await endpointEdit('Recurso', 'module_topic_activity_resources', id, {
            params
          })
        }
        refreshCallback(moduleId)
        break
      default:
        break
    }
  }

  const onInput = (e) => {
    setValue(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (e.target.value !== '') {
        onEdit(e.target.value, activityScene)
      }
    }
  }

  const onBlur = (e) => {
    if (e.target.value !== '') {
      onEdit(e.target.value, activityScene)
    }
  }

  const getScene = (scenes, id) => {
    let selected
    scenes.forEach((s) => {
      // console.log(s.id, id)
      if (s.id === id) {
        selected = s
      }
    })
    return selected
  }

  // const getFlex = () => {
  //   return type==='resource' ? 2 :
  //           type==='activity' ? 3 :
  //                               3;
  // }

  const getIdentation = () => {
    // return 0;
    return type === 'resource'
      ? 30
      : type === 'activity'
        ? 0
        : 0
  }

  return (

    <Container style={{ marginLeft: getIdentation() }}>
      {id === -1 && (
        <RowItem style={{
          borderBottom: 'none'
        }}>

        { type === 'activity' && false &&
             <Select
              style={{
                flex: 1,
                height: '32px',
                margin: 10,
                backgroundColor: activityScene === -1 ? '#eaeaea' : 'white'
                // color: activityScene === -1 ? 'rgba(0, 0, 0, 0.4)' : 'black',
              }}
              value={activityScene}
              label="Escenario"
              displayEmpty
              onChange={(e) => {
                setActivityScene(e.target.value)
              }}
              renderValue={() => {
                const scene = getScene(course.scenes, activityScene)
                return (
                 <MediaImage src={scene?.image}/>
                )
              }}
            >

            {/* <MenuItem key={`empty_${activityId}`} value={-1}>{ language.localization['select-the-environment'] }</MenuItem>   */}
            {course.scenes.map((scene, j) =>
              <MediaRow style={{ width: 280 }} key={`media_${j}`} name={scene?.title} value={scene?.id}>
                <MediaImage src={scene?.image}/>
                <MediaTitle>
                  {language.localization[scene?.title]}
                </MediaTitle>
            </MediaRow>
              // <MenuItem value={scene.id}>{scene.title}</MenuItem>
            )}
           </Select>
          }

          <Input
            type="text"
            onChange={onInput}
            onKeyDown={onKeyDown}
            // onBlur={onBlur}
            value={value}
            placeholder={typeText}
            style={{
              flex: 19,
              width: '100%',
              fontSize: type === 'topic' ? 17 : 14,
              // flex: type === 'activity' ? 4 : 8,
              // width: type === 'activity' ? '50%' : '100%',
              // marginRight: type === 'activity' ? '5%' : '0',
              backgroundColor: '#eaeaea'
            }}
          />

          <AddCircleOutlineIcon
            onClick={() => onEdit(value, activityScene)}
            style={{
              width: 20,
              padding: 10,
              color: Colors.primaryColor,
              cursor: 'pointer'
            }}/>

        </RowItem>
      )}

      {id !== -1 && (
        <>
          {text === '' && (
            <RowItem style={{
              borderBottom: 'none'
            }}></RowItem>
          )}
          {text !== '' && (
            <RowItem style={{ borderBottom: 'none' }}>

    { type === 'activity' && false &&
                <Select
                  style={{
                    // width:'100%',
                    height: '32px',
                    margin: 10,
                    // marginRight: '5%',
                    flex: 1,
                    backgroundColor: activityScene === -1 ? '#eaeaea' : 'white',
                    color: activityScene === -1 ? 'rgba(0, 0, 0, 0.4)' : 'black'
                  }}
                  value={activityScene}
                  label="Escenario"
                  displayEmpty
                  onChange={(e) => {
                    setActivityScene(e.target.value)
                    onEdit(value, e.target.value)
                  }}
                  renderValue={() => {
                    const scene = getScene(course.scenes, activityScene)
                    return (
                    <MediaImage src={scene?.image}/>
                    )
                  }}
                >
                {/* <MenuItem key={`empty_${activityId}`} value={-1}>{ language.localization['select-the-environment'] }</MenuItem>   */}
                {course.scenes.map((scene, j) =>
                  <MediaRow style={{ width: 280 }} key={`media_${j}`} name={scene?.title} value={scene?.id}>
                    <MediaImage src={scene?.image}/>
                    <MediaTitle>
                      {language.localization[scene?.title]}
                    </MediaTitle>
              </MediaRow>
                  // <MenuItem value={scene.id}>{scene.title}</MenuItem>
                )}
                </Select>
              }
              <Input
                type="text"
                onChange={onInput}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                value={value}
                style={{
                  width: '100%',
                  flex: 19,
                  fontSize: type === 'topic' ? 17 : 14
                  // flex:1,
                  // display:'flex',
                  // width: type === 'activity' ? '50%' : '100%',
                  // marginRight: type === 'activity' ? '5%' : '0'
                }}
              />

              <RemoveCircleOutlineIcon
                onClick={onDelete}
                style={{
                  width: 15,
                  padding: 10,
                  color: Colors.red,
                  cursor: 'pointer'
                }}/>

            </RowItem>
          )}
        </>
      )}
    </Container>
  )
}

TopicsTableItem.propTypes = {
  moduleId: PropTypes.any,
  topicId: PropTypes.any,
  activityId: PropTypes.any,
  activityScene: PropTypes.any,
  resourceId: PropTypes.any,
  id: PropTypes.any,
  type: PropTypes.any,
  text: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointDelete: PropTypes.any,
  endpointEdit: PropTypes.any,
  refreshCallback: PropTypes.any,
  course: PropTypes.any,
  language: PropTypes.any
}

export default connect(TopicsTableItem)
