import * as actions from '../types/actionTypes'

const initialState = {
  userdata: {}
}

export default function UserData (state = initialState, action) {
  switch (action.type) {
    case actions.GET_FULL_USER_INFO_SUCCESS:
      return {
        userdata: action.payload
      }

    default:
      return state
  }
}
