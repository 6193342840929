import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ColumnContainer, PickerContainer, TextTitle } from '../../../screens/stepOne/style'
import { FormControl, Tooltip } from '@mui/material'

import emailIcon from '../../../assets/icons/email.png'

import connect from '../connect'
import { SmartTextfield } from '../../SmartTextfield'
import { formatDate } from '../../../utils/dates'
import { useParams } from 'react-router-dom'
import { sendGAEvent } from '../../../utils/useGAPageView'
import { Colors } from '../../../constants/Theme'
import CommunicationItem from './CommunicationItem'
import { AddRounded } from '@mui/icons-material'

import { es, enCA } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import InfoIcon from '@mui/icons-material/Info'
import { HeaderContainer, HeaderItem } from '../style'
import CommunicationSelect from './CommunicationSelect'
import { Column } from '../../../screens/course/style'

const CommunicationStrategy = ({ data, course, language, endpointCreate, endpointDelete, endpointGet, endpointEdit, generating }) => {
  const [courseCommunication, setCourseCommunication] = useState([])
  const [communicationMedias, setCommunicationMedias] = useState([])
  // const [newCommunicationTitle, setNewCommunicationTitle] = useState('');
  // const [newCommunicationDate, setNewCommunicationDate] = useState(dayjs());
  // const [newCommunicationMedia, setNewCommunicationMedia] = useState({});
  const [newCommunication, setNewCommunication] = useState({})

  const { id } = useParams()

  useEffect(() => {
    const fetchCommunication = async () => {
      // get available communication medias
      const commMedias = await endpointGet(
        'Medio de comunicacion',
        'course_communication_medias'
      )
      if (commMedias.data) {
        setCommunicationMedias(commMedias.data)
        // console.log(commMedias.data[0])
        setNewCommunication({
          title: '',
          // eslint-disable-next-line new-cap
          date: new dayjs(),
          media: commMedias.data[0]
        })
        // console.log(commMedias.data)
        // setNewCommunicationMedia({
        // title: newCommunicationTitle,
        // date: newCommunicationDate,
        // media:commMedias.data[0]
        // })
      }
    }

    fetchCommunication()

    if (course) {
      setCourseCommunication(course.course_communication)
    }
  }, [course, endpointGet])

  const updateCommunication = async (item) => {
    const params = { ...item }
    params.date = formatDate(item.date)
    const { success } = await endpointEdit(
      'Comunicacion',
      'course_communications',
      item.id,
      { params }
    )
    if (success) {
      // refresh
      refreshCommunication()
    }
  }

  const refreshCommunication = async () => {
    const { data } = await endpointGet('Curso', 'cursos', id)
    setCourseCommunication(data.course_communication)
  }

  const createCommunication = async (item) => {
    const params = { ...item }
    const result = await endpointCreate(
      'Comunicacion',
      'course_communications',
      { params }
    )

    if (result.success) {
      // refresh
      setNewCommunication({
        title: '',
        media: newCommunication.media,
        // eslint-disable-next-line new-cap
        date: new dayjs()
      })
      refreshCommunication()

      sendGAEvent({
        category: 'edit',
        action: 'communication-media'
      })
    }
  }

  const deleteCommunication = async (item) => {
    const { success } = await endpointDelete(
      'Comunicacion',
      'course_communications',
      item.id
    )
    if (success) {
      // refresh
      refreshCommunication()
    }
  }

  return (
    <Column generating={generating}>
      <ColumnContainer>
        <TextTitle>
          <div className="row">
          <img
            style={{ width: '25px', marginRight: 10 }}
            src={emailIcon}
            alt="Título del curso"
          />
          <span
            className="titleForm"
            style={{ alignItems: 'center' }}
          >
            <span style={{ marginRight: 10 }}>
            { language.localization['communication-strategy'] }
            </span>
            <Tooltip
              title={ language.localization['communication-strategy-tooltip'] }
              arrow
              placement="right"
            >
            <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
            </Tooltip>
            {/* <AutoFixHighIcon style={{display:'block', margin:10, cursor:'pointer', color:Colors.orange}}/> */}
          </span>
          </div>
          <span
          className="objetivesDescription"
          style={{ marginTop: 16 }}
          >{ language.localization['communication-strategy-description'] }
          </span>
        </TextTitle>

          <HeaderContainer>
            <HeaderItem
              sx={{
                width: { xs: '40%', md: '60%' }
              }}>
              { language.localization.event }
            </HeaderItem>
            <HeaderItem sx={{
              width: { xs: '20%', md: '11%' }
            }}>
              { language.localization.means }
            </HeaderItem>
            <HeaderItem sx={{
              width: { xs: '40%', md: '29%' }
            }}>
              { language.localization.date }
            </HeaderItem>
          </HeaderContainer>

          <div>
            {courseCommunication.map((item, i) => (
            <CommunicationItem item={item}
              language={language}
              communicationMedias={communicationMedias}
              updateCommunication={updateCommunication}
              deleteCommunication={deleteCommunication}
              key={`comm_${i}`}>
            </CommunicationItem>
            ))}
          </div>
          {/* add new communication */}
          <div style={{ }}>
            <SmartTextfield
              sx={{
                width: { xs: '40%', md: '60%' },
                padding: '16.5px 14px',
                margin: 0
              }}
              style={{
                textAlign: 'left'
              }}
              value={newCommunication.title}
              // className="titleInput"
              placeholder={ language.localization['event-name'] }
              onChange={(e) => {
                setNewCommunication({ ...newCommunication, title: e.target.value })
              }}
              onSave={(value) => {
                createCommunication({
                  title: value,
                  date: formatDate(newCommunication.data),
                  media: newCommunication.media,
                  course: parseInt(id)
                })
              }}
              />

            <CommunicationSelect
              item={newCommunication}
              medias={communicationMedias}
              updateCommunication={(event) => {
                communicationMedias.forEach((m) => {
                  if (m.id === event.media) {
                    const newItem = {
                      ...newCommunication,
                      media: m
                    }
                    // console.log(newItem)
                    setNewCommunication(newItem)
                  }
                })
              }}/>

              <FormControl
                sx={{
                  width: { xs: '20%', md: '20%' }
                }}
                style={{
                  // flex: 2,
                  margin: 0
                }}>
              <PickerContainer
              style={{
                textAlign: 'right',
                // flex:2,
                margin: 4
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale={language.lang === 'es' ? es : enCA}>
                <DatePicker
                  // label={ language.localization['from'] }
                  value={newCommunication.date}
                  onChange={(newValue) => {
                    setNewCommunication({
                      ...newCommunication,
                      date: newValue
                    })
                  }}
                />
                <AddRounded style={{
                  width: 20,
                  height: 20,
                  // margin:5,
                  paddingTop: 20,
                  color: Colors.primaryColor,
                  cursor: 'pointer',
                  position: 'absolute'
                }}
                onClick={() => {
                  const newData = {
                    course: parseInt(id),
                    title: newCommunication.title,
                    media: newCommunication.media,
                    date: formatDate(newCommunication.date)
                  }
                  createCommunication(newData)
                }}></AddRounded>
                </LocalizationProvider>
              </PickerContainer>
              </FormControl>
              <div
                style={{
                  // display: 'flex',
                  // flex:3,
                  // padding: 20,
                  width: 28,
                  height: 28
                }}
                >
              </div>
            </div>

        </ColumnContainer>
      </Column>

  )
}

CommunicationStrategy.propTypes = {
  data: PropTypes.any,
  course: PropTypes.any,
  language: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointDelete: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointEdit: PropTypes.any,
  generating: PropTypes.any
}

export default connect(CommunicationStrategy)
