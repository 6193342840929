import * as actions from '../types/actionTypes'

const initialState = {
  localization: {},
  lang: ''
}

export default function LanguageData (state = initialState, action) {
  switch (action.type) {
    case actions.SET_LOCALIZATION:
      return {
        ...state,
        localization: action.payload
      }
    case actions.SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload
      }

    default:
      return state
  }
}
