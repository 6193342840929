import { connect } from 'react-redux'
import { compose } from 'redux'

import endpointDelete from '../../actions/EndpointDelete'
import endpointGet from '../../actions/EndpointGet'
import endpointCreate from '../../actions/EndpointCreate'
import showToast from '../../actions/Toast'
import { getShowToast } from '../../selectors/selectors'
import setLanguage from '../../actions/SetLanguage'

const mapStateToProps = (state) => ({
  toastProps: getShowToast(state),
  language: state.language
})

const mapDispatchToProps = {
  endpointDelete,
  endpointCreate,
  endpointGet,
  showToast,
  setLanguage
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
