import React, { useState } from 'react'
import connect from './connect'
import PropTypes from 'prop-types'

import LogoImage from '../../assets/logo-app.png'
import ToastMessage from '../../components/ToastMessage'
// import background from '../../assets/icons/background.png'

import { useNavigate } from 'react-router-dom'
import { ERROR_TYPES } from '../../constants/ErrorTypes'
import { validateEmail } from '../../utils/validateEmail'

import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CircularProgress from '@mui/material/CircularProgress'

import { Colors } from '../../constants/Theme'

import {
  Logo,
  Page,
  ButtonLogin,
  InputText,
  Container,
  NewAccount
  // Background
} from '../signIn/style'

import sendOnEnter from '../../components/sendOnEnter'
import { useGAPageView } from '../../utils/useGAPageView'
import FooterComponent from '../../components/Footer'
import Header from '../../components/Header'

const SignUpScreen = ({ setLanguage, signUpEmail, showToast, toastProps, loading, language }) => {
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepat] = useState(false)

  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(false)
  const [userNameError, setUserNameError] = useState(false)
  const [repeatPasswordError, setRepeatPasswordError] = useState(false)

  const navigate = useNavigate()
  useGAPageView()

  const signUp = async () => {
    if (!userName) {
      setUserNameError(true)
    }

    if (!email) {
      setEmailError(true)
    }

    if (email) {
      if (!validateEmail(email)) {
        setEmailError(true)
        showToast({
          show: true,
          message: 'El correo es invalido.',
          type: ERROR_TYPES.ERROR
        })
      }
    }

    if (!password) {
      setPasswordError(true)
    }
    if (!repeatPassword) {
      setRepeatPasswordError(true)
    }

    if (password !== repeatPassword) {
      setPasswordError(true)
      setRepeatPasswordError(true)
      showToast({
        show: true,
        message: 'Las contraseñas no coinciden.',
        type: ERROR_TYPES.ERROR
      })
    }

    if (
      email &&
      password &&
      repeatPassword &&
      userName &&
      validateEmail(email)
    ) {
      const success = await signUpEmail({ userName, email, password })
      if (success) {
        setTimeout(() => {
          navigate('/')
        }, 5000)
      }
    }
  }

  return (
    <div style={{ backgroundColor: '#D1DBDE', minHeight: '100vh' }}>
      <Header setLanguage={setLanguage} language={language} />
      <Page>
        {/* <Background src={background} /> */}
        <Container style={{ zIndex: 2 }}>
          <Logo src={LogoImage} />
          <InputText
            id="username-signup"
            label={language.localization.username }
            variant="outlined"
            type="text"
            value={userName}
            error={userNameError}
            onChange={(e) => setUserName(e.target.value)}
            onFocus={() => setUserNameError(false)}
            onKeyPress={(e) => sendOnEnter(e, signUp)}
          />
          <InputText
            id="email-signup"
            label={language.localization.email }
            variant="outlined"
            type="email"
            value={email}
            error={emailError}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setEmailError(false)}
            onKeyPress={(e) => sendOnEnter(e, signUp)}
          />
          <InputText
            id="password-signup"
            label={language.localization.pasword }
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setPasswordError(false)}
            error={passwordError}
            onKeyPress={(e) => sendOnEnter(e, signUp)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {showPassword
                    ? (
                    <VisibilityIcon
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    />
                      )
                    : (
                    <VisibilityOffIcon
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    />
                      )}
                </InputAdornment>
              )
            }}
          />
          <InputText
            id="repeat-password-signup"
            type={showPasswordRepeat ? 'text' : 'password'}
            label={language.localization['repeat-pasword'] }
            variant="outlined"
            value={repeatPassword}
            onFocus={() => setRepeatPasswordError(false)}
            onChange={(e) => setRepeatPassword(e.target.value)}
            error={repeatPasswordError}
            onKeyPress={(e) => sendOnEnter(e, signUp)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {showPassword
                    ? (
                    <VisibilityIcon
                      onClick={() => {
                        setShowPasswordRepat(!showPasswordRepeat)
                      }}
                    />
                      )
                    : (
                    <VisibilityOffIcon
                      onClick={() => {
                        setShowPasswordRepat(!showPasswordRepeat)
                      }}
                    />
                      )}
                </InputAdornment>
              )
            }}
          />
          <ButtonLogin
            onClick={loading ? () => {} : signUp}
            variant="contained"
          >
            {loading
              ? (
              <CircularProgress color="inherit" size={25} />
                )
              : (
                  language.localization.register
                )}
          </ButtonLogin>
          <NewAccount to="/" style={{ color: Colors.header, marginTop: 20 }}>
          {language.localization.back}
          </NewAccount>
        </Container>
        <FooterComponent language={language}/>
      </Page>
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
    </div>
  )
}

SignUpScreen.propTypes = {
  signUpEmail: PropTypes.any,
  loading: PropTypes.any,
  setLanguage: PropTypes.any,
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  resetPassword: PropTypes.any,
  language: PropTypes.any
}

export default connect(SignUpScreen)
