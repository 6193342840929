import * as React from 'react'
import { Input, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../constants/Theme'

const TextFieldInnerInput = styled(Input)`
  width:100%;
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
`
const TextFieldInnerTextField = styled(TextField)`
  width:100%;
  font-size: 15px;
  font-family: Inter, Helvetica, Arial, Verdana, Trebuchet MS, sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: ${Colors.blueText};
  margin-bottom: 20px;
`

export const SmartTextfield = ({ onSave, saveOnBlur, autoClean, inputRef, input = 'input', ...props }) => {
  let lastValue

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (e.target.value !== '') {
        if (onSave) {
          onSave(e.target.value)
        }
        lastValue = e.target.value
      }
    }
  }

  const onBlur = (e) => {
    if (!saveOnBlur) return
    console.log(e.target.value, lastValue)
    if (e.target.value !== '' && e.target.value !== lastValue) {
      if (onSave) {
        onSave(e.target.value)
      }

      lastValue = e.target.value
    }
  }

  return input === 'input'
    ? (<TextFieldInnerInput
            {...props}
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
    />)
    : (
      <TextFieldInnerTextField
                {...props}
                inputRef={inputRef}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
              />
      )
}

SmartTextfield.propTypes = {
  onSave: PropTypes.any,
  saveOnBlur: PropTypes.any,
  autoClean: PropTypes.any,
  inputRef: PropTypes.any,
  input: PropTypes.any
}
