import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../constants/Theme'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Container } from '@mui/system'

export const HeaderBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 90px; */
  background-color: ${Colors.white};
  justify-content: start;
  border-radius:5px;
  @media (max-width: 440px) {
    border-radius: 0;
  }
  span {
    padding: 10px;
    font-weight: 400;
    font-size: 24px;
    color: ${Colors.white};
  }
`

export const Title = styled(Typography)`
  padding: 22px;
  text-align:center;
  font-weight: 400;
  font-size: 20px;
  color: ${Colors.black};
`

const FormHeader = ({ backLink, course, module }) => {
  const navigate = useNavigate()

  return (
    <HeaderBody>
          {backLink &&
              <ArrowBackIcon
                onClick={() => {
                  navigate(backLink)
                }}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  color: Colors.black,
                  // flex:1,
                  marginTop: 30,
                  marginLeft: 15,
                  width: 60,
                  hieght: 60
                }}
              />
          }

          <Container style={{
            display: 'flex',
            flexDirection: 'column',
            // flex:11,
            gap: 0,
            maxWidth: 'auto',
            padding: 0,
            margin: 0
          }}>

            <Title sx={{
              display: 'flex',
              flex: 1,
              padding: 0,
              paddingTop: 2,
              margin: 0,
              fontSize: 24
            }}>{module ? module.orden + ' ' + module.title : course?.title}</Title>

            <Title sx={{
              display: 'flex',
              flex: 1,
              margin: 0,
              padding: 0,
              // paddingLeft:4,
              paddingBottom: 2,
              fontSize: 13,
              color: Colors.primaryColor
            }}>{'@' + course?.owner?.username}</Title>

          </Container>

          {/* <div style={{flex:1}}>
            <span style={{ marginRight: 10, cursor: 'pointer' }}>
              <img
                onClick={() => navigate('/')}
                src={closeIcon}
                alt="cerrar"
              />
            </span>
          </div> */}

    </HeaderBody>
  )
}

FormHeader.propTypes = {
  course: PropTypes.any,
  module: PropTypes.any,
  backLink: PropTypes.any
}

export default FormHeader
