export const URLS = {
  // LOCAL
  // base: 'https://127.0.0.1/api/v1.0/',
  // use_jwt: false

  // STAGE
  // base:'https://dle-staging.herokuapp.com/api/v1.0/',
  // use_jwt: true,

  // PROD
  base: 'https://backend.digitalexs.com/api/v1.0/',
  use_jwt: false
}
