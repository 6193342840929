import axios from 'axios'

import { ERROR_TYPES } from '../constants/ErrorTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const sendMail =
  (contactname, contactmail, contacbody) =>
    async (dispatch) => {
      try {
        const params = {
          name: contactname,
          email: contactmail,
          body: contacbody
        }
        const response = await axios.post(`${URLS.base}contact/`, params)
        if (response) {
          dispatch(
            showToast({
              show: true,
              message: '¡Tu consulta ha sido enviada!',
              type: ERROR_TYPES.SUCCESS
            })
          )

          // setTimeout(() => { window.location.href = '/#/login' }, 3000)

          return response
        } else {
          dispatch(
            showToast({
              show: true,
              message: 'Ups! Ocurrió un error.',
              type: ERROR_TYPES.ERROR
            })
          )
          return false
        }
      } catch (error) {
        dispatch(
          showToast({
            show: true,
            message: getServerErrors(error),
            type: ERROR_TYPES.ERROR
          })
        )
      }
    }

export default sendMail
