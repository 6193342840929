import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Column, ColumnContainer, NewRowContainer, TextTitle } from '../../../screens/stepOne/style'
import { Tooltip } from '@mui/material'

import folderIcon from '../../../assets/icons/drive_folder_upload.png'

import connect from '../connect'
import InfoIcon from '@mui/icons-material/Info'
import { Colors } from '../../../constants/Theme'
import { useParams } from 'react-router-dom'
import { AddRounded } from '@mui/icons-material'
import { sendGAEvent } from '../../../utils/useGAPageView'
import { formatDate } from '../../../utils/dates'
import { SmartTextfield } from '../../SmartTextfield'
import ModuleItem from './ModuleItem'
import { ModuleListContainer } from './style'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enCA, es } from 'date-fns/locale'

const ModulesList = ({ course, language, endpointCreate, endpointEdit, endpointGet, endpointDelete, onModulesChanged }) => {
  const [modules, setModules] = useState([])
  const [newDate, setNewDate] = useState(new Date())
  const [newModuleName, setNewModelName] = useState('')

  const { id } = useParams()

  // const refreshModule = async (mid, editAfter = false) => {
  //   const { data } = await endpointGet('Curso', 'cursos', id)
  //   console.log(data.modulos)
  //   setModules(data.modulos)
  //   // if (onModulesChanged) onModulesChanged(data.modulos)
  // }

  const handleDeleteModule = async (moduleID) => {
    console.log(moduleID)
    const { success } = await endpointDelete('Modulo', 'modulos', moduleID)

    if (success) {
      const nextModules = modules.filter((val) => val.id !== moduleID)
      setModules(nextModules)
      // refreshModule(null)
    }
  }

  // const getHigherOrder = () => {
  //   let high = 0
  //   modules.forEach((m) => {
  //     if (m.orden > high) {
  //       high = m.orden
  //     }
  //   })
  //   return high
  // }

  const updateOrCreateModule = async (item) => {
    if (item.id) {
      // edit
      const params = { curso: id, title: item.title, date: formatDate(item.date) }
      await endpointEdit(
        'modulo',
        'modulos',
        item.id,
        { params }
      )

      const nextModules = modules.map(module => {
        if (module.id === item.id) {
          return {
            ...module,
            title: item.title,
            date: formatDate(item.date)
          }
        } else {
          return module
        }
      })
      nextModules.sort((a, b) => new Date(a.date) < new Date(b.date) ? -1 : (new Date(a.date) > new Date(b.date) ? 1 : 0))
      setModules(nextModules)
      if (onModulesChanged) onModulesChanged(nextModules)
    } else {
      // create
      // const nextOrder = getHigherOrder() + 1
      // console.log(`next:${nextOrder}`)
      // eslint-disable-next-line new-cap
      const params = { curso: id, title: item.title, duration: 2, date: formatDate(newDate) }
      const { success, newId } = await endpointCreate(
        'modulo',
        'modulos',
        { params }
      )
      if (success) {
        const m = [...modules, { title: item.title, id: newId, date: newDate }]
        m.sort((a, b) => new Date(a.date) < new Date(b.date) ? -1 : (new Date(a.date) > new Date(b.date) ? 1 : 0))
        setModules(m)
        if (onModulesChanged) onModulesChanged(m)

        sendGAEvent({
          category: 'edit',
          action: 'module'
        })
      }
    }
    // refreshModule(null)
  }

  useEffect(() => {
    if (course) {
      setModules(course.modulos)
    }
  }, [course])

  return (
    <Column>
    <ColumnContainer>
      <TextTitle>
      <div className="row">
        <img alt={ language.localization['list-of-modules'] }
        style={{ width: '25px', marginRight: 10 }}
        src={folderIcon}
        />
        <span
        className="titleForm"
        style={{ display: 'flex', justifyContent: 'center' }}
        >
        <span style={{ marginRight: 10 }}>
        { language.localization['list-of-modules'] }
        </span>
        <Tooltip
          title={ language.localization['module-list-tooltip'] }
          arrow
          placement="right"
        >
          <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
        </Tooltip>
        </span>
      </div>
      </TextTitle>
      <span className="objetivesDescription">
      { language.localization['module-description'] }
      </span>
      <ModuleListContainer>
      {modules.map((data, index) => {
        return (
        <ModuleItem
          allowDelete={true}
          allowEdit={true}
          id={index}
          data={data}
          key={index}
          deleteItem={() => {
            handleDeleteModule(data.id)
          }}
          edit={async (value) => {
            await updateOrCreateModule(value)
          }}
        />
        )
      })}
      </ModuleListContainer>
      <NewRowContainer>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={language.lang === 'es' ? es : enCA}
      >
        <DatePicker
          style={{ flex: 1 }}
          label={ language.localization['start-date'] }
          okLabel="Guardar"
          cancelLabel="Cerrar"
          value={newDate}
          onChange={(newValue) => {
            setNewDate(newValue)
          }}
        />
      </LocalizationProvider>
      <SmartTextfield
      className="titleInput"
      saveOnBlur={true}
      onSave={async (value) => {
        await updateOrCreateModule({
          curso: id,
          title: value,
          date: formatDate(newDate)
        })
        setNewModelName('')
      }}
      value={newModuleName}
      onChange={(e) => setNewModelName(e.target.value)}
      style={{ marginTop: 20, width: '100%', maxLength: 100 }}
      // placeholder={ language.localization['modules-placeholder'] }
      />
      <AddRounded style={{
        width: 28,
        height: 28,
        marginRight: 5,
        marginLeft: 8,
        marginTop: 18,
        color: Colors.primaryColor,
        cursor: 'pointer'
      }}
        onClick={async () => {
          await updateOrCreateModule({
            curso: id,
            title: newModuleName
          })
          setNewModelName('')
        }}></AddRounded>
    </NewRowContainer>
    </ColumnContainer>
    </Column>

  )
}

ModulesList.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointCreate: PropTypes.any,
  endpointEdit: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointDelete: PropTypes.any,
  onModulesChanged: PropTypes.any
}

export default connect(ModulesList)
