import { MenuItem } from '@mui/material'
import styled from 'styled-components'
import { Colors } from '../../../constants/Theme'

export const MediaRow = styled(MenuItem)`
  display:flex;
  flex-direction:row;
  width:140px;
  gap:5px;
  font-size:10pt;
  background-color: ${Colors.red};
  align-items: center;
`

export const MediaImage = styled.img`
  display:flex;
  /* flex:1; */
  width:24px;
  height:24px;
`

export const MediaTitle = styled.p`
  display:flex;
  flex:8;
  width:24px;
  height:24px;
`
