import React from 'react'
import { PageHeader, FAQFooter, FAQFooterText, Page, Terms, Term, ListItemTerm } from './style'
import PropTypes from 'prop-types'

import Header from '../../components/Header'
import { useGAPageView } from '../../utils/useGAPageView'
import connect from './connect'
import FooterComponent from '../../components/Footer'
import Box from '@material-ui/core/Box'
import { Typography } from '@mui/material'

const TYCScreen = ({ setLanguage, language }) => {
  useGAPageView()

  return (
    <Page>
      <Header setLanguage={setLanguage} language={language}/>

      <PageHeader sx={{
        fontSize: { xs: 30, md: 40 },
        paddingTop: { xs: 0, sm: 6 },
        paddingBottom: { xs: 0, sm: 6 }
      }}>{ language.localization['terms-and-conditions'] }</PageHeader>
      <Terms>
        <Box>
        <Term>
          <Typography variant="h6">
            { language.localization['tyc-introduction'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-introduction-1'] }
            </ListItemTerm>
          </Typography>
        </Term>
        <Term>
          <Typography variant="h6">
          { language.localization['tyc-use-of-the-application'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
            { language.localization['tyc-use-of-the-application-1'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
            { language.localization['tyc-use-of-the-application-2'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
            { language.localization['tyc-use-of-the-application-3'] }
            </ListItemTerm>
          </Typography>
        </Term>
        <Term>
          <Typography variant="h6">
            { language.localization['tyc-user-generated-content'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-user-generated-content-1'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-user-generated-content-2'] }
            </ListItemTerm>
          </Typography>
        </Term>
        <Term>
          <Typography variant="h6">
            { language.localization['tyc-intellectual-property-rights'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-intellectual-property-rights-1'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-intellectual-property-rights-2'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-intellectual-property-rights-3'] }
            </ListItemTerm>
          </Typography>
        </Term>
        <Term>
          <Typography variant="h6">
            { language.localization['tyc-privacy-and-data-protection'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-privacy-and-data-protection-1'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-privacy-and-data-protection-2'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-privacy-and-data-protection-3'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-privacy-and-data-protection-4'] }
            </ListItemTerm>
          </Typography>
        </Term>
        <Term>
          <Typography variant="h6">
            { language.localization['tyc-modifications-and-termination'] }
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-modifications-and-termination-1'] }
            </ListItemTerm>
          </Typography>
          <Typography variant="body1">
            <ListItemTerm>
              { language.localization['tyc-modifications-and-termination-2'] }
            </ListItemTerm>
          </Typography>
        </Term>
        </Box>
      </Terms>
      <PageHeader sx={{
        fontSize: { xs: 30, md: 40 },
        paddingTop: { xs: 0, sm: 6 },
        paddingBottom: { xs: 0, sm: 6 }
      }}>{ language.localization['tyc-data-policy'] }</PageHeader>
      <Terms>
        <Box>
          <Term>
            <Typography variant="h6">
              { language.localization['tyc-collected-information'] }
            </Typography>
            <Typography variant="body1">
              <ListItemTerm>
                { language.localization['tyc-collected-information-1'] }
              </ListItemTerm>
            </Typography>
            <Typography variant="body1">
              <ListItemTerm>
                { language.localization['tyc-collected-information-2'] }
              </ListItemTerm>
            </Typography>
          </Term>
          <Term>
            <Typography variant="h6">
              { language.localization['tyc-use-of-information'] }
            </Typography>
            <Typography variant="body1">
              <ListItemTerm>
                { language.localization['tyc-use-of-information-1'] }
              </ListItemTerm>
            </Typography>
            <Typography variant="body1">
              <ListItemTerm>
                { language.localization['tyc-use-of-information-2'] }
              </ListItemTerm>
            </Typography>
          </Term>
          <Term>
            <Typography variant="h6">
              { language.localization['tyc-cookies-and-similar-technologies'] }
            </Typography>
            <Typography variant="body1">
              <ListItemTerm>
                { language.localization['tyc-cookies-and-similar-technologies-1'] }
              </ListItemTerm>
            </Typography>
          </Term>
        </Box>
      </Terms>
      <FAQFooter>
        <FAQFooterText>
          { language.localization['tyc-contact'] } <a href='mailto:soporte@dle.com.uy'>soporte@dle.com.uy</a>
        </FAQFooterText>
      </FAQFooter>
      <FooterComponent language={language} />
    </Page>
  )
}

TYCScreen.propTypes = {
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default connect(TYCScreen)
