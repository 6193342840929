import React, { useState, useEffect } from 'react'
import { Column, ColumnContainer, TextTitle } from '../../../screens/stepOne/style'
import PropTypes from 'prop-types'

import commentIcon from '../../../assets/icons/comment.png'
import connect from '../connect'
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import axios from 'axios'
import { URLS } from '../../../utils/urls'
import { sendGAEvent } from '../../../utils/useGAPageView'
import { Colors } from '../../../constants/Theme'

const LearningEnvironments = ({ course, language, endpointEdit, endpointGet }) => {
  const [scenes, setScenes] = useState([])

  const selectScene = async (scene, checked) => {
    // refresh
    const newScenes = []
    scenes.forEach((s) => {
      const newData = { ...s }
      newData.checked = scene.id === s.id ? checked : s.checked
      newScenes.push(newData)
    })
    setScenes(newScenes)
    // update
    const action = checked ? 'add' : 'remove'
    await axios.put(`${URLS.base}cursos/${course.id}/scenes/${action}/${scene.id}/`)
    if (checked) {
      sendGAEvent({
        category: 'edit',
        action: 'scene',
        label: scene.title
      })
    }
  }

  useEffect(() => {
    const fetchScenes = async () => {
      // get available scenes
      const scenesData = await endpointGet('Escenarios', 'scenes')
      if (scenesData.data) {
        const courseScenes = []
        scenesData.data.forEach((scene) => {
          let found = false
          course.scenes.forEach((courseScene) => {
            if (scene.id === courseScene.id) {
              found = true
            }
          })
          courseScenes.push({
            id: scene.id,
            title: scene.title,
            checked: found
          })
        })
        setScenes(courseScenes)
      }
    }

    if (course) {
      fetchScenes()
    }
  }, [course, endpointGet])

  return (
    <Column sx={{ minWidth: { xs: 'auto', md: 420 } }}>
    <ColumnContainer>
      <TextTitle>
      <div className="row">
        <img
        style={{ height: 15, width: 20 }}
        className="iconForm"
        src={commentIcon}
        alt="Entornos Educativos"
        />
        <span className="titleForm" style={{ marginRight: 10 }}>
        { language.localization['learning-environments'] }
        </span>
        <Tooltip
        title={ language.localization['learning-environments-tooltip'] }
        arrow
        placement="right"
        >
        <InfoIcon sx={{ color: Colors.primaryColor }}></InfoIcon>
        </Tooltip>
      </div>
      <span
        className="objetivesDescription"
        style={{ marginTop: 16, display: 'flex' }}
      >
        { language.localization['learning-environments-description'] }
      </span>
      </TextTitle>
      <FormGroup style={{ marginRight: 50 }}>
      {scenes.map((scene, i) =>
        <FormControlLabel
        key={`scene_${i}`}
        control={
          <Checkbox
          checked={scene.checked}
          onChange={(e) => selectScene(scene, e.target.checked)}
          />
        }
        label={ language.localization[scene.title] }
        />
      )}

      </FormGroup>

    </ColumnContainer>
  </Column>
  )
}
LearningEnvironments.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointGet: PropTypes.any,
  endpointEdit: PropTypes.any
}
export default connect(LearningEnvironments)
