import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Column, ColumnContainer, TextTitle } from '../../../screens/stepOne/style'
import { InputAdornment, Typography } from '@mui/material'
import styled from 'styled-components'

import noteIcon from '../../../assets/icons/note.png'
import pencilIcon from '../../../assets/icons/pencil.png'
import { Colors } from '../../../constants/Theme'

import connect from '../connect'
import { SmartTextarea } from '../../SmartTextarea'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const Title = styled(Typography)`
  padding: 22px;
  text-align:center;
  font-weight: 400;
  font-size: 20px;
  color: ${Colors.black};
`

const CourseTitle = ({ course, language, endpointEdit }) => {
  const [title, setTitle] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (course) {
      setTitle(course.title)
    }
  }, [course])

  const saveTitle = async () => {
    const params = course
    params.title = title
    params.image = undefined

    await endpointEdit('Curso', 'cursos', course.id, { params })
  }

  return (
    <Column style={{ marginTop: 20 }}>
        <ColumnContainer>
            <TextTitle>
            <Title sx={{
              display: 'flex',
              flex: 1,
              margin: 0,
              padding: 0,
              // paddingLeft:4,
              paddingBottom: 2,
              fontSize: 13,
              color: Colors.primaryColor
            }}>{'@' + course?.owner?.username}</Title>
            <div className="row">
            <ArrowBackIcon
                onClick={() => {
                  navigate('/')
                }}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  color: Colors.black,
                  // flex:1,
                  width: 60,
                  hieght: 60
                }}/>
                <img
                className="iconForm"
                src={noteIcon}
                alt={ language.localization['course-title'] }
                />
                <span className="titleForm">{ language.localization['course-title'] }</span>
            </div>
            </TextTitle>
            <SmartTextarea
                className="titleInput"
                inputProps={{ maxLength: 300 }}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={ language.localization['specify-the-course-title'] }
                endAdornment={
                    <InputAdornment position="end">
                    <img
                        className="iconForm"
                        src={pencilIcon}
                        alt={ language.localization['specify-the-course-title'] }
                    />
                    </InputAdornment>
                }
                saveOnBlur
                onSave={ () => {
                  saveTitle()
                }}
                style={{
                  textAlign: 'left'
                }}
                value={title}
                type="text"
            />
        </ColumnContainer>
    </Column>

  )
}
CourseTitle.propTypes = {
  course: PropTypes.any,
  language: PropTypes.any,
  endpointEdit: PropTypes.any
}
export default connect(CourseTitle)
