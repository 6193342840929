import { connect } from 'react-redux'
import { compose } from 'redux'
import setLanguage from '../../actions/SetLanguage'

const mapStateToProps = (state) => ({
  language: state.language,
  user: state.user
})

const mapDispatchToProps = {
  setLanguage
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
