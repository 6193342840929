import { Container, Typography } from '@mui/material'
import styled from 'styled-components'
import { Colors } from '../../../constants/Theme'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.white};
  border-radius: 5px;
  margin-bottom: 20px;
  /* height: 100%; */
  @media (max-width: 440px) {
    border-radius: 0;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: space-between;
`

export const BoxInfo = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  padding:0;
  font-size:10pt;
  background-color: ${Colors.white};

`
export const AcivityRow = styled.div`
  display:flex;
  flex-direction:row;
  gap:10px;
  font-size:10pt;
  background-color: ${Colors.white};
  align-items: center;
  padding-top:10px;
`

export const ResourceBox = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  font-size:10pt;
  background-color: ${Colors.white};
  align-items: center;
  padding-top:10px;
  width:100%;
`
export const ModuleListContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const ModuleBoxSubtitle = styled.div`
  display:block;
  width:100%;
  padding:0;
  font-size:12pt;
`

export const ModuleBoxItem = styled(Typography)`
  /* display:block; */
  width:100%;
  padding:0;
  margin:7px;
  font-size:9pt;
`

export const ModuleInfo = styled(Typography)`
  /* display:block; */
  width:100%;
  padding:0;
  margin:7px;
  font-size:9pt;
`
export const CompetenceRow = styled.div`
  display:flex;
  flex-direction:column;
  font-size:10pt;
  background-color: ${Colors.white};
  align-items: start;
  padding-top:10px;
`
export const CompetenceDetailRow = styled(Container)`
  display:flex;
  flex-direction:column;
  font-size:10pt;
  background-color: ${Colors.white};
  align-items: start;
`
