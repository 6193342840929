import React, { useState } from 'react'
import connect from './connect'
import { Page, ButtonContacto, InputTextContacto, ContainerContact } from '../signIn/style'
import ToastMessage from '../../components/ToastMessage'
// import background from '../../assets/icons/background.png'
import { useGAPageView } from '../../utils/useGAPageView'
import PropTypes from 'prop-types'
import Header from '../../components/Header'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { Colors } from '../../constants/Theme'
import ContactImg from '../../assets/contact_img.png'
import { validateEmail } from '../../utils/validateEmail'
import { ERROR_TYPES } from '../../constants/ErrorTypes'
import Alert from '@mui/material/Alert'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ReCAPTCHA from 'react-google-recaptcha'
import { EnvCaptcha } from '../../constants/env'

export const BoxContact = styled(Box)`
margin:2em;
width:100%;
`
const ContactScreen = ({ showToast, toastProps, sendMail, setLanguage, language }) => {
  const [contactname, setcontactname] = useState('')
  const [contactmail, setcontactmail] = useState('')
  const [contacbody, setcontacbody] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [mailEnviado, setmailEnviado] = useState(null)
  const recaptchaRef = React.createRef()

  useGAPageView()

  const doSendMail = async () => {
    const recaptchaValue = recaptchaRef.current.getValue()
    console.log(contactname)
    console.log(contactmail)
    console.log(contacbody)
    if (contactmail) {
      if (!validateEmail(contactmail)) {
        showToast({
          show: true,
          message: 'El correo es invalido.',
          type: ERROR_TYPES.ERROR
        })
      }
    }

    if (
      contactname &&
      contacbody &&
      contacbody &&
      recaptchaValue &&
      validateEmail(contactmail)
    ) {
      const success = await sendMail(contactname, contactmail, contacbody)
      if (success) {
        setTimeout(() => {
          console.log('mando el mail chabon')
          setmailEnviado(true)
        }, 500)
      }
    } else {
      if (!validateEmail(contactmail)) {
        showToast({
          show: true,
          message: 'El correo es invalido.',
          type: ERROR_TYPES.ERROR
        })
      } else {
        if (!recaptchaValue) {
          showToast({
            show: true,
            message: 'Debes marcar el captcha',
            type: ERROR_TYPES.ERROR
          })
        } else {
          showToast({
            show: true,
            message: 'Hay campos incompletos',
            type: ERROR_TYPES.ERROR
          })
        }
      }
    }
  }

  return (

    <div style={{ minHeight: '100vh' }}>
      <Header setLanguage={setLanguage} language={language} />
      <Page>
        {/* <Background src={background} /> */}
        <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <ContainerContact style={{ zIndex: 2, textAlign: 'left' }}>
          <BoxContact>
            <Typography variant='h2' sx={{ color: Colors.orange }}>{language.localization['title-contacto']}</Typography>
            <Typography variant='h5'>{language.localization['sub-contact']}</Typography>
            <img src= {ContactImg} alt="img contact" style={{ width: '80%', marginTop: '1em' }}/>
          </BoxContact>
        </ContainerContact>
        </Grid>
        <Grid item xs={12} md={6}>
        <ContainerContact style={{ zIndex: 2 }}>
        { mailEnviado &&
        <BoxContact>
          <Alert icon={<CheckCircleOutlineIcon fontSize='2rem' />}><Typography variant='h5' sx={{ color: 'Black' }}>¡Tu consulta ha sido enviada!</Typography></Alert>
        </BoxContact>
        }
        { !mailEnviado &&
        <BoxContact>
        <InputTextContacto
          label={language.localization['contact-name']}
          variant="outlined"
          type="Text"
          value={contactname}
          onChange={(e) => setcontactname(e.target.value)}
        />
        <InputTextContacto
          label={language.localization['contact-mail']}
          variant="outlined"
          type="Text"
          value={contactmail}
          onChange={(e) => setcontactmail(e.target.value)}
          onFocus={() => setEmailError(false)}
          error={emailError}
        />
        <InputTextContacto
          label={language.localization['contact-body']}
          variant="outlined"
          type="Text"
          multiline
          value={contacbody}
          rows={9}
          onChange={(e) => setcontacbody(e.target.value)}
        />
        <ReCAPTCHA
        sitekey= {EnvCaptcha.sitekey}
        ref={recaptchaRef}
        />
        <ButtonContacto onClick={doSendMail} variant="contained">
        {language.localization['contact-button']}
        </ButtonContacto>
      </BoxContact>
        }
        </ContainerContact>
        </Grid>
        </Grid>
      </Page>
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
    </div>

  )
}

ContactScreen.propTypes = {
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  language: PropTypes.any,
  setLanguage: PropTypes.any,
  sendMail: PropTypes.any
}

export default connect(ContactScreen)
