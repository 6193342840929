export const formatDate = (date) => {
  if (
    typeof date === 'string' ||
    date instanceof String
  ) {
    return date
  }

  if (date !== undefined) {
    // const offset = date.getTimezoneOffset();
    // date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0]
  }
}
