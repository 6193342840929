// import axios from 'axios'
import { ERROR_TYPES } from '../constants/ErrorTypes'
import {
  SIGN_IN_GOOGLE,
  SIGN_IN_GOOGLE_ERROR,
  SIGN_IN_GOOGLE_SUCCESS
} from '../types/actionTypes'
import { getServerErrors } from '../utils/serverErrors'
import { URLS } from '../utils/urls'

import showToast from './Toast'

const signInGoogle = () => async (dispatch) => {
  try {
    dispatch({ type: SIGN_IN_GOOGLE })

    window.open(`${URLS.base}accounts/google/login/`)
    const response = false

    // const response = await axios({
    //   method: 'post',
    //   url: `${URLS.base}accounts/google/login/`,
    //   withCredentials: true,
    //   maxRedirects: 0,
    //   headers: {
    //     'content-type': 'application/json'
    //   },
    //   beforeRedirect: (options, { headers }) => {
    //     console.log('before redirect')
    //     console.log(options)
    //   }
    // })

    if (response) {
      dispatch({
        type: SIGN_IN_GOOGLE_SUCCESS
        // payload: {
        //   email,
        //   loggedin: true,
        // },
      })
      dispatch(
        showToast({
          show: true,
          message: 'Bienvenido',
          type: ERROR_TYPES.SUCCESS
        })
      )
      return true
    } else {
      dispatch(
        showToast({
          show: true,
          message: 'Ups! Ocurrió un error al intentar acceder con Google.',
          type: ERROR_TYPES.ERROR
        })
      )
      dispatch({
        type: SIGN_IN_GOOGLE_ERROR
      })
      return false
    }
  } catch (error) {
    dispatch(
      showToast({
        show: true,
        message: getServerErrors(error),
        type: ERROR_TYPES.ERROR
      })
    )
    dispatch({
      type: SIGN_IN_GOOGLE_ERROR
    })
  }
}

export default signInGoogle
