import { HIDE_TOAST, SHOW_TOAST } from '../types/actionTypes'

const showToast =
  ({ show, type, message }) =>
    (dispatch) => {
      if (show === true) {
        dispatch({
          type: SHOW_TOAST,
          payload: {
            show,
            type,
            message
          }
        })
      } else {
        dispatch({
          type: HIDE_TOAST,
          payload: false
        })
      }
    }

export default showToast
