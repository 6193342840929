import React, { useRef } from 'react'
import * as d3 from 'd3'
import { useD3 } from '../hooks/useD3'
import PropTypes from 'prop-types'

const LearningNetwork = ({ language }) => {
  const d = {
    nodes: [
      {
        id: 1,
        name: language.localization['collaborative-learning'],
        pic: require('../assets/icon-aprendizaje-colaborativo.png')
      },
      {
        id: 2,
        name: language.localization['case-studies'],
        pic: require('../assets/icon-casos.png')
      },
      {
        id: 3,
        name: language.localization['project-based-learning'],
        pic: require('../assets/icon-aprendizaje-basado-proyectos.png')
      },
      {
        id: 4,
        name: language.localization['peer-learning'],
        pic: require('../assets/icon-aprendizaje-entre-pares.png')
      },
      {
        id: 5,
        name: language.localization.gamification,
        pic: require('../assets/icon-gamificacion.png')
      },
      {
        id: 6,
        name: language.localization['immersive-learning'],
        pic: require('../assets/icon-aprendizaje-inmersivo.png')
      },
      {
        id: 7,
        name: language.localization['flipped-classroom'],
        pic: require('../assets/icon-aula-invertida.png')
      }
    ],
    links: [
      { source: 3, target: 1 },
      { source: 3, target: 4 },
      { source: 3, target: 7 },
      { source: 1, target: 2 },
      { source: 1, target: 4 },
      { source: 2, target: 4 },
      { source: 2, target: 6 },
      { source: 2, target: 7 },
      { source: 2, target: 5 },
      { source: 4, target: 4 },
      { source: 4, target: 6 },
      { source: 4, target: 7 },
      { source: 5, target: 6 }
    ]
  }

  // const [data] = useState(d)
  const data = d

  const tooltipRef = useRef(null)

  const ref = useD3((svg) => {
    const width = 800
    const height = 600

    svg.attr('viewBox', '0 0 800 600')

    // Initialize the links
    const link = svg
      .selectAll('line')
      .data(data.links)
      .enter()
      .append('line')
      .style('stroke', '#c8c8c8')
      .style('stroke-width', 5)

    const Tooltip = d3.select(tooltipRef.current)
      .style('opacity', 0)
      .attr('class', 'noselect')
      .style('background-color', 'rgb(252 183 158)')
      .style('border-radius', '5px')
      .style('padding', '15px')

    const mouseover = function (d) {
      Tooltip
        .style('opacity', 1)
    }
    const mousemove = function (e, d) {
      Tooltip
        .html(d.name)
        .style('left', (e.clientX) + 'px')
        .style('top', (e.clientY - 80) + 'px')
    }
    const mouseleave = function (d) {
      Tooltip
        .style('opacity', 0)
    }

    // Initialize the nodes
    const node = svg
      .selectAll('circle')
      .data(data.nodes)
      .enter()
      .append('svg:image')
      .attr('xlink:href', (d) => { return d.pic })
      .attr('width', 80)
      .attr('height', 80)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)

    // Let's list the force we wanna apply on the network
    const simulation = d3.forceSimulation(data.nodes) // Force algorithm is applied to data.nodes
      .force('link', d3.forceLink() // This force provides links between nodes
        .id(function (d) { return d.id }) // This provide  the id of a node
        .links(data.links) // and this the list of links
      )
      .force('charge', d3.forceManyBody().strength(-3000)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      .force('center', d3.forceCenter(width / 2, height / 2)) // This force attracts nodes to the center of the svg area
      .force('collide', d3.forceCollide().strength(0.01).radius(50).iterations(1))
    // .force("boundary", d3.forceBoundary(0,0,width, height)) // not available in this d3 version
      .on('tick', ticked)

    const drag = d3
      .drag()
      .on('start', dragstart)
      .on('drag', dragged)

    node.call(drag).on('click', click)

    function clamp (x, lo, hi) {
      return x < lo ? lo : x > hi ? hi : x
    }

    // This function is run at each iteration of the force algorithm, updating the nodes position.
    function ticked () {
      link
        .attr('x1', function (d) { return d.source.x })
        .attr('y1', function (d) { return d.source.y })
        .attr('x2', function (d) { return d.target.x })
        .attr('y2', function (d) { return d.target.y })

      node
        .attr('x', function (d) { return clamp(d.x - 40, 0, width - 80) })
        .attr('y', function (d) { return clamp(d.y - 40, 0, height - 80) })
    }

    function click (event, d) {
      delete d.fx
      delete d.fy
      d3.select(this).classed('fixed', false)
      simulation.alpha(1).restart()
    }

    function dragstart () {
      d3.select(this).classed('fixed', true)
    }

    function dragged (event, d) {
      d.fx = clamp(event.x, 0, width)
      d.fy = clamp(event.y, 0, height)
      simulation.alpha(1).restart()
    }

    simulation.restart()
  }, [data])

  return (
    <>
      <div
        ref={tooltipRef}
        style={{
          // backgroundColor:'red',
          position: 'fixed'
          // width:'100%'
        }}>

      </div>
      <svg
        ref={ref}
        style={{
          // backgroundColor:'red',
          // height: 650,
          // width: "100%",
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
      </svg>
    </>
  )
}

LearningNetwork.propTypes = {
  language: PropTypes.any
}

export default LearningNetwork
