import { React, useState } from 'react'
import { Tooltip } from '@mui/material'
import { SmartTextfield } from '../../SmartTextfield'
import trashIcon from '../../../assets/icons/trash.png'
import { RowContainer } from '../style'
import PropTypes from 'prop-types'

const Activityitem = ({ item, deleteItem, editItem }) => {
  const [title, setTitle] = useState(item.title)
  const [percentage, setPercentage] = useState(item.percentage)

  return (
    <RowContainer style={{ padding: '0 10px' }}>

        <SmartTextfield
            saveOnBlur
            onSave={() => editItem({ ...item, title, percentage })}
            style={{
              textAlign: 'left',
              margin: 5,
              padding: '0 12px'
            }}
            sx={{
              width: { xs: '64%', md: '85%' }
            }}
            value={title}
            type="text"
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            // onKeyPress={(e) => sendOnEnter(e, saveItem)}
          />
        <SmartTextfield
          saveOnBlur
          onSave={() => editItem({ ...item, title, percentage })}
          style={{
            textAlign: 'right',
            margin: 5
          }}
          sx={{
            width: { xs: '14%', md: '14%' }
          }}
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
          type="number"
        />
        %

        <Tooltip title="Eliminar" arrow placement="top">
            <img
              style={{
                width: 18,
                height: 18,
                marginDown: 10,
                marginRight: 10,
                marginLeft: 10,
                cursor: 'pointer'
              }}
              src={trashIcon}
              alt="Eliminar"
              onClick={() => {
                deleteItem(item)
              }}
            />
          </Tooltip>

    </RowContainer>
  )
}

Activityitem.propTypes = {
  item: PropTypes.any,
  deleteItem: PropTypes.any,
  editItem: PropTypes.any
}

export default Activityitem
