import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const AlertDialog = ({ action, type, closeModal }) => {
  const handleClose = () => {
    closeModal(false)
  }

  const handleAcept = () => {
    action()
    closeModal(false)
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-slide-description">
          ¿Estás seguro que deseas eliminar el {type}?
        </DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'black' }} onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="outlined"
          onClick={handleAcept}
          startIcon={<DeleteIcon />}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  action: PropTypes.string,
  type: PropTypes.string,
  closeModal: PropTypes.func
}

export default AlertDialog
