import { logout } from './backend'

export const getServerErrors = (error) => {
  if (error.response.status === 403) {
    // session expired
    logout()
    return 'Tu sesion ha expirado'
  }
  let message

  for (const k in error?.response?.data) message = error?.response?.data[k]
  if (message?.length > 0 && message !== '') {
    // console.log(message[0]?.message)
    return message[0]?.message || message
  }

  return message || 'Ups! Ocurrió un error.'
}

export const getFormErrors = (error) => {
  const errors = []
  for (const k in error?.response?.data) errors.push({ name: k, data: error?.response?.data[k] })
  return errors
}
