import React, { useState } from 'react'
import connect from './connect'
import { useGAPageView } from '../../utils/useGAPageView'
import PropTypes from 'prop-types'

import { ExportButton, ExportCaption, ExportImage } from '../../screens/course/style'
import ExportModal from './ExportModal'

const ExportButtons = ({ user, icon, caption, cost, onAccept }) => {
  useGAPageView()

  const [open, setOpen] = useState(false)

  return (
    <>
      <ExportButton
        onClick={() => {
          if (cost === 0) onAccept()
          else setOpen(true)
        }}
      >
      { icon &&
        <ExportImage
          src={icon}
          alt="export"
        />
      }
      <ExportCaption>{ caption }</ExportCaption>
      </ExportButton>
      <ExportModal user={user} icon={icon} caption={caption} cost={cost} onAccept={onAccept}
        open={open} onClose={() => {
          setOpen(false)
        }}>
    </ExportModal>
</>
  )
}

ExportButtons.propTypes = {
  user: PropTypes.any,
  icon: PropTypes.any,
  caption: PropTypes.any,
  cost: PropTypes.any,
  onAccept: PropTypes.any
}

export default connect(ExportButtons)
