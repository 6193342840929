import { ERROR_TYPES } from '../constants/ErrorTypes'
import * as actions from '../types/actionTypes'

const initialState = {
  show: false,
  type: ERROR_TYPES.SUCCESS,
  message: ''
}

export default function Toast (state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_TOAST:
      return {
        show: true,
        type: action.payload.type,
        message: action.payload.message
      }

    case actions.HIDE_TOAST:
      return {
        show: false
      }
    default:
      return state
  }
}
