import React, { useState } from 'react'
import connect from './connect'
import { useParams } from 'react-router-dom'
import { Logo, Page, ButtonLogin, InputText, Container } from '../signIn/style'
import ToastMessage from '../../components/ToastMessage'
import LogoImage from '../../assets/logo-app.png'
// import background from '../../assets/icons/background.png'
import { useGAPageView } from '../../utils/useGAPageView'
import PropTypes from 'prop-types'
import Header from '../../components/Header'

const ChangePasswordScreen = ({ showToast, toastProps, changePassword, setLanguage, language }) => {
  const [passwordError, setPasswordError] = useState(false)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const { uid, token } = useParams()

  useGAPageView()

  const doResetPassword = async () => {
    await changePassword(uid, token, password1, password2)
    console.log(uid)
    console.log(token)
    console.log(password1)
    console.log(password2)
  }

  return (

    <div style={{ backgroundColor: '#D1DBDE', minHeight: '100vh' }}>
      <Header setLanguage={setLanguage} language={language} />
      <Page>
        {/* <Background src={background} /> */}
        <Container style={{ zIndex: 2 }}>
          <Logo src={LogoImage} />
          <InputText
            label="Password"
            variant="outlined"
            type="password"
            value={password1}
            error={passwordError}
            onChange={(e) => setPassword1(e.target.value)}
            onFocus={() => setPasswordError(false)}
          />
          <InputText
            label="Password"
            variant="outlined"
            type="password"
            value={password2}
            error={passwordError}
            onChange={(e) => setPassword2(e.target.value)}
            onFocus={() => setPasswordError(false)}
          />
          <ButtonLogin onClick={doResetPassword} variant="contained">
            Enviar
          </ButtonLogin>
        </Container>
      </Page>
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
    </div>

  )
}

ChangePasswordScreen.propTypes = {
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  language: PropTypes.any,
  setLanguage: PropTypes.any,
  changePassword: PropTypes.any
}

export default connect(ChangePasswordScreen)
