import styled from 'styled-components'
import { Colors } from '../../constants/Theme'
import { Box } from '@mui/material'

export const Page = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background: ${Colors.white};
  margin:30px;
`

export const H1 = styled('h1')`
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: ${Colors.primaryColor};
  margin: 40px 40px 40px 0px!important;
`
export const H2 = styled('h2')`
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: ${Colors.secondaryColor};
`
export const P = styled('h2')`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;;
`
