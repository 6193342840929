// import axios from 'axios'
import { URLS } from '../utils/urls'

const endpointExport =
  ({ path, courseId, ext }) =>
    async (dispatch) => {
      return new Promise((resolve) => {
        const url = `${URLS.base}export/${path}/${courseId}`
        // console.log(url);
        window.open(url, '_blank')
        setTimeout(resolve, 1000)
      })

      // return;

      // const response = await axios.get(
      //   url
      // )
      // const data = response.data
      // if (data.error) {
      //   return
      // }
      // console.log(response.headers)
      // const type = response.headers['content-type']
      // const filename = response.headers['dle-export-filename']
      // console.log(filename, type)

      // // var file = new Blob([data]);
      // // var file = new Blob([data], {type: 'octet/stream'});
      // const file = new Blob([data], { type })

      // console.log(file)

      // if (window.navigator.msSaveOrOpenBlob) // IE10+
      // { window.navigator.msSaveOrOpenBlob(file, filename) } else { // Others
      //   const download_url = URL.createObjectURL(file)
      //   const a = document.createElement('a')
      //   a.href = download_url
      //   a.download = filename
      //   document.body.appendChild(a)
      //   a.click()
      //   setTimeout(function () {
      //     document.body.removeChild(a)
      //     window.URL.revokeObjectURL(download_url)
      //   }, 100)
      // }
    }

export default endpointExport
