import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import connect from './connect'
import PropTypes from 'prop-types'

import FormHeader from '../../components/FormHeader'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

import QuizIcon from '../../assets/icons/quiz.png'

import questionImage from '../../assets/icons/questionImage.png'

import {
  Container,
  Body,
  FormContainer,
  ColumnContainer,
  Column,
  TextTitle
} from './style.js'
import { Colors } from '../../constants/Theme'
import { sendGAEvent, useGAPageView } from '../../utils/useGAPageView'

const Questionary = ({ getQuestion, setLanguage, language }) => {
  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState([])
  const [options, setOptions] = useState([])
  const [resources, setResources] = useState([])
  const [activities, setActivities] = useState([])
  const [isStrategy, setIsStrategy] = useState(false)
  const [courseID, serCourseId] = useState(null)
  const [tips, setTips] = useState('')
  const [image, setImage] = useState('')

  const navigate = useNavigate()

  useGAPageView()

  useEffect(() => {
    const fetchQuestion = async () => {
      // fetch root question
      const result = await getQuestion({ question_id: null })
      setQuestion(language.localization[result.data.title])
      setImage(result.data.image ? result.data.image : questionImage)
      setTips(result.data.tips)
      setOptions(result.data.options)
      serCourseId(result.data.course_id)
      console.log('data: ', result.data)
    }
    fetchQuestion()
  }, [getQuestion, language])

  const fetchQuestion = async (option) => {
    sendGAEvent({
      category: 'question',
      action: 'answer',
      question,
      answer: option.title
    })

    setOptions([])
    setResources([])
    setActivities([])
    setDescription([])
    setQuestion('')

    const result = await getQuestion({ question_id: option.goto })

    // console.log(result.data);

    if (result.data.title) setQuestion(language.localization[result.data.title])
    if (result.data.options) setOptions(result.data.options)
    if (result.data.description) setDescription(result.data.description)
    if (result.data.resources) setResources(result.data.resources)
    if (result.data.activities) setActivities(result.data.activities)

    setTips(result.data.tips)
    setImage(result.data.image ? result.data.image : questionImage)

    if (result.data.finished) {
      for (let i = 0; i < result.data.strategies.length; i++) {
        sendGAEvent({
          category: 'strategy',
          action: result.data.strategies[i].title
        })
      }
      setTimeout(() => {
        navigate(`/strategies/${courseID}`)
      }, 1000)
    }

    setIsStrategy(result.data.strategy)
  }

  const buttonHandler = (o) => {
    return fetchQuestion(o)
  }

  const optionButtons = options.map((o) => {
    return (
      <div
        style={{
          width: '60px',
          height: 32,
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          padding: '0 10px',
          margin: 10,
          marginLeft: 0,
          cursor: 'pointer',
          backgroundColor: '#D1DBDE',
          color: Colors.black
        }}
        key={o.title}
        onClick={() => {
          buttonHandler(o)
        }}
        className={`button-${o.title.toLowerCase()}`}
      >
        {language.localization[o.title]}
        {/* <img
          style={{ marginLeft: 10 }}
          src={o.title.toUpperCase() == 'NO' ? nosuccess : success}
        /> */}
      </div>
    )
  })

  const descriptionList = description.map((r) => <span key={r}>{r}</span>)
  const resourcesList = resources.map((r) => (
    <span key={r.id}>{r.title}</span>
  ))
  const activitiesList = activities.map((r) => (
    <span key={r.id}>{r.title}</span>
  ))

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language}/>
      <Body>
        <FormHeader
          title={ language.localization['step-1'] }
          save={() => {}}
          backLink="/"
        />
        <FormContainer>
          <Column style={{ marginTop: 20 }}>
            <ColumnContainer>
              <TextTitle>
                <div className="row">
                  <img
                    style={{ width: '25px', marginRight: 10 }}
                    src={QuizIcon}
                  />
                  <span className="titleForm">{ language.localization.questions }</span>
                </div>
              </TextTitle>
              <span className="objetivesDescription">
              { language.localization['questions-text'] }
              </span>
              {isStrategy && (
                <div
                  style={{
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}
                >
                  <span style={{ fontSize: 16, fontWeight: 300 }}>
                    {question}
                  </span>
                  {descriptionList}
                  <h2>Te recomendamos los siguientes recursos:</h2>
                  {resourcesList}
                  <h2>Actividades que puedes implementar:</h2>
                  {activitiesList}
                </div>
              )}

              {!isStrategy && (
                <div
                  style={{
                    marginTop: 50,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}
                >
                  <span style={{ fontSize: 16, fontWeight: 300 }}>
                    {question}
                  </span>
                </div>
              )}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {optionButtons}
              </div>
              <span
                style={{
                  height: 1,
                  display: 'flex',
                  backgroundColor: Colors.blackText,
                  marginTop: 20
                }}
              ></span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 80
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'start'
                  }}
                >
                  {/* <span
                    style={{
                      fontWeight: 500,
                      color: Colors.blackText,
                      marginBottom: 20,
                    }}
                  >
                    APRENDIZAJE COLABORATIVO:
                  </span> */}
                  <span
                    className="objetivesDescription"
                    style={{ lineHeight: '24px' }}
                  >
                    { language.localization[tips] }
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img alt={question} width={300} src={image} />
                </div>
              </div>
            </ColumnContainer>
          </Column>
        </FormContainer>
      </Body>
      <Footer />
    </Container>
  )
}

Questionary.propTypes = {
  getQuestion: PropTypes.any,
  setLanguage: PropTypes.any,
  language: PropTypes.any
}

export default connect(Questionary)
