import styled from 'styled-components'
import { Colors } from '../../../constants/Theme'

export const CompetenceHeader = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding:10px;
margin-bottom:10px;
background: ${Colors.background};
color:${Colors.primaryColor};
width:20%;
justify-content:right;
`
