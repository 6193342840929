import React from 'react'
import connect from './connect'
import { useGAPageView } from '../../utils/useGAPageView'
import { Box, Modal, Typography, Button } from '@mui/material'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Coin from '../../assets/coin.png'
import { localizationReplace } from '../../utils/localization'

export const ExportModalContainer = styled(Modal)`
  display:flex;
  align-items:center;
  justify-content:center;
`

export const ExportModalBox = styled(Box)`
  top: 50px;
  margin: 'auto';
`

const Creditlogo = styled.img`
  height: 25px;
  margin: 10px;
  vertical-align: middle;
  @media (max-width: 440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 320px) {
    margin-bottom: 20px;
  }
`

const FormLink = styled.a`
  color: #4B97B4;
  width: 100%;
  text-align: center;
  display: block;
`

const ExportModal = ({
  user, icon, caption,
  open, onClose,
  language,
  cost, onAccept
}) => {
  useGAPageView()

  //   const [open, setOpen] = useState(true);

  //   const { id } = useParams();

  //   console.log(user);

  return (
  <ExportModalContainer open={open} onClose={onClose}>
    <ExportModalBox
    sx={{
      mx: 'auto',
      px: 5,
      py: 2,
      backgroundColor: '#ffffff'
    }}>

    <Typography align='center'>
      {localizationReplace(language.localization['credits-consumtion'], [cost])}
    </Typography>
    <Typography align='center'>
      {localizationReplace(language.localization['credits-balance'], [user.credits])}
    </Typography>
    <Typography
      align='center'
    >
      <Button onClick={() => onClose()}
        color="error"
        variant="outlined"
        sx={{ padding: 1.2, margin: 2 }}
        >
        {language.localization.close}
      </Button>

    {user?.credits < 1 &&
      <Button
        href="/#/buy-credits"
        variant="outlined"
        sx={{ padding: 1, margin: 2 }}>

          <Creditlogo src={Coin}/>
          Obtener más Créditos
        </Button>
    }
    {user?.credits > 0 &&
      <Button
        onClick={() => {
          onAccept()
          onClose()
        }}
        variant="outlined"
        sx={{ padding: 1, margin: 2 }}>
        <img style={{ margin: 5, width: 20 }}
          className="iconForm"
          src={icon}
          alt="anterior"
        />
      {caption}
      </Button>
    }
      </Typography>

      <FormLink href='https://forms.gle/gqJaB3P1PJAcwQzm6' target='_blank' rel="noreferrer">Ayduanos a Mejorar</FormLink>

    </ExportModalBox>
  </ExportModalContainer>
  )
}

ExportModal.propTypes = {
  language: PropTypes.any,
  user: PropTypes.any,
  icon: PropTypes.any,
  caption: PropTypes.any,
  cost: PropTypes.any,
  open: PropTypes.any,
  onAccept: PropTypes.any,
  onClose: PropTypes.any
}

export default connect(ExportModal)
