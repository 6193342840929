import React, { useState } from 'react'
import connect from './connect'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ERROR_TYPES } from '../../constants/ErrorTypes'

import LogoImage from '../../assets/logo-app.png'
import ToastMessage from '../../components/ToastMessage'
import { validateEmail } from '../../utils/validateEmail'
// import background from '../../assets/icons/background.png'

import { Logo, Page, ButtonLogin, InputText, Container } from '../signIn/style'
import { useGAPageView } from '../../utils/useGAPageView'
import FooterComponent from '../../components/Footer'
import Header from '../../components/Header'

const FargotPasswordScreen = ({ showToast, toastProps, resetPassword, language, setLanguage }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)

  const navigate = useNavigate()

  useGAPageView()

  const doResetPassword = async () => {
    if (!email) {
      setEmailError(true)
    }

    if (!validateEmail(email)) {
      setEmailError(true)
      showToast({
        show: true,
        message: language.localization['enter-valid-email'],
        type: ERROR_TYPES.ERROR
      })
      return
    }

    await resetPassword(email)

    showToast({
      show: true,
      message: language.localization['check-your-email'],
      type: ERROR_TYPES.INFO
    })
    setTimeout(() => {
      navigate('/')
    }, 2000)
  }

  return (
    <div style={{ backgroundColor: '#D1DBDE', minHeight: '100vh' }}>
      <Header setLanguage={setLanguage} language={language} />
      <Page>
        {/* <Background src={background} /> */}
        <Container style={{ zIndex: 2 }}>
          <Logo src={LogoImage} />
          <InputText
            id="email-reset-password"
            label={language.localization.email }
            variant="outlined"
            type="email"
            value={email}
            error={emailError}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setEmailError(false)}
          />
          <ButtonLogin onClick={doResetPassword} variant="contained">
          {language.localization.send }
          </ButtonLogin>
        </Container>
        <FooterComponent language={language}/>
      </Page>
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
    </div>
  )
}

FargotPasswordScreen.propTypes = {
  setLanguage: PropTypes.any,
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  resetPassword: PropTypes.any,
  language: PropTypes.any
}

export default connect(FargotPasswordScreen)
