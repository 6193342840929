import styled from 'styled-components'
import { Colors } from '../../constants/Theme'
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

export const Logo = styled.img`
  width: 35%;
  margin-bottom:10px;
  @media (max-width: 440px) {
    margin-bottom: 10px;
  }
  @media (max-width: 320px) {
    margin-bottom: 10px;
  }
`

export const ButtonLogin = styled(Button)`
  margin: 5px 0 !important;
  width: 50% !important;
  height: 50px !important;
  border-radius: 80px !important;
  background: ${Colors.header} !important;
`
export const ButtonContacto = styled(Button)`
  margin: 5px 0 !important;
  width: 90% !important;
  height: 50px !important;
  border-radius: 80px !important;
  background: ${Colors.header} !important;
`

export const ButtonGoogle = styled(Button)`
  margin: 5px 0 !important;
  width: 80%;
  height: 50px;
  background-color: ${Colors.gray} !important;
  color: ${Colors.blackText} !important;
  font-weight: bold !important;
  border-radius: 8px;
  text-transform: inherit !important;
  border-color: transparent !important;
`

export const ButtonFacebook = styled(Button)`
  margin: 5px 0 !important;
  width: 80%;
  height: 50px;
  background-color: ${Colors.gray} !important;
  color: ${Colors.blackText} !important;
  font-weight: bold !important;
  border-radius: 8px;
  text-transform: inherit !important;
  border-color: transparent !important;
`

export const InputText = styled(TextField)`
  margin-bottom: 20px !important;
  width: 80%;
`
export const InputTextContacto = styled(TextField)`
  margin-bottom: 20px !important;
  width: 90%;
`

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  // height:100%;
  // background-color:blue;
  min-height: 100vh;
  // height: calc(100vh - 100px);
`

export const Container = styled.div`
  background: ${Colors.white};
  max-width: 400px;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 5px !important;
  padding: 30px 0;
  margin:20px;
`
export const ContainerContact = styled.div`
  background: ${Colors.white};
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 5px !important;
  padding: 0 5em;
`

export const Background = styled.img`
  position: absolute;
  z-index: 1;
  overflow:hidden;
  @media (max-width: 440px) {
    display:none;
  }
`

export const CreateAccount = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`

export const NewAccount = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`
export const TyC = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
`

export const FargotPassword = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
  margin-top: 20px;
  text-decoration: none;
`
