import { connect } from 'react-redux'
import { compose } from 'redux'
import showToast from '../actions/Toast'

import { getShowToast } from '../selectors/selectors'
import setLanguage from '../actions/SetLanguage'

const mapStateToProps = (state) => ({
  toastProps: getShowToast(state),
  language: state.language,
  user: state.user
})

const mapDispatchToProps = {
  showToast,
  setLanguage
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
