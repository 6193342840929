import React, { useState } from 'react'
import connect from './connect'
import { useNavigate } from 'react-router-dom'
import ToastMessage from '../../components/ToastMessage'
import LogoImage from '../../assets/logo-app.png'
// import background from '../../assets/icons/background.png'
// import facebook from '../../assets/icons/facebook.png'
import google from '../../assets/icons/google.png'
import PropTypes from 'prop-types'

import {
  Logo,
  Page,
  ButtonLogin,
  InputText,
  // ButtonFacebook,
  ButtonGoogle,
  NewAccount,
  TyC,
  Container,
  FargotPassword
  // Background
} from './style'

import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CircularProgress from '@mui/material/CircularProgress'

import { Colors } from '../../constants/Theme'
import sendOnEnter from '../../components/sendOnEnter'
import { useGAPageView } from '../../utils/useGAPageView'
import Header from '../../components/Header'
import FooterComponent from '../../components/Footer'

const SignInScreen = ({
  signInFacebook,
  signInGoogle,
  signInEmail,
  showToast,
  toastProps,
  loading,
  language,
  setLanguage
}) => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [userNameError, setUserNameError] = useState(null)
  const [passwordError, setPasswordError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()

  useGAPageView()

  const googleSignIn = async () => {
    const success = await signInGoogle()
    if (success) {
      navigate('/')
    }
  }

  // const facebookSignIn = async () => {
  //   const success = await signInFacebook()
  //   if (success) {
  //     navigate('/')
  //   }
  // }

  const signIn = async () => {
    if (!userName) {
      setUserNameError(true)
    }
    if (!password) {
      setPasswordError(true)
    }

    if (userName && password) {
      const success = await signInEmail({ userName, password })
      if (success) {
        // navigate('/');
        // window.location.href = '/#/';
      }
    }
  }

  const showPasswordFunc = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div style={{ backgroundColor: '#D1DBDE', minHeight: '100vh' }}>
      <Header setLanguage={setLanguage} language={language} />
      <Page>
        {/* <Background src={background} /> */}
        <Container style={{ zIndex: 2 }}>
          <Logo src={LogoImage} />
          <p style={{ marginLeft: '10%', marginRight: '10%', color: '#4B97B4', textAlign: 'center' }}>{ language.localization['login-screen'] }</p>
          <div
            style={{
              width: '80%',
              height: '1px',
              background: Colors.blackText,
              margin: 20
            }}
          ></div>
          <>
            <ButtonGoogle
              onClick={() => googleSignIn()}
              variant="outlined"
              startIcon={<img width={25} height={25} src={google} />}
            >
              Ingresar con Google
            </ButtonGoogle>
            {/* <ButtonFacebook
              onClick={() => facebookSignIn()}
              variant="outlined"
              startIcon={<img width={30} height={30} src={facebook} />}
            >
              Ingresar con Facebook
            </ButtonFacebook> */}
          </>
          <div
            style={{
              width: '80%',
              height: '1px',
              background: Colors.blackText,
              margin: 20
            }}
          ></div>
          <InputText
            id="username-input"
            type="email"
            // label={ language.localization.user }
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
            error={userNameError}
            onFocus={() => setUserNameError(false)}
            onKeyPress={(e) => sendOnEnter(e, signIn)}
          />
          <InputText
            id="password-input"
            type={showPassword ? 'text' : 'password'}
            label={ language.localization.pasword }
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setPasswordError(false)}
            error={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {showPassword
                    ? (
                    <VisibilityIcon onClick={showPasswordFunc} />
                      )
                    : (
                    <VisibilityOffIcon onClick={showPasswordFunc} />
                      )}
                </InputAdornment>
              )
            }}
            onKeyPress={(e) => sendOnEnter(e, signIn)}
          />
          <ButtonLogin
            onClick={loading ? () => {} : () => signIn()}
            variant="contained"
          >
            {loading
              ? (
              <CircularProgress color="inherit" size={25} />
                )
              : (
                  language.localization['log-in']
                )}
          </ButtonLogin>
          <FargotPassword
            style={{ color: Colors.header }}
            to="/nueva-contrasenia"
          >
            {language.localization['forgot-password'] }
          </FargotPassword>
          <TyC
            style={{ color: Colors.header }}
            to="/tyc">
              {language.localization['terms-and-conditions'] }
          </TyC>
          <NewAccount style={{ color: Colors.header }} to="/signup">
            {language.localization['create-user'] }
          </NewAccount>
        </Container>
        <FooterComponent language={language}/>
      </Page>
      {toastProps.show && (
        <ToastMessage
          showToast={showToast}
          message={toastProps.message}
          type={toastProps.type}
        />
      )}
    </div>
  )
}

SignInScreen.propTypes = {
  signInGoogle: PropTypes.any,
  signInFacebook: PropTypes.any,
  signInEmail: PropTypes.any,
  loading: PropTypes.any,
  setLanguage: PropTypes.any,
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  resetPassword: PropTypes.any,
  language: PropTypes.any
}

export default connect(SignInScreen)
