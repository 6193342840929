import React, { useEffect, useState } from 'react'
import { Routes, Route, HashRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './store'

import SignInScreen from './screens/signIn/SignInScreen'
import SignUpScreen from './screens/signUp/SignUpScreen'

import { getUserSessionStorage } from './utils/userSessionStorage'

import HomeScreen from './screens/home/HomeScreen'
import FargotPasswordScreen from './screens/fargotPassword/FargotPasswordScreen'
import Questionary from './screens/questionary/Questionary'
import StepInformation from './screens/stepInformation/StepInformation'
import EmailVerified from './screens/emailVerified/EmailVerified'
import ChangePassword from './screens/changePassword/ChangePasswordScreen'
import FAQScreen from './screens/faq/FAQScreen'
import TYCScreen from './screens/tyc/TYCScreen'
import PrivacyPolicyScreen from './screens/privacyPolicy/PrivacyPolicyScreen'
import DashboardScreen from './screens/dashboard/DashboardScreen'
import BuyCreditsScreen from './screens/buyCredits/BuyCreditsScreen'
import SearchScreen from './screens/search/SearchScreen'
import CourseScreen from './screens/course/CourseScreen'
import ModuleScreen from './screens/course/ModuleScreen'
import setUser from './actions/SetUser'
import setLanguage from './actions/SetLanguage'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ContactScreen from './screens/contact/ContactScreen'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f46524'
    }
  }
})

function App (props) {
  const localUser = getUserSessionStorage()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    store.dispatch(setUser())
    var userLang = navigator.language || navigator.userLanguage
    let res = userLang.substring(0, 2)
    if (res !== 'es') {
      res = 'en'
    }
    store.dispatch(setLanguage(res))
    setReady(true)
  }, [])

  const router = !ready
    ? <></>
    : <HashRouter>
      <ThemeProvider theme={theme}>
    <Provider store={store}>
        <Routes>
          <Route
            path="/login"
            element={<SignInScreen />}
          ></Route>
          <Route
            path="/"
            element={localUser && localUser.loggedin ? <DashboardScreen /> : <HomeScreen/>}
          ></Route>
          <Route path="/signup" element={<SignUpScreen />}></Route>
          <Route
            exact
            path="/nueva-contrasenia"
            element={<FargotPasswordScreen />}
          ></Route>
          <Route
            exact
            path="/email-verified"
            element={<EmailVerified />}
          ></Route>
          <Route
            exact
            path="/reset-password/:uid/:token/"
            element={<ChangePassword />}
          ></Route>
          <Route
            exact
            path="/faq/"
            element={<FAQScreen />}
          ></Route>
          <Route
            exact
            path="/tyc/"
            element={<TYCScreen />}
          ></Route>
          <Route
            exact
            path="/como-funciona"
            element={<HomeScreen />}
          ></Route>
          <Route
            exact
            path="/questionary"
            element={<Questionary />}
          ></Route>
          <Route
            path="/course/:id"
            element={<CourseScreen />}
          ></Route>
          <Route
            exact
            path="/course/:id/module/:mid/"
            element={<ModuleScreen />}
          ></Route>
          <Route
            exact
            path="/strategies/:id"
            element={<StepInformation />}
          ></Route>
          <Route
            exact
            path="/email-verified"
            element={<EmailVerified />}
          ></Route>
          <Route
            exact
            path="/reset-password/:uid/:token/"
            element={<HomeScreen />}
          ></Route>
          <Route
            exact
            path="/faq/"
            element={<FAQScreen />}
          ></Route>
          <Route
            exact
            path="/privacy-policy/"
            element={<PrivacyPolicyScreen />}
          ></Route>
          <Route
            exact
            path="/buy-credits/"
            element={localUser && localUser.loggedin ? <BuyCreditsScreen /> : <HomeScreen/>}
          ></Route>
          <Route
            exact
            path="/search/"
            element={<SearchScreen />}
          ></Route>
          <Route
            exact
            path="/contact/"
            element={<ContactScreen />}
          ></Route>
        </Routes>
    </Provider>
      </ThemeProvider>
  </HashRouter>

  return router
}

export default App
