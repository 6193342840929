import * as actions from '../types/actionTypes'

const initialState = {
  user: {},
  username: '',
  credits: 0,
  costs: {},
  avatar: '',
  access_token: ''
}

export default function User (state = initialState, action) {
  switch (action.type) {
    // case actions.SIGN_IN_ERROR:
    //   return {
    //     user: action.payload,
    //   };

    // case actions.SIGN_IN:
    //   return {
    //     user: action.payload,
    //   };

    // case actions.SIGN_UP:
    //   return {
    //     user: action.payload,
    //   };

    case actions.SIGN_IN_SUCCESS:
      return action.payload

      // case actions.SIGN_UP_ERROR:
      //   return {
      //     user: action.payload,
      //   };

      // case actions.SIGN_UP_SUCCESS:
      //   return {
      //     user: action.payload,
      //   };

      // case actions.SIGN_IN_GOOGLE_ERROR:
      //   return {
      //     user: {},
      //   };
      // case actions.SIGN_IN_GOOGLE_SUCCESS:
      //   return {
      //     user: action.payload,
      //   };

      // case actions.SIGN_IN_FACEBOOK_ERROR:
      //   return {
      //     user: {},
      //   };

      // case actions.SIGN_IN_FACEBOOK_SUCCESS:
      //   return {
      //     user: action.payload,
      //   };

    default:
      return state
  }
}
