import { combineReducers } from 'redux'
import User from './user'
import Toast from './toast'
import GetCourse from './getCourse'
import UserData from './userData'
import LanguageData from './language'

export default combineReducers({
  user: User,
  toast: Toast,
  course: GetCourse,
  userdata: UserData,
  language: LanguageData
})
