import React from 'react'
import connect from './connect'
import { useNavigate } from 'react-router-dom'
// import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
// import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css'
// import withAutoplay from 'react-awesome-slider/dist/autoplay'
import Header from '../../components/Header'
import FooterComponent from '../../components/Footer'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import {
  Container,
  ButtonStart
} from './style.js'
import { Box, Typography } from '@mui/material'
// import styledComponents from 'styled-components'
import LearningNetwork from '../../components/LearningNetwork'

import { useGAPageView } from '../../utils/useGAPageView'

/* const AutoplaySlider = withAutoplay(AwesomeSlider)
const StyledSlider = styledComponents(AutoplaySlider)`
  & .awssld__content {
    background-color: transparent !important;
  }
  & .awssld__wrapper{ -webkit-box-shadow: -2px 5px 19px 6px #CFCFCF; box-shadow: -2px 5px 19px 6px #CFCFCF; }

  & .awssld__bullets button {
    background-color: ${Colors.orange}; !important;
  }

` */

const HomeScreen = ({ user, setLanguage, language }) => {
  useGAPageView()
  const navigate = useNavigate()

  return (
    <Container>
      <Header setLanguage={setLanguage} language={language} />
        <Grid container sx={{
          padding: '3% 10%',
          backgroundColor: 'white'
        }}>
        <Grid item xs={12} md={6}>
          <Box
          sx={{
            padding: '0 5%',
            backgroundColor: 'white'
          }}>
          <Typography variant="h1" component="H1"
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              width: '100%',
              fontWeight: 400,
              fontSize: { xs: 45, md: 54 },
              lineHeight: { xs: '50px', md: '60px' },
              letterSpacing: { xs: -0.2, md: -0.55 },
              color: '#1c1b1f',
              margin: '0 2'
            }}>
            { language.localization['home-title'] }
          </Typography>
          <Typography
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: 0.5,
            m: 2
          }}>
            {/* language.localization['home-description'] */}
          </Typography>
          <ButtonStart style={{ margin: 20, display: 'none' }}
            onClick={() => {
              navigate(user?.loggedin ? '/questionary' : '/login')
            }}
          >
            { language.localization['home-button'] }
          </ButtonStart>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
          sx={{
            margin: '0 2'
          }}>
            <iframe
            src='https://www.youtube.com/embed/NMfZZFN7MWA?controls=0'
            width='100%'
            height= '300px'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen>
            </iframe>
          </Box>
        </Grid>
      </Grid>

      <Box // <---oculto en home V2
        sx={{
          display: 'none',
          flexDirection: { xs: 'column', sm: 'column', md: 'row ' },
          // margin: '3% 10% 50px',
          minHeight: { xs: 'auto', md: 500 },
          padding: 3,
          background: 'rgba(244, 101, 36, 0.21)'
        }}
      >
        <Box
          sx={{
            // backgroundColor:'red',
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            flex: { xs: 1, md: 2 },
            justifyContent: 'center',
            marginLeft: { xs: 5, md: 0 },
            // marginTop: {xs:20, md:-10},
            // marginBottom: {xs:20, md:10},
            alignItems: 'start'
          }}
        >
          <LearningNetwork setLanguage={setLanguage} language={language}/>

          <div style={{
            position: 'absolute',
            display: 'none'
          }}>
            <img
              style={{ width: '100%' }}
              src={require('../../assets/lines-home.png')}
            />

            <div>
              <img
                className="casos animation-item"
                src={require('../../assets/icon-casos.png')}
              />
              <div
                className="tooltip tooltip-casos"
                style={{ right: '6%', top: '-38%' }}
              >
                <span className="tooltip-text">{ language.localization['case-studies'] }</span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-aprendizaje-colaborativo animation-item"
                src={require('../../assets/icon-aprendizaje-colaborativo.png')}
              />
              <div
                className="tooltip tooltip-icon-aprendizaje-colaborativo"
                style={{ top: '-30%', left: '12%' }}
              >
                <span className="tooltip-text">{ language.localization['collaborative-learning'] }</span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-aprendizaje-basado-proyectos animation-item"
                src={require('../../assets/icon-aprendizaje-basado-proyectos.png')}
              />
              <div
                className="tooltip tooltip-icon-aprendizaje-basado-proyectos"
                style={{ top: '14%', left: '-17%' }}
              >
                <span className="tooltip-text">
                { language.localization['project-based-learning'] }
                </span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-aula-invertida animation-item"
                src={require('../../assets/icon-aula-invertida.png')}
              />
              <div
                className="tooltip tooltip-icon-aula-invertida"
                style={{ bottom: '23%', left: '2%' }}
              >
                <span className="tooltip-text">{ language.localization['flipped-classroom'] }</span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-aprendizaje-entre-pares animation-item"
                src={require('../../assets/icon-aprendizaje-entre-pares.png')}
              />
              <div
                className="tooltip tooltip-icon-aprendizaje-entre-pares"
                style={{ bottom: '55%', left: '29%' }}
              >
                <span className="tooltip-text">{ language.localization['peer-learning'] } </span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-gamificacion animation-item"
                src={require('../../assets/icon-gamificacion.png')}
              />
              <div
                className="tooltip tooltip-icon-gamificacion"
                style={{ top: '4%', right: '-18%' }}
              >
                <span className="tooltip-text">{ language.localization.gamification }</span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>

            <div>
              <img
                className="icon-aprendizaje-inmersivo animation-item"
                src={require('../../assets/icon-aprendizaje-inmersivo.png')}
              />
              <div
                className="tooltip tooltip-aprendizaje-inmersivo"
                style={{ bottom: '32%', right: '1%' }}
              >
                <span className="tooltip-text">{ language.localization['immersive-learning'] }</span>
                <div
                  className="tooltip-arrow"
                  style={{ left: '46%', bottom: '-7%' }}
                ></div>
              </div>
            </div>
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            // marginTop:{xs:1, md:10},
            m: { xs: 1, md: 10 },

            width: '100%'
          }}
        >
           <Typography variant="h1" component="span"
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              width: '100%',
              fontWeight: 400,
              fontSize: 57,
              textAlign: { xs: 'left', md: 'right' },
              lineHeight: '64px',
              letterSpacing: -0.25,
              color: '#1c1b1f'

            }}>
            { language.localization['home-sumate'] }
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '24px',
              letterSpacing: 0.5,
              m: { xs: 1, md: 2 },
              mt: { xs: 3, md: 2 }
            }}
          >
            { language.localization['home-identifica'] }
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: '#F4F4F4',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          // marginBottom: 5,
          padding: '50px 0'
        }}
      >
        <div
          style={{
            display: 'flex',
            // flexDirection: {xs:'column', md:'row'},
            alignItems: 'center',
            justifyContent: 'center'
            // marginBottom:0
          }}
        >
          <Box
            sx={{
              color: '#F46524',
              fontSize: 28,
              fontWeight: 400,
              lineHeight: '36px',
              width: '70%',
              marginBottom: { xs: 5, md: 0 }
            }}
          >
            { language.localization['empieza-a-construir'] }
          </Box>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}
        >
          <ButtonStart
            onClick={() => {
              navigate(user?.loggedin ? '/' : '/login')
            }}
          >
            { language.localization['home-crear-2'] }
          </ButtonStart>
        </div>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          // marginBottom: 5,
          padding: '50px 0'
        }}
      >
        <div
          style={{
            // flexDirection: {xs:'column', md:'row'},
            alignItems: 'center'
            // marginBottom:0
          }}
        >
          <Box
            sx={{
              fontSize: 28,
              fontWeight: 400,
              lineHeight: '36px',
              width: '100%'
            }}
          >
          <a href="https://www.instagram.com/dlexperience" rel="noreferrer" target='_blank'>
            <img style={{
              width: '30px',
              margin: '0 .3em'
            }}
            src={require('../../assets/icons/ig_icon.png')} alt="" />
          </a>
          <a href="https://twitter.com/dlearning_ex" rel="noreferrer" target='_blank'>
            <img style={{
              width: '30px',
              margin: '0 .3em'
            }}
            src={require('../../assets/icons/tw_icon.png')} alt="" />
          </a>
          </Box>
        </div>
      </Box>
      <FooterComponent language={language} />
    </Container>
  )
}

HomeScreen.propTypes = {
  setLanguage: PropTypes.any,
  showToast: PropTypes.any,
  toastProps: PropTypes.any,
  resetPassword: PropTypes.any,
  language: PropTypes.any,
  user: PropTypes.any
}

export default connect(HomeScreen)
