import { SIGN_IN, SIGN_IN_SUCCESS } from '../types/actionTypes'
import { getUserSessionStorage } from '../utils/userSessionStorage'

const setUser =
  () =>
    async (dispatch) => {
      dispatch({
        type: SIGN_IN,
        payload: {}
      })

      const user = getUserSessionStorage()

      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: user
      })
    }

export default setUser
