import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import PropTypes from 'prop-types'

const Alert = React.forwardRef(function Alert (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ToastMessage = ({ type, message, showToast }) => {
  const TOAST_TIME = 5000

  const [open, setOpen] = useState(true)

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    showToast({ show: false, message: '', type: '' })
    setOpen(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      showToast({ show: false, type: '' })
    }, TOAST_TIME)

    return () => {
      clearTimeout(timer)
    }
  }, [message, showToast])

  return (
    <Stack>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

ToastMessage.propTypes = {
  type: PropTypes.any,
  message: PropTypes.any,
  showToast: PropTypes.any
}

export default ToastMessage
