import { connect } from 'react-redux'
import { compose } from 'redux'

import endpointCreate from '../../actions/EndpointCreate'
import endpointEdit from '../../actions/EndpointEdit'
import endpointDelete from '../../actions/EndpointDelete'
import endpointGet from '../../actions/EndpointGet'
import endpointExport from '../../actions/EndpointExport'
import showToast from '../../actions/Toast'

import { getShowToast } from '../../selectors/selectors'
import setLanguage from '../../actions/SetLanguage'

const mapStateToProps = (state) => ({
  toastProps: getShowToast(state),
  language: state.language,
  user: state.user
})

const mapDispatchToProps = {
  endpointCreate,
  endpointEdit,
  endpointDelete,
  endpointGet,
  endpointExport,
  showToast,
  setLanguage
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)
