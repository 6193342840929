import { useState, useEffect, React } from 'react'
import PropTypes from 'prop-types'
import { proxy } from '../utils/backend'
import styled from 'styled-components'
import { Colors } from '../constants/Theme'
import { Typography } from '@mui/material'
import Linkify from 'linkify-react'
import YouTube from 'react-youtube'

export const PreviewContainer = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  font-size:10pt;
  background-color: ${Colors.white};
  align-items: center;
  padding-top:10px;
  cursor: pointer;
`

export const PreviewImageContainer = styled.div`
  width: 100%;
  height: 140px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
`

export const PreviewImage = styled.img`
  height: 100%;
  width: auto;
`

export const PreviewTitle = styled(Typography)`
  width:100%;
  padding:0;
  margin:7px;
  font-weight: 900!important;
  font-family: Roboto;
  font-size:9pt;
`

function LinkPreview ({ url }) {
  const [previewData, setPreviewData] = useState(null)
  const [loading, setLoading] = useState(true)

  const isYouTubeURL = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be')
  }

  const isYouTubeVideo = isYouTubeURL(url)

  const extractYouTubeVideoId = (url) => {
    const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/
    const match = url.match(videoIdRegex)
    return match ? match[1] : ''
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(url, { credentials: 'include' })
        const response = await proxy(url)
        const data = await response.data
        // console.log(data)

        const parser = new DOMParser()
        const doc = parser.parseFromString(data, 'text/html')
        const title = doc.querySelector('title')?.textContent || ''
        const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || ''
        const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || ''

        setPreviewData({
          title,
          description,
          image
        })
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    if (isYouTubeVideo) {
      const videoId = extractYouTubeVideoId(url)
      // const videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      setPreviewData({
        videoId
        // videoThumbnail
      })
      setLoading(false)
    } else {
      fetchData()
    }
  }, [url])

  if (loading) {
    return <p>...</p>
  }

  if (!previewData) {
    return <Linkify>
            {url}
          </Linkify>
  }

  const handleClick = () => {
    window.open(url, '_blank')
  }

  if (previewData.videoId) {
    const opts = {
      height: 'auto',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    }
    return (
      <PreviewContainer onClick={handleClick}>
        <YouTube videoId={previewData.videoId} opts={opts} />
      </PreviewContainer>
    )
  }

  return (
    <PreviewContainer onClick={handleClick}>
      <PreviewTitle>{previewData.title}</PreviewTitle>
      {/* <p>{previewData.description}</p> */}
      {previewData.image &&
        <PreviewImageContainer>
          <PreviewImage src={previewData.image} alt="Link Preview" />
        </PreviewImageContainer>
      }
    </PreviewContainer>
  )
}

LinkPreview.propTypes = {
  url: PropTypes.any
}

export default LinkPreview
